import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, CertificateDesignApi, CompanyApi, LoopBackAuth, CertificateApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';


@Component({
  selector: 'app-nuevo-certificado',
  templateUrl: './nuevo-certificado.component.html',
  styleUrls: ['./nuevo-certificado.component.scss']
})
export class NuevoCertificadoComponent implements OnInit {
  form: FormGroup;
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });
  image: any;
  cert = {
  image: null
}
  message = "";
  amount = 0;
  certImage = 'assets/images/cert.png';
  desing1 = null;
  desing2 = null;
  desing3 = null;
  desing4 = null;
  desings = [];
  indice = 0;
  index1 = 0;
  index2 = 1;
  index3 = 2;
  categoryId;
  img;

  constructor(
    public dialogRef: MatDialogRef<NuevoCertificadoComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _desing: CertificateDesignApi,
    private _user: UserApi,
    private _company: CompanyApi,
    private _certificate: CertificateApi,
    private loopBackAuth: LoopBackAuth,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.form = this.formBuilder.group({
      amount: ['', Validators.required],
      message: ['', Validators.required]
    }); 
    
  }

  ngOnInit() {
    if(this.data.plantillas && (this.data.plantillas.length > 0)){
      this.message = this.data.plantillas[0].description;
      console.log(this.data.plantillas);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectCategory(cat){    
    this.indice = 0;
    this.categoryId = cat.target.value;    
    this.desings = this.data.designs.filter((des) => {
      return des.categoryId == this.categoryId;
    });

    this.desing1 = this.desings[0] ? this.desings[0] : null;
    this.desing2 = this.desings[1] ? this.desings[1] : null;
    this.desing3 = this.desings[2] ? this.desings[2] : null;
    this.desing4 = this.desings[3] ? this.desings[3] : null;
  }

  selectImage(img){
    if(img == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png"){
      this.img = img
    }
    else{
      const image = img.split('/');
      this.img = image[image.length - 1];
    }
    this.certImage = img;
  }

  selectNext() {
    this.indice++;   
    if (this.desings[this.index1 + this.indice]) {
      this.desing1 = this.desings[0 + this.indice]
    } else {
      this.indice = -1;
      return this.selectNext();
    }
    if (this.desings[this.index2 + this.indice]) {
      this.desing2 = this.desings[1 + this.indice]
    } else {
      this.indice = -1;
      return this.selectNext();
    }
    if (this.desings[this.index3 + this.indice]) {
      this.desing3 = this.desings[2 + this.indice]
    } else {
      this.indice = -1;
      return this.selectNext();
    }
  }

  create(){
    const cert = {
      companyId: this.data.companyId,
      value: this.amount,
      userId:null,
      categoryId: this.categoryId,
      message: this.message,
      img: this.img,
      isWelcome: true
    }

    console.log(cert);
    this._certificate.create(cert).subscribe((newcert) => {
      this.dialogRef.close(newcert);
    });
  }

  changeMessage(event){
    let plantilla = this.data.plantillas.find((plan) => plan.id == event.target.value);

    this.message = plantilla.description;    
    
  }

  
}
