/* tslint:disable */
import {
  User
} from '../index';

declare var Object: any;
export interface TicketInterface {
  "companyId"?: number;
  "type"?: string;
  "status"?: string;
  "category"?: string;
  "createdAt"?: Date;
  "senderId"?: number;
  "description"?: string;
  "extraData"?: any;
  "groupId"?: string;
  "solvedBy"?: number;
  "solvedAt"?: Date;
  "id"?: number;
  company?: User;
  employee?: User;
  solved?: User;
}

export class Ticket implements TicketInterface {
  "companyId": number;
  "type": string;
  "status": string;
  "category": string;
  "createdAt": Date;
  "senderId": number;
  "description": string;
  "extraData": any;
  "groupId": string;
  "solvedBy": number;
  "solvedAt": Date;
  "id": number;
  company: User;
  employee: User;
  solved: User;
  constructor(data?: TicketInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Ticket`.
   */
  public static getModelName() {
    return "Ticket";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Ticket for dynamic purposes.
  **/
  public static factory(data: TicketInterface): Ticket{
    return new Ticket(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Ticket',
      plural: 'Tickets',
      path: 'Tickets',
      idName: 'id',
      properties: {
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "category": {
          name: 'category',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "senderId": {
          name: 'senderId',
          type: 'number'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "extraData": {
          name: 'extraData',
          type: 'any'
        },
        "groupId": {
          name: 'groupId',
          type: 'string'
        },
        "solvedBy": {
          name: 'solvedBy',
          type: 'number'
        },
        "solvedAt": {
          name: 'solvedAt',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'senderId',
          keyTo: 'id'
        },
        solved: {
          name: 'solved',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'solvedBy',
          keyTo: 'id'
        },
      }
    }
  }
}
