/* tslint:disable */
import {
  Company
} from '../index';

declare var Object: any;
export interface OrderCompanyInterface {
  "companyId"?: number;
  "createdAt"?: Date;
  "status"?: string;
  "amount"?: number;
  "id"?: number;
  company?: Company;
}

export class OrderCompany implements OrderCompanyInterface {
  "companyId": number;
  "createdAt": Date;
  "status": string;
  "amount": number;
  "id": number;
  company: Company;
  constructor(data?: OrderCompanyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OrderCompany`.
   */
  public static getModelName() {
    return "OrderCompany";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of OrderCompany for dynamic purposes.
  **/
  public static factory(data: OrderCompanyInterface): OrderCompany{
    return new OrderCompany(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'OrderCompany',
      plural: 'OrderCompanies',
      path: 'OrderCompanies',
      idName: 'id',
      properties: {
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
      }
    }
  }
}
