import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-solicitud-categoria',
  templateUrl: './solicitud-categoria.component.html',
  styleUrls: ['./solicitud-categoria.component.scss']
})
export class SolicitudCategoriaComponent implements OnInit {

  constructor(
    public dialogRef : MatDialogRef<SolicitudCategoriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user : UserApi,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  confirm(resp) {
    
    this._user.confirmRequestCategory(this.data.id, resp).subscribe((resp) => {
      let message = resp ? 'Solicitud aceptada!' : 'Solicitud rechazada!';
      this.snackBar.open(message, 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close();
    });
  }

}
