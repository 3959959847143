/* tslint:disable */
import {
  Company,
  Store
} from '../index';

declare var Object: any;
export interface Company_storeInterface {
  "companyId"?: number;
  "storeId"?: number;
  "createdAt"?: Date;
  "id"?: number;
  company?: Company;
  store?: Store;
}

export class Company_store implements Company_storeInterface {
  "companyId": number;
  "storeId": number;
  "createdAt": Date;
  "id": number;
  company: Company;
  store: Store;
  constructor(data?: Company_storeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Company_store`.
   */
  public static getModelName() {
    return "Company_store";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Company_store for dynamic purposes.
  **/
  public static factory(data: Company_storeInterface): Company_store{
    return new Company_store(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Company_store',
      plural: 'Company_stores',
      path: 'Company_stores',
      idName: 'id',
      properties: {
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "storeId": {
          name: 'storeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        store: {
          name: 'store',
          type: 'Store',
          model: 'Store',
          relationType: 'belongsTo',
                  keyFrom: 'storeId',
          keyTo: 'id'
        },
      }
    }
  }
}
