import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-info-pedido-empresa',
  templateUrl: './info-pedido-empresa.component.html',
  styleUrls: ['./info-pedido-empresa.component.scss']
})
export class InfoPedidoEmpresaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoPedidoEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
