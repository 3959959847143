import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UserApi} from '../../../../services/shared/sdk';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private formBuilder: FormBuilder,
    private _user: UserApi,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['',[Validators.required, Validators.email]]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  send(){
    this._user.forgotPassword(this.form.value).subscribe(resp => {
      this.snackBar.open('Mensaje enviado', 'Listo!', {
        duration: 3000
      });
      this.dialogRef.close(resp);
    },(error) => {
      this.snackBar.open('Ocurrió un error', 'ERROR', {
        duration: 5000
      });
      console.log(error);
      this.dialogRef.close();
    })
  }

  test(){
    console.log(this.form.value);
  }

}
