/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { CompanyApi } from './services/custom/Company';
import { EmployeeApi } from './services/custom/Employee';
import { StoreApi } from './services/custom/Store';
import { Company_storeApi } from './services/custom/Company_store';
import { AdminApi } from './services/custom/Admin';
import { Admin_companyApi } from './services/custom/Admin_company';
import { BalanceHistoryApi } from './services/custom/BalanceHistory';
import { GroupApi } from './services/custom/Group';
import { Group_employeeApi } from './services/custom/Group_employee';
import { OrderCompanyApi } from './services/custom/OrderCompany';
import { OrderEmployeeApi } from './services/custom/OrderEmployee';
import { CertificateCategoryApi } from './services/custom/CertificateCategory';
import { TicketApi } from './services/custom/Ticket';
import { CertificateApi } from './services/custom/Certificate';
import { GiftsApi } from './services/custom/Gifts';
import { CertTemplateApi } from './services/custom/CertTemplate';
import { CertificateDesignApi } from './services/custom/CertificateDesign';
import { UserApi } from './services/custom/User';
import { ContainerApi } from './services/custom/Container';
import { FaqApi } from './services/custom/Faq';
import { ImporantDateApi } from './services/custom/ImporantDate';
import { CertScheduleApi } from './services/custom/CertSchedule';
import { CampusApi } from './services/custom/Campus';
import { EventApi } from './services/custom/Event';
import { Employee_eventApi } from './services/custom/Employee_event';
import { Campus_commerceApi } from './services/custom/Campus_commerce';
import { Codigo_postalApi } from './services/custom/Codigo_postal';
import { QuestionApi } from './services/custom/Question';
import { ResponseApi } from './services/custom/Response';
import { Question_companyApi } from './services/custom/Question_company';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        CompanyApi,
        EmployeeApi,
        StoreApi,
        Company_storeApi,
        AdminApi,
        Admin_companyApi,
        BalanceHistoryApi,
        GroupApi,
        Group_employeeApi,
        OrderCompanyApi,
        OrderEmployeeApi,
        CertificateCategoryApi,
        TicketApi,
        CertificateApi,
        GiftsApi,
        CertTemplateApi,
        CertificateDesignApi,
        UserApi,
        ContainerApi,
        FaqApi,
        ImporantDateApi,
        CertScheduleApi,
        CampusApi,
        EventApi,
        Employee_eventApi,
        Campus_commerceApi,
        Codigo_postalApi,
        QuestionApi,
        ResponseApi,
        Question_companyApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

