import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CertScheduleApi } from '../../../../services/shared/sdk';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  displayedColumns: string[] = ['date', 'status'];
  dataSource = new MatTableDataSource();
  @ViewChild('MatSort1') sort: MatSort;
  company: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HistoryComponent>,
    private _schedules: CertScheduleApi
  ) {
    console.log(data);
    this.company = data.balance;
    let aux = [];
    this._schedules.find({ where: { companyId: this.company.id } }).subscribe(schedules => {
      if (schedules) {
        schedules.forEach((sc: any) => {
          aux.push({ date: sc.createdAt, status: sc.value, text: 'fueron utilizados', spent: true })
        });
        aux.sort((a, b) => b.date - a.date);
      }
      if (this.company.balanceHistories) {
        const balanceHistories = this.company.balanceType == 1 ? this.company.categories.flatMap(cat => cat.balanceHistories) : this.company.balanceHistories.filter(bal => !bal.categoryId);
        balanceHistories.forEach(bal => {
          let text = 'fueron abonados';
          if (bal.categoryId) {
            let category = this.company.categories.find(cat => cat.id == bal.categoryId);
            text += ` ${category.name}`;
          }
          aux.push({ date: bal.createdAt, status: bal.balance, text, spent: false });
        });
        aux.sort((a, b) => b.date - a.date);
      }
      this.dataSource = new MatTableDataSource(aux.reverse());
      this.dataSource.sort = this.sort;
    });
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
