import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { GroupApi, Group_employeeApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-eliminar-grupo',
  templateUrl: './eliminar-grupo.component.html',
  styleUrls: ['./eliminar-grupo.component.scss']
})
export class EliminarGrupoComponent implements OnInit {

  constructor(
    public dialogRef : MatDialogRef<EliminarGrupoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _group : GroupApi,
    private _group_employee: Group_employeeApi,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  delete(){
    // this._group_employee.find({where: {groupId : this.data.group.id}}).subscribe(resp => {
    //   if(resp){
    //     resp.forEach((element: any) => {
    //       this._group_employee.deleteById(element.id).subscribe(res => {
    //         console.log(`Relación con grupo borrada ${res}`);
    //       })
    //     });
    //   }
    // });
    this._group.patchAttributes(this.data.group.id,{isDeleted: true}).subscribe(resp => {
      console.log(`El grupo fue borrado ${resp}`);
      this.snackBar.open('Grupo eliminado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(resp);
    });
  }

}
