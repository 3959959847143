import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'balancetype'
})
export class BalanceTypePipe implements PipeTransform {

    transform(balanceType: number): any {
        return balanceType == 1 ? 'Categorías' : 'Global';
    }

}
