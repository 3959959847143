import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { CertScheduleApi, UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-evaluacion-pedido',
  templateUrl: './evaluacion-pedido.component.html',
  styleUrls: ['./evaluacion-pedido.component.scss']
})
export class EvaluacionPedidoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EvaluacionPedidoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _certschedule: CertScheduleApi,
    private snackBar: MatSnackBar,
    private _user: UserApi
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  Aceptar(){
    this.data.history.push({status: 'accepted', date: new Date(), userId: this._user.getCurrentId});
    this._certschedule.patchAttributes(this.data.id,{status:'accepted',history: this.data.history, updatedAt : new Date()}).subscribe(res => {
      this.snackBar.open('Pedido Aprobado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(res);
    })
  }

  Rechazar(){
    this.data.history.push({status: 'rejected', date: new Date(), userId: this._user.getCurrentId});
    this._certschedule.patchAttributes(this.data.id,{status:'rejected',history: this.data.history, updatedAt : new Date()}).subscribe(res => {
      this.snackBar.open('Pedido Rechazado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(res);
    })
  }

}
