import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';
import { GiftsApi, LoopBackAuth, UserApi } from '../../../services/shared/sdk';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';

@Component({
  selector: 'app-entrega-regalos',
  templateUrl: './entrega-regalos.component.html',
  styleUrls: ['./entrega-regalos.component.scss']
})
export class EntregaRegalosComponent implements OnInit {

  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });

  giftDocument;
  fileDocument;

  constructor(
    public dialogRef: MatDialogRef<EntregaRegalosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _gifts: GiftsApi,
    private _user : UserApi,
    private loopBackAuth: LoopBackAuth,
    private snackBar: MatSnackBar
  ) { 
    console.log(data);
    
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  uploadGift(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.giftDocument = eventReader.target.result;
    };

    this.uploader.queue[0].file.name = UUID.UUID() + '.pdf';
    this.fileDocument = this.uploader.queue[0].file.name;
  }

  test(){
    console.log(this.uploadURL+this.fileDocument);
    console.log(this.data);
  }

  Aceptar(){
    if (this.uploader.queue.length) {
      this.uploader.uploadAll();
    }
    if(!this.data.history){
      this.data.history = [];
    }
    this.data.history.push({status: 'available', date: new Date(), userId: this._user.getCurrentId});
    this._gifts.patchAttributes(this.data.id,{status:'available',history: this.data.history,file:this.fileDocument}).subscribe(res => {
      this.snackBar.open('Regalo Entregado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(res);
    },error => {
      this.snackBar.open('Ocurrió un error', error, {
        duration: 2000
      });
      this.dialogRef.close();
    })
  }

}
