import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { EventApi, CompanyApi, CampusApi } from '../../../services/shared/sdk';
import * as moment from 'moment';


@Component({
  selector: 'app-editar-evento',
  templateUrl: './editar-evento.component.html',
  styleUrls: ['./editar-evento.component.scss']
})
export class EditarEventoComponent implements OnInit {
  form: FormGroup;
  companies = [];
  campuses = [];
  allCampuses = [];
  event = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditarEventoComponent>,
    private formBuilder: FormBuilder,
    private _campus: CampusApi,
    private _company: CompanyApi,
    private _event: EventApi
  ) { 
    console.log(data);
    data.event.date = moment(data.event.date).format("YYYY-MM-DD");
    this.getCompany();
    this.getCampus(data.event.companyId);
    this.form = this.formBuilder.group({
      companyId: [data.event.companyId, Validators.required],
      date: [data.event.date, Validators.required],
      place: [data.event.place, Validators.required],
      campusId: [data.event.campusId, Validators.required],
      startTime: [data.event.startTime, Validators.required]
    });
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getCompany(){
    this._company.find({where: { isDeleted: false}}).subscribe(companies => {
      this.companies = companies;
    }) ;
  }

  getCampus(id){
    this._campus.find({
      where: {
        companyId: id
      }
    }).subscribe((campus) => {      
      this.allCampuses = campus;
    });
  }

  onChange(event){
    console.log(event);
    this.getCampus(event)
  }

  editEvent() {
    this._event.patchAttributes(this.data.event.id, this.data.event).subscribe((event) => {    
      this.dialogRef.close(this.data);
    });
  }

}
