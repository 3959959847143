/* tslint:disable */
import {
  Group_employee,
  User,
  CertSchedule
} from '../index';

declare var Object: any;
export interface GroupInterface {
  "name"?: string;
  "companyId"?: number;
  "createdAt"?: Date;
  "isDeleted"?: boolean;
  "id"?: number;
  groupEmployees?: Group_employee[];
  users?: User[];
  schedules?: CertSchedule[];
}

export class Group implements GroupInterface {
  "name": string;
  "companyId": number;
  "createdAt": Date;
  "isDeleted": boolean;
  "id": number;
  groupEmployees: Group_employee[];
  users: User[];
  schedules: CertSchedule[];
  constructor(data?: GroupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Group`.
   */
  public static getModelName() {
    return "Group";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Group for dynamic purposes.
  **/
  public static factory(data: GroupInterface): Group{
    return new Group(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Group',
      plural: 'Groups',
      path: 'Groups',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        groupEmployees: {
          name: 'groupEmployees',
          type: 'Group_employee[]',
          model: 'Group_employee',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'groupId'
        },
        users: {
          name: 'users',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
          modelThrough: 'Group_employee',
          keyThrough: 'userId',
          keyFrom: 'id',
          keyTo: 'groupId'
        },
        schedules: {
          name: 'schedules',
          type: 'CertSchedule[]',
          model: 'CertSchedule',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'groupId'
        },
      }
    }
  }
}
