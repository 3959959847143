/* tslint:disable */
import {
  Group,
  User
} from '../index';

declare var Object: any;
export interface Group_employeeInterface {
  "groupId"?: number;
  "userId"?: number;
  "companyId"?: number;
  "id"?: number;
  "employeeId"?: number;
  group?: Group;
  user?: User;
}

export class Group_employee implements Group_employeeInterface {
  "groupId": number;
  "userId": number;
  "companyId": number;
  "id": number;
  "employeeId": number;
  group: Group;
  user: User;
  constructor(data?: Group_employeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Group_employee`.
   */
  public static getModelName() {
    return "Group_employee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Group_employee for dynamic purposes.
  **/
  public static factory(data: Group_employeeInterface): Group_employee{
    return new Group_employee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Group_employee',
      plural: 'Group_employees',
      path: 'Group_employees',
      idName: 'id',
      properties: {
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
      },
      relations: {
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'groupId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
