/* tslint:disable */
import {
  Admin_company
} from '../index';

declare var Object: any;
export interface AdminInterface {
  "name"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "isSuperAdmin"?: boolean;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "password"?: string;
  accessTokens?: any[];
  adminCompanies?: Admin_company[];
}

export class Admin implements AdminInterface {
  "name": string;
  "createdAt": Date;
  "updatedAt": Date;
  "isSuperAdmin": boolean;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "password": string;
  accessTokens: any[];
  adminCompanies: Admin_company[];
  constructor(data?: AdminInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Admin`.
   */
  public static getModelName() {
    return "Admin";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Admin for dynamic purposes.
  **/
  public static factory(data: AdminInterface): Admin{
    return new Admin(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Admin',
      plural: 'Admins',
      path: 'Admins',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "isSuperAdmin": {
          name: 'isSuperAdmin',
          type: 'boolean'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        adminCompanies: {
          name: 'adminCompanies',
          type: 'Admin_company[]',
          model: 'Admin_company',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'adminId'
        },
      }
    }
  }
}
