import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserApi } from '../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  wellcome = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit aenean venenatis.';

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    rememberme: new FormControl(false)
  });

  constructor(private router: Router, private _user: UserApi, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  login() {
    this._user.login(this.loginForm.value).subscribe((resp: any) => { 
      if (resp.user.type == 'admin') {
        this.router.navigate(['/inicio']);
      } else {
        this._user.logout();
        this.router.navigate(['login']);
        this.snackBar.open('No tienes permiso para acceder!', 'Error!', {
          duration: 2000
        });
      }

    }, (err: any) => {
      this.snackBar.open('Ocurrió un error!', 'Error!', {
        duration: 2000
      });
    });
  }
}
