import { Component, OnInit } from '@angular/core';
import { CompanyApi, TicketApi, UserApi, EventApi, CertificateCategoryApi } from '../../services/shared/sdk';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  companies = [];
  newCompanies;
  totalTickets = 0;
  categories = [];
  delUsers = [];
  delEvents = [];
  User;

  constructor(
    private _company: CompanyApi,
    private _ticket: TicketApi,
    private _user: UserApi,
    private _event: EventApi,
    private _category: CertificateCategoryApi,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCompanies();
    this._user.getCurrent().subscribe(user => {
      this.User = user;
    })
  }

  logOut() {
    this._user.logout().subscribe(resp => {
      console.log("Sesión terminada", resp);
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      localStorage.removeItem('userId');
      sessionStorage.removeItem('userId');
      this.router.navigate(['/loginv2']);
    }, error => {
      console.log("Ocurrió un ERROR", error);
    })
  }

  getCompanies() {
    this._company.find({
      where: {
        isDeleted: false,
      }, include: 'adminCompanies'
    }).subscribe((companies) => {

      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          companies.forEach((element: any) => {
            this._ticket.find({ where: { and: [{ status: 'activo' }, { companyId: element.id }] } }).subscribe(resp => {
              if (resp.length != 0) {
                element.tickets = resp;
                this.companies.push(element);
                this.totalTickets = this.totalTickets + resp.length;
              }
            });
            this._user.find({ where: { and: [{ isDeleted: false }, { companyId: element.id }, { deletionRequest: true }] } }).subscribe(users => {
              if (users.length > 0) {
                element.delReqs = users;
                this.delUsers.push(element);
                this.totalTickets = this.totalTickets + users.length;
              }
            });
            this._category.find({ where: { and: [{ isDeleted: false }, { companyId: element.id }, { isValidated: false }] } }).subscribe(categories => {
              if (categories.length > 0) {
                element.catReqs = categories;
                this.categories.push(element);
                this.totalTickets = this.totalTickets + categories.length;
              }
            });
            this._event.find({ where: { and: [{ isDeleted: false }, { companyId: element.id }, { isValidated: false }] } }).subscribe(events => {
              if (events.length > 0) {
                element.eventReqs = events;
                this.delEvents.push(element);
                this.totalTickets = this.totalTickets + events.length;
              }
            })
          });
        }
        else {
          this.newCompanies = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id)
          });

          this.newCompanies.forEach(element => {
            this._ticket.find({ where: { and: [{ status: 'activo' }, { companyId: element.id }] } }).subscribe(resp => {
              if (resp.length != 0) {
                element.tickets = resp;
                this.companies.push(element);
                this.totalTickets = this.totalTickets + resp.length;
              }
            });
            this._user.find({ where: { and: [{ isDeleted: false }, { companyId: element.id }, { deletionRequest: true }] } }).subscribe(users => {
              if (users.length > 0) {
                element.delReqs = users;
                this.delUsers.push(element);
                this.totalTickets = this.totalTickets + users.length;
              }
            });
            this._category.find({ where: { and: [{ isDeleted: false }, { companyId: element.id }, { isValidated: false }] } }).subscribe(categories => {
              if (categories.length > 0) {
                element.catReqs = categories;
                this.categories.push(element);
                this.totalTickets = this.totalTickets + categories.length;
              }
            });
            this._event.find({ where: { and: [{ isDeleted: false }, { companyId: element.id }, { isValidated: false }] } }).subscribe(events => {
              if (events.length > 0) {
                element.eventReqs = events;
                this.delEvents.push(element);
                this.totalTickets = this.totalTickets + events.length;
              }
            })
          });
        }
      })

    })

  }

}
