import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyApi, UserApi, StoreApi, Company_storeApi, LoopBackAuth, Admin_companyApi, Codigo_postalApi } from '../../../services/shared/sdk';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-nueva-empresa',
  templateUrl: './nueva-empresa.component.html',
  styleUrls: ['./nueva-empresa.component.scss']
})
export class NuevaEmpresaComponent implements OnInit {
  form: FormGroup;
  dropdownSettings = {};
  admins: any;
  shops: any;
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });
  uploaderLogoWhite: FileUploader = new FileUploader({ url: this.uploadURL });
  uploaderGraphic: FileUploader = new FileUploader({ url: this.uploadURL });
  uploaderBanner: FileUploader = new FileUploader({ url: this.uploadURL });
  imageLogo: any;
  imageLogoWhite: any;
  imageGraphic: any;
  company: any = {};
  color: any = '#ff000073';
  banner: any = 'url(assets/images/banner.jpg)';
  patronEmail = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";
  owners = []
  colonias = [];
  Frase;

  states = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _company: CompanyApi,
    private _commerce: StoreApi,
    private _commerceCompany: Company_storeApi,
    private _admin: UserApi,
    private _snackBar: MatSnackBar,
    private loopBackAuth: LoopBackAuth,
    private _adminCompany: Admin_companyApi,
    private _code: Codigo_postalApi
  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      admin: ['', Validators.required],
      attendantName: ['', Validators.required],
      attendantEmail: ['', [Validators.required, Validators.pattern(this.patronEmail)]],
      attendantPhone: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      suburb: ['', Validators.required],
      street: ['', Validators.required],
      businessName: ['', Validators.required],
      typePerson: ['Moral', Validators.required],
      rfc: ['', [Validators.required]],
      addressNumber: ['', Validators.required],
      logo: [''],
      logoWhite: [''],
      graphic: [''],
      banner: [''],
      color: [''],
      shops: [''],
      phrase: [''],
      balanceType: [0, Validators.required]
    });
  }

  ngOnInit() {
    this.getAdmin();
    this.getCommers();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 20,
      allowSearchFilter: true
    };
  }

  validarRFC(event) {
    if (event.target.value == 'Fisica') {
      console.log('fisica')
      document.getElementById('rfc').setAttribute('minLength', '13');
      document.getElementById('rfc').setAttribute('maxLength', '13');
      this.form.get('rfc').setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13)]);
      this.form.updateValueAndValidity();
    }
    if (event.target.value == 'Moral') {
      console.log('moral')
      document.getElementById('rfc').setAttribute('minLength', '12');
      document.getElementById('rfc').setAttribute('maxLength', '12');
      this.form.get('rfc').setValidators([Validators.required, Validators.maxLength(12), Validators.minLength(12)]);
      this.form.updateValueAndValidity();
    }
  }

  saveCompany() {
    let company = this.form.value;
    company.color = this.color;
    company.banner = this.company.banner;
    company.logo = this.company.logo;
    company.logoWhite = this.company.logoWhite;
    company.graphic = this.company.graphic;
    company.phrase = this.Frase;

    this._company.create(company).subscribe((newCompany: any) => {
      this._snackBar.open('Empresa creada', 'Listo!', {
        duration: 2000
      });
      this.createOwnersApi({
        email: company.attendantEmail,
        name: company.attendantName,
        companyId: newCompany.id,
        type: 'owner'
      });
      if (this.owners.length) {
        this.owners.forEach((owner) => {
          owner.companyId = newCompany.id;
          owner.type = 'owner';
          this.createOwnersApi(owner);
        });
      }

      this.createAdmins(newCompany.id);

      // Subir imagenes
      if (this.uploader.queue.length) {
        this.uploader.uploadAll();
      }
      if (this.uploaderLogoWhite.queue.length) {
        this.uploaderLogoWhite.uploadAll();
      }
      if (this.uploaderGraphic.queue.length) {
        this.uploaderGraphic.uploadAll();
      }
      if (this.uploaderBanner.queue.length) {
        this.uploaderBanner.uploadAll();
      }

      let shopsSelected = this.shops.filter((shop) => {
        return shop.selected;
      }).map((shop) => {
        return {
          companyId: newCompany.id,
          storeId: shop.id
        }
      });
      this._commerceCompany.createMany(shopsSelected).subscribe((resp) => {
        console.log(resp);
        this.router.navigate(['/lista-empresas'])
      }, () => {
        this._snackBar.open('Ocurrió un error', 'Error!', {
          duration: 2000
        });
      });

    });
  }

  createOwnersApi(owner) {
    owner.pass = '3' + Math.floor(1000000 + Math.random() * 9000000);
    owner.password = owner.pass;
    this._admin.create(owner).subscribe((user) => {
    });
  }

  getAdmin() {
    this._admin.find({
      where: {
        isAdmin: true,
        isDeleted: false
      }
    }).subscribe((admins: any) => {
      this.admins = admins;
    });
  }

  getCommers() {
    this._commerce.find({
      where: {
        isDeleted: false
      }
    }).subscribe((shops: any) => {
      this.shops = shops;

      this.shops.forEach(shop => {
        shop.selected = false;
      });
    });
  }

  uploadFileLogo(event) {
    console.log("avatar");

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageLogo = eventReader.target.result;
    };

    this.uploader.queue[0].file.name = UUID.UUID();
    this.company.logo = this.uploader.queue[0].file.name;
  }

  uploadFileLogoWhite(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageLogoWhite = eventReader.target.result;
    };

    this.uploaderLogoWhite.queue[0].file.name = UUID.UUID();
    this.company.logoWhite = this.uploaderLogoWhite.queue[0].file.name;
  }

  uploadFileGraphic(event) {
    console.log("avatar");

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageGraphic = eventReader.target.result;
    };

    this.uploaderGraphic.queue[0].file.name = UUID.UUID();
    this.company.graphic = this.uploaderGraphic.queue[0].file.name;
  }

  uploadFileBanner(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.banner = 'url(' + eventReader.target.result + ')';
    };

    this.uploaderBanner.queue[0].file.name = UUID.UUID();
    this.company.banner = this.uploaderBanner.queue[0].file.name;
  }

  createAdmins(companyId) {
    let admins = [];
    if (this.company.admins && this.company.admins.length) {
      this.company.admins.forEach((admin) => {
        admins.push({
          adminId: admin.id,
          companyId: companyId
        });
      });
      this._adminCompany.createMany(admins).subscribe(resp => console.log(resp)
      );
    }

  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.company.admins);

  }
  onSelectAll(items: any) {
    console.log(items);
  }
  addOwner() {
    this.owners.push({
      name: '',
      email: ''
    });
  }
  deleteOwner(index) {
    this.owners.splice(index, 1);
  }

  addName(event, index) {
    this.owners[index].name = event;
  }

  addEmail(event, index) {
    this.owners[index].email = event;
  }

  zipCodeFinder(event) {
    if (event.length >= 5) {
      this.colonias = []
      this._code.find({ where: { cp: event } }).subscribe((cp: any) => {
        if (cp) {
          cp.forEach(data => {
            this.colonias.push(data.colonia)
          });
        }
        console.log(this.colonias);
      })
    }
  }
}
