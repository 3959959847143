import {
  StoreApi,
  GiftsApi
} from '../../services/shared/sdk';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import * as moment from 'moment';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';

import { API_VERSION, BASE_URL, CONTAINER_FOLDER } from '../../services/base-url';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { LoopBackAuth } from '../../services/shared/sdk';
import { EntregaRegalosComponent } from '../pedidos-colaboradores/entrega-regalos/entrega-regalos.component'

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  products = [];
  dataSource: any;
  dataSourceUpdate: any;
  showUpload: boolean = false;
  displayedColumns = [
    'nameEmployee',
    'employeeEmail',
    'companyName',
    'price',
    'estado',
    'createdAt',
    'actions'
  ];

  displayedColumnsUpdate = [
    'email',
    'link',
    'link2',
    'code',
    'nip',
    'expiration',
    'status'
  ];

  dropdownSettings = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginatorUpdate: MatPaginator;
  data: any = [];
  dataLink: any = [];
  dataCoin: any = [];
  dataCert: any = [];
  product: any;
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploadURL2 = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({
    url: this.uploadURL,
    queueLimit: 1
  });
  uploader2: FileUploader = new FileUploader({
    url: this.uploadURL2,
    queueLimit: 1
  });
  uploader3: FileUploader = new FileUploader({
    url: this.uploadURL,
    queueLimit: 1
  });
  uploader4: FileUploader = new FileUploader({
    url: this.uploadURL,
    queueLimit: 1
  });
  showSorteos: boolean = false;
  showCoin: boolean = false;
  showCert: boolean = false;
  dialogRef: any;
  orders = [];
  config;
  showTable = false;
  showTableUpdate = false;

  showFisico = false;
  showDigital = false;
  showisFile = false;
  showisGenerated = false;
  showisTicket = false;
  productId = 0;


  constructor(
    private _products: StoreApi,
    private loopBackAuth: LoopBackAuth,
    private _order: GiftsApi,
    public snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 25,
      allowSearchFilter: true,
      searchPlaceholderText: 'Filtrar por comercio',
      closeDropDownOnSelection: true
    };
    // **configuración de MatSnackBar**
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'center';
    this.config.duration = 10000;
    // *****
    this.getProducts();

  }

  getProducts() {
    this._products.find({
      where: {
        isDeleted: false
      }
    }).subscribe((products: any) => {
      this.products = products;
      console.log(products);

    });
  }

  setProduct(id) {
    this.productId = id;
    this.showFisico = false;
    this.showDigital = false;
    this.showisGenerated = false;
    this.showisTicket = false;
    this.showisFile = false;
    let product = this.products.find(product => product.id == id);

    if (product.productType == 0) {
      this.showFisico = true;

      this.dataCoin = [{
        correo: 'Correo',
        link: 'Link',
        orderId: '# orden'

      }];
    } else {
      this.showDigital = true;
      if (product.isGenerated) {
        this.showisGenerated = true;
        this.data = [{
          correo: 'Correo',
          codigo: 'Código',
          nip: 'Nip',
          expiration: 'Vigencia',
          orderId: '# orden'
        }];
      }
      if (product.isFile) {
        this.showisFile = true;
      }
      if (product.isTicket) {
        this.showisTicket = true;

        this.dataLink = [{
          correo: 'Correo',
          link: 'Link',
          link2: 'Link2',
          codigo: 'Código',
          expiration: 'Vigencia',
          orderId: '# orden'

        }];
      }
    }

    this.uploader.clearQueue();
    this.uploader2.clearQueue();
    this.uploader3.clearQueue();
    this.uploader4.clearQueue();

    this.product = product;
    let upload: boolean = false;
    this.dataSource = [];
    this._order.getGifts(
      {
        include: [{
          relation: 'employee',
          scope: {
            include: 'company'
          }
        }, 'store'],
        where: {
          storeId: product.id,
          status: "process"
        }
      }
    ).subscribe((orders: any) => {
      console.log(orders);
      this.showTableUpdate = false
      this.showTable = true;
      let certificates = [];
      if (orders) {
        orders.forEach((order, index) => {
          certificates.push({
            companyName: order.employee && order.employee.company ? order.employee.company.name : "no asignado",
            nameEmployee: order.employee ? order.employee.name : "no asignado",
            employeeEmail: order.employee ? order.employee.email : "no asignado",
            status: this.setStatus(order.status),
            value: order.value,
            estado: order.status == 1 ? 'Entregado' : 'Pendiente',
            createdAt: order.createdAt,
            name: this.product.name,
            id: order.id,
            upload: order.store.isFile && order.store.productType == 1 ? true : false,
            history: order.history,
            updatedAt: new Date()
          });

          this.data.push({
            correo: order.employee ? order.employee.email : "no asignado",
            codigo: '-',
            nip: '-',
            expiration: '-',
            orderId: order.id
          });

          this.dataLink.push({
            correo: order.employee ? order.employee.email : "no asignado",
            link: 'http://',
            link2: 'http://',
            codigo: '-',
            expiration: '-',
            orderId: order.id
          });

          this.dataCoin.push({
            correo: order.employee ? order.employee.email : "no asignado",
            link: 'http://',
            orderId: order.id

          });

          if (index == orders.length - 1) {
            this.orders = certificates;
            this.dataSource = new MatTableDataSource(certificates);
            setTimeout(() => {
              this.dataSource.paginator = this.paginator;
            }, 1000)
            console.log(certificates);
          }
        });
      }

    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  downloadCsv() {
    const archivo = new Angular5Csv(this.data, 'actualizarCodigo' + this.product.name);
  }

  downloadCsvLink() {
    const archivo = new Angular5Csv(this.dataLink, 'actualizarCodigo' + this.product.name);
  }

  downloadCsvCoin() {
    const archivo = new Angular5Csv(this.dataCoin, 'actualizarCodigo' + this.product.name);
  }

  downloadCsvCert() {
    const archivo = new Angular5Csv(this.dataCert, 'actualizarCodigo' + this.product.name);
  }

  // uploadFileUpdateCode(event) {
  //   console.log("code");

  //   this.uploader.addToQueue(event.srcElement.files);
  //   this.uploader.queue[0].file.name = UUID.UUID();
  //   this.uploader.uploadAll();
  //   this.uploader.onSuccessItem = () => {
  //     setTimeout(() => {
  //       this._purchaseOrder.updateCodeOrder(this.uploader.queue[0].file.name, this.product.id)
  //     .subscribe(( response ) => {
  //       console.log(response)

  //       this.showTable = false;

  //       response.resp.forEach((update: any) => {
  //         update.link = '-';
  //         update.status = 1;
  //         update.estado = 'Entregado';
  //       });
  //       this.dataSourceUpdate = new MatTableDataSource(response.resp);
  //       setTimeout(() => {
  //         this.dataSourceUpdate.paginator = this.paginatorUpdate;
  //       }, 1000)
  //       this.showTableUpdate = true;
  //       this.snackBar.open('Listo!', 'Se han actualizado los estatus de los pedidos!', this.config);

  //     }, ( err ) => {
  //       this.snackBar.open('Error!', 'Ocurrio un error, revisa que los datos del archivo esten correctos!', this.config);
  //     });
  //     },1500)
  //   };
  //   this.uploader.onErrorItem = () => {
  //     this.uploader.clearQueue();

  //   };
  // }

  uploadFileUpdateLink(event) {
    console.log("link");
    this.uploader2.addToQueue(event.srcElement.files);
    this.uploader2.queue[0].file.name = UUID.UUID();
    this.uploader2.uploadAll();
    this.uploader2.onSuccessItem = () => {
      setTimeout(() => {
        this._order.updateCodeOrderLink(this.uploader2.queue[0].file.name, this.product.id)
          .subscribe((response) => {
            console.log(response.resp);
            this.showTable = false;
            this.showTableUpdate = true;
            this.snackBar.open('Listo!', 'Se han actualizado los pedidos!', this.config);
            this.setProduct(this.productId);
          }, (err) => {
            this.snackBar.open('Error!', 'Ocurrio un error, revisa que los datos del archivo esten correctos!', this.config);
          });
      });

    };
    this.uploader2.onErrorItem = () => {
      this.uploader2.clearQueue();

    };
  }

  uploadFileUpdateCoin(event) {
    console.log("coin");

    this.uploader3.addToQueue(event.srcElement.files);
    this.uploader3.queue[0].file.name = UUID.UUID();
    this.uploader3.uploadAll();
    this.uploader3.onSuccessItem = () => {
      this._order.updateOrderShipping(this.uploader3.queue[0].file.name, this.product.id)
        .subscribe((response) => {
          console.log(response)
          this.snackBar.open('Listo!', 'Se han actualizado los pedidos!', this.config);
          this.setProduct(this.productId);
        }, (err) => {
          this.snackBar.open('Error!', 'Ocurrio un error, revisa que los datos del archivo esten correctos!', this.config);
        });

    };
    this.uploader3.onErrorItem = () => {
      this.uploader3.clearQueue();

    };
  }

  uploadFileUpdateCert(event) {
    console.log("cert");

    this.uploader4.addToQueue(event.srcElement.files);
    this.uploader4.queue[0].file.name = UUID.UUID();
    this.uploader4.uploadAll();
    this.uploader4.onSuccessItem = () => {
      this._order.updateCodeOrder(this.uploader4.queue[0].file.name, this.product.id)
        .subscribe((response) => {
          console.log(response)
          this.snackBar.open('Listo!', 'Se han actualizado los pedidos!', this.config);
          this.setProduct(this.productId);
        }, (err) => {
          this.snackBar.open('Error!', 'Ocurrio un error, revisa que los datos del archivo esten correctos!', this.config);
        });

    };
    this.uploader4.onErrorItem = () => {
      this.uploader4.clearQueue();

    };
  }

  showEditOrder(product, index) {
    console.log(index);
    console.log(product);
    this.dialogRef = this.dialog.open(EntregaRegalosComponent, {
      panelClass: 'app-edit-order',
      width: '800px',
      disableClose: true,
      data: {
        orders: this.orders,
        type: 1,
        product: product,
        index: index,
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        console.log(response);
        this.setProduct(this.product);
        this.dataSource = new MatTableDataSource(response);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 1000)
      });
  }

  openEntrega(order, index) {
    console.log(order);

    order.index = index;
    const EntregaOrder = this.dialog.open(EntregaRegalosComponent, {
      width: '50%',
      data: order
    });
    EntregaOrder.afterClosed().subscribe((result) => {
      if (result) {
        console.log('The dialog was closed');
        this.setProduct(this.productId);
      }
    })
  }

  setStatus(status) {
    switch (status) {
      case "process":
        return "En proceso"
      case "available":
        return "Entregado"
    }
  }

}
