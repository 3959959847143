import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import {CampusApi, CompanyApi} from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-nuevo-campus',
  templateUrl: './nuevo-campus.component.html',
  styleUrls: ['./nuevo-campus.component.scss']
})
export class NuevoCampusComponent implements OnInit {

  form: FormGroup;
  dropdownSettings = {};
  companies;
  comps;
  compaId;

  constructor(
    public dialogRef: MatDialogRef<NuevoCampusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _campus : CampusApi,
    private _company : CompanyApi,
    private snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      companyId: ['', Validators.required],
      abbreviation: ['']
    });
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(){
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getCompanies();

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar empresa',
      closeDropDownOnSelection: true
    };

  }

  onItemSelect(item: any) {
    console.log(item);
    this.compaId = item.id;
    console.log(this.form.value);
  }

  getCompanies(){
    this._company.find({where: {isDeleted: false}}).subscribe(resp => {
      this.companies = resp;
    })
  }

  createCampus(){
    this.form.value.companyId = this.compaId;
    this._campus.create(this.form.value).subscribe((resp:any) => {
        this.snackBar.open('Campus/Sede creado', 'Listo!', {
          duration: 2000
        });
        this.dialogRef.close(resp);
    },(error => {
      this.snackBar.open('Ocurrió un problema', 'ERROR', {
        duration: 5000
      });
      this.dialogRef.close();
      console.error(error);
    }));
  }

}
