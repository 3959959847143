import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-editar-password',
  templateUrl: './editar-password.component.html',
  styleUrls: ['./editar-password.component.scss']
})
export class EditarPasswordUserComponent implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditarPasswordUserComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user: UserApi,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: ['',Validators.required],
      confirmPass: ['',Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  passIsEqual(e) {
    this.form.get('confirmPass')
      .setValue(e.target.value === this.form.get('password').value);
  }

  editPass(){
    this._user.updatePassword(this.data.id,this.form.value.password).subscribe(resp => {
      this.snackBar.open('Contraseña editada', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(resp);
    })
  }
}
