import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatPaginator } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrarColaboradorComponent } from '../registrar-colaborador/registrar-colaborador.component';
import { PerfilColaboradorComponent } from '../perfil-colaborador/perfil-colaborador.component';
import { EditarPasswordUserComponent } from '../editar-password/editar-password.component';
import { EliminarColaboradorComponent } from '../eliminar-colaborador/eliminar-colaborador.component';
import { IntegrantesGrupoComponent } from '../integrantes-grupo/integrantes-grupo.component';
import { RegistrarGrupoComponent } from '../registrar-grupo/registrar-grupo.component';
import { EliminarGrupoComponent } from '../eliminar-grupo/eliminar-grupo.component';
import { EditarGrupoComponent } from '../editar-grupo/editar-grupo.component';
import { SolicitudBajaComponent } from '../solicitud-baja/solicitud-baja.component';
import { ReenviarInvitacionComponent } from '../reenviar-invitacion/reenviar-invitacion.component';
import { AsignaCertificadoComponent } from '../asigna-certificado/asigna-certificado.component';
import { UserApi, GroupApi, AdminApi, CompanyApi, Group_employeeApi, CampusApi, LoopBackAuth, CertificateCategoryApi } from '../../../services/shared/sdk';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from "moment";
import { isNumber } from 'util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-lista-colaboradores',
  templateUrl: './lista-colaboradores.component.html',
  styleUrls: ['./lista-colaboradores.component.scss']
})
export class ListaColaboradoresComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'payrollNumber', 'balance', 'updatedAt', 'actions'];
  displayedColumns2: string[] = ['name', 'members', 'lastShipping', 'createdAt', 'certifiedToExpire', 'actions'];

  @ViewChild('MatSort1') sort: MatSort;
  @ViewChild('MatSort2') sort2: MatSort;

  @ViewChild('singlePaginator') paginator: MatPaginator;
  @ViewChild('singlePaginator2') paginator2: MatPaginator;
  users = [];
  admin;
  selectedCompanies = [];
  dataUsers = new MatTableDataSource();
  groups = [];
  dataGroups = new MatTableDataSource();
  companies;
  companyId;
  dropdownSettings = {};
  companyName;
  campuses;
  loadingUsers: boolean = false
  loadingGroups: boolean = false
  certificateCategories = [];

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public Router: ActivatedRoute,
    private _user: UserApi,
    private _group: GroupApi,
    private _admin: AdminApi,
    private _company: CompanyApi,
    private _groupUser: Group_employeeApi,
    private _campusApi: CampusApi,
    private _categoryApi: CertificateCategoryApi,
    private auth: LoopBackAuth,
    public snackBar: MatSnackBar
    //public dialogRef: MatDialogRef

  ) {
    this.Router.params.subscribe(resp => {
      // console.log(resp);
      if (resp && resp.id) { //resp.id - companyId
        if (isNaN(Number(resp.id))) return alert('Error, parametro no válido')
        this.selectOption2(resp.id);
      }
    })
  }

  ngOnInit() {
    this.dataUsers.data = this.users;
    this.dataUsers.paginator = this.paginator
    this.admin = this.auth.getCurrentUserData();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 25,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar empresa',
      closeDropDownOnSelection: true
    };
    this.getCompanies();

    this._campusApi.find().subscribe((response) => {
      this.campuses = response
    });
  }

  getCompanies() {
    this._company.find({ where: { isDeleted: false }, include: 'adminCompanies' }).subscribe((companies) => {
      if (this.admin.isSuperAdmin) {
        this.companies = companies;
        this.companies.unshift({ name: 'MOSTRAR TODO', id: 'all' });
      } else {
        this.companies = companies.filter((company: any) => {
          return company.adminCompanies.map((admin) => admin.adminId).includes(this.admin.id);
        });
        this.companies.unshift({ name: 'MOSTRAR TODO', id: 'all' });
      }
      // this.getAllUsers();
    })
  }

  async getUsersCompany(id) { //Para traer los usuarios si es por compañía
    this.loadingUsers = true
    const users = await this._user.find({ where: { and: [{ isDeleted: false }, { companyId: id }] }, include: ['groupEmployees', 'certs', 'gifts'] }).toPromise()
    this.users = users
    this.dataUsers.data = this.users;
    this.dataUsers.sort = this.sort;
    this.dataUsers._updatePaginator(this.dataUsers.data.length)
    this.loadingUsers = false
    // })
  }

  async getCategoriesCompany(id) {
    const categories = await this._categoryApi.find({ where: { companyId: id } }).toPromise();
    this.certificateCategories = categories;
  }

  getAllUsers() {
    this.loadingUsers = true
    this.users = []
    Promise.all(
      this.companies.map(async company => {
        if (isNaN(Number(company.id))) return
        const users = await this._user.find({ where: { isDeleted: false, companyId: company.id }, include: ['groupEmployees', 'certs', 'gifts'] }).toPromise()
        this.users = this.users.concat(users)
        this.dataUsers.data = this.dataUsers.data.concat(users)
        this.dataUsers._updatePaginator(this.dataUsers.data.length)
        return users
      })
    ).then(allUsers => {
      this.dataUsers.sort = this.sort;
      this.loadingUsers = false
    })
    this.companyName = "TODOS";
  }

  getGroups(id) {
    this.loadingGroups = true
    let where: any = { isDeleted: false }
    if (id !== 'all') where = { ...where, companyId: id }
    this._group.find({ where }).subscribe((groups) => {
      if (groups) {
        this.groups = groups.filter((group: any) => {
          return this.companies.map((c) => c.id).includes(group.companyId);
        });
        Promise.all(
          this.groups.map(async group => {
            const countUsers = await this._groupUser.count({ groupId: group.id }).toPromise()
            group.membersCount = countUsers.count
          })
        ).catch(error => console.log(error))
        this.dataGroups.data = this.groups;
        this.dataGroups.sort = this.sort2;
        setTimeout(() => this.dataGroups.paginator = this.paginator2);
        this.loadingGroups = false
      }
    })
  }

  getAllGroups() {
    this.loadingGroups = true
    this._group.find({ where: { isDeleted: false } }).subscribe(async (groups) => {

      if (groups) {
        this.groups = groups.filter((group: any) => {
          return this.companies.map((c) => c.id).includes(group.companyId);
        });
        Promise.all(
          this.groups.map(async group => {
            const countUsers = await this._groupUser.count({ groupId: group.id }).toPromise()
            group.membersCount = countUsers.count
          })
        ).catch(error => console.log(error))
        this.dataGroups.data = this.groups;
        this.dataGroups.sort = this.sort2;
        setTimeout(() => this.dataGroups.paginator = this.paginator2);
        this.loadingGroups = false
      }
    })
    this.companyName = "TODOS";
  }

  async selectOption(event) {
    // console.log('selectedCompany', event);
    this.loadingGroups = true
    this.loadingUsers = true

    if (event.id == "all") {
      await this.getAllUsers();
      await this.getAllGroups();
      this.companyId = "all"
      this.companyName = "TODOS";
    } else {
      this.companyId = event.id;
      this.companyName = event.name;
      await this.getUsersCompany(this.companyId);
      await this.getCategoriesCompany(this.companyId);
      await this.getGroups(this.companyId);
    }
  }

  selectOption2(id) {
    this.companyId = id;
    this._company.findById(id).subscribe((nameC: any) => {
      this.companyName = nameC.name;
      this.selectedCompanies = [{
        id: nameC.id,
        name: nameC.name
      }]
    })
    this.getUsersCompany(this.companyId);
    this.getGroups(this.companyId);
  }

  openNuevoColaborador() {
    const nuevoColaborador = this.dialog.open(RegistrarColaboradorComponent, {
      width: '40%',
      data: {
        campuses: this.campuses.filter(campus => campus.companyId == this.companyId),
        companyId: this.companyId
      }
    });

    nuevoColaborador.afterClosed().subscribe((result) => {
      if (result) {
        console.log('The dialog was closed');
        this.getUsersCompany(this.companyId);
      }
    })
  }

  openPerfil(user, index) {
    user.company = this.companies.find((company: any) => company.id == user.companyId)
    const perfilColababorador = this.dialog.open(PerfilColaboradorComponent, {
      width: '30%',
      maxHeight: '90vh',
      position: { top: '72px' },
      data: { user, index }
    });
  }

  goEditUser(id) {
    this.router.navigate(['/editar-colaborador', id]);
  }

  openPassUser(user, index) {
    const editPass = this.dialog.open(EditarPasswordUserComponent, {
      width: '40%',
      data: user
    });
  }

  openDelete(user, index) {
    const deleteUser = this.dialog.open(EliminarColaboradorComponent, {
      width: '25%',
      data: { user, index }
    });
    deleteUser.afterClosed().subscribe(result => {
      if (result) {
        console.log('The dialog was closed');
        this.getUsersCompany(this.companyId);
      }
    })
  }

  newGroup() {
    const nuevoGrupo = this.dialog.open(RegistrarGrupoComponent, {
      width: '50%',
      data: this.companyId
    });
    nuevoGrupo.afterClosed().subscribe((result) => {
      if (result) {
        console.log('The dialog was closed');
        this.getGroups(this.companyId);
      }
    })
  }

  async openMembersGroup(group, index) {
    if (!group.members) {
      const membersGroup = await this._groupUser.find({ where: { groupId: group.id }, include: { relation: 'user' } }).toPromise()
      group.members = membersGroup;
    }
    const merbersList = this.dialog.open(IntegrantesGrupoComponent, {
      width: '50vw',
      maxHeight: '72vh',
      data: { group, index }
    });
  }

  deleteGroup(group, index) {
    const deleteGroup = this.dialog.open(EliminarGrupoComponent, {
      width: '30%',
      data: { group, index }
    });
  }

  async editGroup(group, index) {
    if (!group.members) {
      const membersGroup = await this._groupUser.find({ where: { groupId: group.id }, include: { relation: 'user' } }).toPromise()
      group.members = membersGroup;
    }

    const editGroup = this.dialog.open(EditarGrupoComponent, {
      width: '50%',
      data: { group, index }
    });
    editGroup.afterClosed().subscribe(res => {
      if (res) {
        console.log('The dialog was closed');
        this.getGroups(this.companyId);
      }
    })
  }

  solicitud(row) {
    const dialog = this.dialog.open(SolicitudBajaComponent, {
      width: '50%',
      data: row
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.getUsersCompany(this.companyId);
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataUsers.filter = filterValue.trim().toLowerCase();
    if (this.dataUsers.paginator) {
      this.dataUsers.paginator.firstPage();
    }
  }

  applyGroupFilter(filterValue: string) {
    this.dataGroups.filter = filterValue.trim().toLowerCase();
    if (this.dataGroups.paginator) {
      this.dataGroups.paginator.firstPage();
    }
  }

  reporteColaboradores() {
    let data: any = [
      {
        title: this.companyName && this.companyName != "TODOS" ? 'Reporte de colaboradores de ' + this.companyName : 'Reporte de todos los colaboradores',
        fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY')
      },
      {
        name: 'NOMBRE',
        email: 'EMAIL',
        id: 'ID COLABORADOR',
        estado: 'ESTADO',
        nomina: 'NÓMINA',
        lealtad: 'LEALTAD/ANTIGÜEDAD',
        empresa: 'EMPRESA',
        campus: 'CAMPUS',
        fechaingreso: 'FECHA DE INGRESO A LA EMPRESA',
        cumple: 'CUMPLEAÑOS',
        edad: 'EDAD',
        edocivil: 'ESTADO CIVIL',
        parent: 'PADRE DE FAMILIA',
        saldoPendienteCertificados: 'PENDIENTE POR CANJEAR',
        saldo: 'SALDO'
      }
    ];
    this.users.forEach(element => {
      let saldoPend = 0
      element.certs.find((cert: any) => {
        if (cert.status == 'available') {
          saldoPend += cert.value
        }
      })
      let edad = moment().diff(element.birthdate, 'years');
      if (element.dateAdmission) {
        var ant: any = moment().diff(element.dateAdmission, 'weeks');
        if (ant >= 52) {
          ant = moment().diff(element.dateAdmission, 'years');
          if (ant == 1) {
            ant = ant + " año";
          }
          else {
            ant = ant + " años";
          }
        }
        else {
          ant = ant + " semanas";
        }
      }

      const nameCompany = this.companies.find((comp: any) => comp.id == element.companyId)
      const nameCampus = this.campuses.find((camp: any) => camp.id == element.campusId)

      let user = {
        name: element.name,
        email: element.email,
        id: element.id,
        estado: element.country ? element.country : '-',
        nomina: element.payrollNumber ? element.payrollNumber : 'No asignada',
        lealtad: ant ? ant : element.years,
        empresa: nameCompany ? nameCompany.name : '',
        campus: nameCampus ? nameCampus.name : '-',
        fechaingreso: element.dateAdmission ? moment(element.dateAdmission).format('DD/MMMM/YYYY') : '-',
        cumple: element.birthdate ? moment(element.birthdate).format('DD/MMMM/YYYY') : '-',
        edad: edad ? edad + " años" : '-',
        edocivil: element.maritalStatus == "married" ? "Casado" : element.maritalStatus == "single" ? "Soltero" : "-",
        parent: element.parentType == "mom" ? "Madre" : element.parentType == "dad" ? "Padre" : element.parentType == false ? "No" : "-",
        saldoPendienteCertificados: `$${saldoPend}`,
        saldo: element.balance ? ('$' + element.balance) : '$0'
      }
      data.push(user);
    });
    const archivo = new Angular5Csv(data, 'ReporteColaboradores-' + moment(new Date()).format('DD/MM/YYYY,h:mm a'));
  }

  reporteColaboradoresCategoria(categoryId) {
    let data: any = [
      {
        title: this.companyName && this.companyName != "TODOS" ? 'Reporte de colaboradores de ' + this.companyName : 'Reporte de todos los colaboradores',
        fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY')
      },
      {
        payrollnumber: 'NÓMINA',
        name: 'NOMBRE',
        email: 'EMAIL',
        lealtad: 'LEALTAD/ANTIGÜEDAD',
        monto: 'MONTO ASIGNADO',
        empresa: 'EMPRESA',
        campus: 'CAMPUS',
      }
    ];
    this.users.filter(user => user.certs.find(cer => cer.categoryId == categoryId)).forEach(element => {
      if (element.dateAdmission) {
        var ant: any = moment().diff(element.dateAdmission, 'weeks');
        if (ant >= 52) {
          ant = moment().diff(element.dateAdmission, 'years');
          if (ant == 1) {
            ant = ant + " año";
          }
          else {
            ant = ant + " años";
          }
        }
        else {
          ant = ant + " semanas";
        }
      }

      const certLealtad = element.certs.find(cer => cer.categoryId == categoryId);
      element.amount = certLealtad ? certLealtad.valuehistory : 0;

      const nameCompany = this.companies.find((comp: any) => comp.id == element.companyId)
      const nameCampus = this.campuses.find((camp: any) => camp.id == element.campusId)

      if (element.isTec) nameCompany.name = 'Tecnológico de Monterrey';
      if (element.isSorteosTec) nameCompany.name = 'Sorteos TEC';
      if (element.isTecMilenio) nameCompany.name = 'Tecmilenio';
      if (element.isTecSalud) nameCompany.name = 'Tec Salud';
      if (element.isTecSec) nameCompany.name = 'Secundarias';

      let user = {

        payrollnumber: `${element.payrollNumber || 'No asignado'}`,
        name: element.name,
        email: element.email,
        lealtad: ant ? ant : element.years,
        monto: element.amount,
        empresa: nameCompany ? nameCompany.name : '',
        campus: nameCampus ? nameCampus.name : '-',
      }
      data.push(user);
    });
    const archivo = new Angular5Csv(data, 'ReporteColaboradores-' + moment(new Date()).format('DD/MM/YYYY,h:mm a'));
  }

  reporteColaboradoresLealtad() {
    let data: any = [
      {
        title: this.companyName && this.companyName != "TODOS" ? 'Reporte de colaboradores de ' + this.companyName + ' lealtad' : 'Reporte de todos los colaboradores lealtad',
        fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY')
      },
      {
        name: 'NOMBRE',
        email: 'EMAIL',
        lealtad: 'LEALTAD/ANTIGÜEDAD',
        monto: 'MONTO ASIGNADO',
        empresa: 'EMPRESA',
        campus: 'CAMPUS',
      }
    ];
    this.users.filter(user => user.isLealtad).forEach(element => {
      let saldoPend = 0
      element.certs.find((cert: any) => {
        if (cert.status == 'available') {
          saldoPend += cert.value
        }
      })
      if (element.dateAdmission) {
        var ant: any = moment().diff(element.dateAdmission, 'weeks');
        if (ant >= 52) {
          ant = moment().diff(element.dateAdmission, 'years');
          if (ant == 1) {
            ant = ant + " año";
          }
          else {
            ant = ant + " años";
          }
        }
        else {
          ant = ant + " semanas";
        }
      }

      const certLealtad = element.certs.find(cer => cer.categoryId == 354);
      element.amount = certLealtad ? certLealtad.valuehistory : 0;

      const nameCompany = this.companies.find((comp: any) => comp.id == element.companyId)
      const nameCampus = this.campuses.find((camp: any) => camp.id == element.campusId)


      if (element.isTec) nameCompany.name = 'Tecnológico de Monterrey';
      if (element.isSorteosTec) nameCompany.name = 'Sorteos TEC';
      if (element.isTecMilenio) nameCompany.name = 'Tecmilenio';
      if (element.isTecSalud) nameCompany.name = 'Tec Salud';
      if (element.isTecSec) nameCompany.name = 'Secundarias';

      let user = {
        payrollnumber: `${element.payrollNumber || 'No asignado'}`,
        name: element.name,
        email: element.email,
        lealtad: ant ? ant : element.years,
        monto: element.amount,
        empresa: nameCompany ? nameCompany.name : '',

        campus: nameCampus ? nameCampus.name : '-',
      }
      data.push(user);
    });
    const archivo = new Angular5Csv(data, 'ReporteColaboradoresLealtad-' + moment(new Date()).format('DD/MM/YYYY,h:mm a'));
  }

  async reporteGrupos() {
    let data: any = [
      {
        title: 'Reporte de grupos de ' + this.companyName,
        fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY')
      },
      {
        nombre: 'COLABORADOR',
        correo: 'EMAIL',
        name: 'GRUPO',
        creado: 'FECHA DE CREACIÓN'
      }
    ];
    await Promise.all(
      this.groups.map(async group => {
        if (!group.members) {
          const membersGroup = await this._groupUser.find({ where: { groupId: group.id }, include: { relation: 'user' } }).toPromise()
          group.members = membersGroup;
        }

        group.members.forEach(memb => {
          let member = {
            name: memb.user.name,
            email: memb.user.email,
            group: `${group.name} (${group.members.length} integrantes)`,
            creado: moment(group.createdAt).format('DD/MM/YYYY')
          }
          data.push(member);
        });
      })
    )
    // console.log('data', data);

    const archivo = new Angular5Csv(data, 'ReporteGrupos-' + moment(new Date()).format('DD/MM/YYYY,h:mm a'));
  }

  openAddCertificate(user) {
    const dialog = this.dialog.open(AsignaCertificadoComponent, {
      width: '80%',
      maxHeight: '90vh',
      data: {
        companyId: user.companyId,
        userId: user.id
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        console.log('The dialog was closed');
        this.getUsersCompany(this.companyId);
      }
    })
  }

  openSendInvitation(user) {
    const dialog = this.dialog.open(ReenviarInvitacionComponent, {
      width: '30%',
      maxHeight: '90vh',
      data: user
    });
  }

  changeRole(user, type) {
    const patched = { type }
    this._user.patchAttributes(user.id, patched).subscribe(resp => {
      this.snackBar.open(type == 'owner' ? 'Usuario convertido en subadmin' : 'Usuario convertido en colaborador', 'Listo!', {
        duration: 2000
      });
      user.type = type;
    })
  }
}

