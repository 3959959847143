/* tslint:disable */
import {
  Response
} from '../index';

declare var Object: any;
export interface QuestionInterface {
  "description"?: string;
  "isDeleted"?: boolean;
  "type"?: string;
  "options"?: Array<any>;
  "required"?: boolean;
  "id"?: number;
  "companyId"?: number;
  responses?: Response[];
}

export class Question implements QuestionInterface {
  "description": string;
  "isDeleted": boolean;
  "type": string;
  "options": Array<any>;
  "required": boolean;
  "id": number;
  "companyId": number;
  responses: Response[];
  constructor(data?: QuestionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Question`.
   */
  public static getModelName() {
    return "Question";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Question for dynamic purposes.
  **/
  public static factory(data: QuestionInterface): Question{
    return new Question(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Question',
      plural: 'Questions',
      path: 'Questions',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "options": {
          name: 'options',
          type: 'Array&lt;any&gt;'
        },
        "required": {
          name: 'required',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
      },
      relations: {
        responses: {
          name: 'responses',
          type: 'Response[]',
          model: 'Response',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'questionId'
        },
      }
    }
  }
}
