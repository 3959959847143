import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NuevoAdminComponent} from './nuevo-admin/nuevo-admin.component';
import {EditarPasswordComponent} from './editar-password/editar-password.component';
import {EditarAdminComponent} from './editar-admin/editar-admin.component';
import {BorrarAdminComponent} from './borrar-admin/borrar-admin.component';
import {AdminApi, UserApi, CompanyApi, Admin_companyApi} from '../../services/shared/sdk';


@Component({
  selector: 'app-administradores',
  templateUrl: './administradores.component.html',
  styleUrls: ['./administradores.component.scss']
})
export class AdministradoresComponent implements OnInit {

  dialogRef : MatDialogRef<AdminsEmpresas>;

  displayedColumns = ['name','email','updatedAt','actions'];
  displayedColumns2 = ['name','email','yourCompanies','updatedAt','actions'];

  dataSuperAdmins;
  dataAdmins;
  user: any = [];

  @ViewChild('MatSort1') sort: MatSort;
  @ViewChild('MatSort2') sort2: MatSort;

  @ViewChild('singlePaginator') paginator: MatPaginator;

  name;
  email;
  password;
  confirmPass;
  companies: any = [];
  firstCom: any = [];


  SuperAdmins: any;
  Admins: any;

  ngOnInit() {}

  constructor(
    public dialog: MatDialog,
    private _admin: AdminApi,
    private _user: UserApi,
    private _company: CompanyApi,
    private _admcom: Admin_companyApi
    ) {
      this._user.getCurrent().subscribe((user) => {
        this.user = user;
        if (user.isSuperAdmin){
          this.getSuperAdmins();
          this.getUsers();          
        } else {
          this._admcom.find({where:{adminId : user.id}}).subscribe((resp)=>{
            user.yourCompanies = resp;
          });
          this.dataAdmins = new MatTableDataSource([user]);
        }
      });
      this.getCompanies();
    }

    getSuperAdmins(){
      this._user.find({where: {and: [{isSuperAdmin: true} , {isDeleted: false}] }}).subscribe((supers: any) => {
        supers.forEach(adm => {
          this._admcom.find({where:{adminId : adm.id}}).subscribe((resp)=>{
            adm.yourCompanies = resp;
          });
        })
        this.SuperAdmins = supers;
        this.dataSuperAdmins = new MatTableDataSource(this.SuperAdmins);
        this.dataSuperAdmins.sort = this.sort;
      })
    }

    getAdmins(){
      this._admin.find({include: {relation: 'adminCompanies'}}).subscribe((admins) => {
        console.log(admins);
        this.Admins = admins;
        this.dataAdmins = new MatTableDataSource(this.Admins);
      })
    }

    getUsers(){
      this._user.find({where: {and: [{isAdmin: true} , {isDeleted: false}] }}).subscribe((admins: any) => {
        admins.forEach(adm => {
          this._admcom.find({where:{adminId : adm.id}}).subscribe((resp)=>{
            adm.yourCompanies = resp;
          });
        })
        this.Admins = admins;
        this.dataAdmins = new MatTableDataSource(this.Admins);
        this.dataAdmins.sort = this.sort2;
        setTimeout(() => this.dataAdmins.paginator = this.paginator);
      });
    }

    getCompanies(){
      this._company.find({where: {isDeleted:false}}).subscribe((comp: any) => {
        if(comp && comp.length){
          this.firstCom.push(comp[0].name);
          comp.shift();
          comp.forEach(element => {
          this.companies.push(element.name)
        });
        }
      })
    }
  
  
  openModalNewUser(): void {
    const modalNewUser = this.dialog.open(NuevoAdminComponent, {
      width: '70%',
      data: {Companies: this.companies, Primera: this.firstCom}
    });
    modalNewUser.afterClosed().subscribe(result => {
      if(result){
        console.log('The dialog was closed');
        this.getUsers();
        this.getSuperAdmins();
      }
    });
  }

  openModalCompanies(admin, index): void {
    const modalCompanies = this.dialog.open(AdminsEmpresas, {
      width: '25%',
      data: {
        Admin: admin, index
      }
    });
  }

  openModalEditAdmin(admin, index) {
    const modalEditAdmin = this.dialog.open(EditarAdminComponent, {
      width: '45%',
      data: {
        Admin: admin, index
      }
    });
    modalEditAdmin.afterClosed().subscribe(res => {
      if(res){
        console.log('The dialog was closed');
        this.getUsers();
      }
    })
  }

  openModalEditPassword(admin,index): void {
    const modalEditPassword = this.dialog.open(EditarPasswordComponent, {
      width: '45%',
      data: admin
    });
    modalEditPassword.afterClosed().subscribe(result => {
      if(result){
        console.log('The dialog was closed');
        this.getUsers();
      }
    });
  }

  deleteAdmin(admin, index): void {
    const modalDelete = this.dialog.open(BorrarAdminComponent, {
      width: '30%',
      data: {
        Admin: admin, index
      }
    });
    modalDelete.afterClosed().subscribe(result => {
      if(result){
        console.log('The dialog was closed');
        this.getUsers();
      }
    });
  }
}

@Component({
  selector: 'mod-admins-empresas',
  template:`
  <div class="container-fluid">
    <div id="_header-container" class="row" style="align-content:space-between">
      <div id="title" class="col-lg-9">
        <div style="font-size: 22px">Empresas a cargo</div>
      </div>
      <div id="button-close" class="col-lg-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
    <br>
    <div id="_body-container">
      <div style="color: darkgrey" *ngFor="let company of companies">
        {{company.name}}
      </div>
    </div>
  </div> `
})

export class AdminsEmpresas {

  companies: any = [];

  constructor(
    public dialogRef : MatDialogRef<AdminsEmpresas>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _AdminCompany: Admin_companyApi,
    private _company: CompanyApi
    ) {
      this._company.find({where:{isDeleted:false}, include: {relation: 'adminCompanies'}}).subscribe((com: any)=>{
        com.forEach(element => {
          if(element.adminCompanies && element.adminCompanies.length){
            element.adminCompanies.forEach(data => {
              if(data.adminId == this.data.Admin.id){
                this.companies.push(element);
              }
            });
          }
        });
      });

      // this._AdminCompany.find({where: {adminId: this.data.Admin.id}}).subscribe((admcoms: any)=>{
      //   admcoms.forEach(adm => {
      //     this._company.findById(adm.companyId).subscribe((company: any)=>{
      //       if(company.isDeleted == false){
      //         this.companies.push(company);
      //       }
      //     });
      //   });
      // });
    }


  closeModal(): void {
    this.dialogRef.close();
  }
}
