import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { CertificateApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-eliminar-certificado',
  templateUrl: './eliminar-certificado.component.html',
  styleUrls: ['./eliminar-certificado.component.scss']
})
export class EliminarCertificadoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EliminarCertificadoComponent>,
    private formBuilder: FormBuilder,
    private _certificate: CertificateApi,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete() {
    this._certificate.deleteById(this.data.cert.id).subscribe(() => {
      this._snackBar.open('Certificado eliminado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(this.data);
    });
  }

}
