import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserApi, StoreApi, LoopBackAuth, ContainerApi } from '../../../services/shared/sdk';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatSnackBar} from '@angular/material/snack-bar';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-edit-comercio',
  templateUrl: './edit-comercio.component.html',
  styleUrls: ['./edit-comercio.component.scss']
})
export class EditComercioComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  amountCtrl = new FormControl();
  stateCtrl = new FormControl();
  filteredAmount: Observable<string[]>;
  filteredState: Observable<string[]>;
  @ViewChild('amountInput') amountInput: ElementRef<HTMLInputElement>;
  @ViewChild('stateInput') stateInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  shops = [];
  form: FormGroup;
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });  
  uploaderGift: FileUploader = new FileUploader({ url: this.uploadURL });
  uploaderTerm: FileUploader = new FileUploader({ url: this.uploadURL });
  imageLogo: any;
  imageGift: any;
  termsPdf: any;
  amounts: any = [
    100,200,500,1000,2000,5000
  ];
  states = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco'	,
    'México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ];
  store:any  ={};
  storeId: number;
  images = {
    logo: '',
    giftDesign: ''
  }


  constructor(
    private router: Router, 
    private _commerce: StoreApi,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loopBackAuth: LoopBackAuth,
    private actRoute: ActivatedRoute,
    private _container: ContainerApi
  ) { }

  ngOnInit() {
    this.actRoute.params.subscribe(params =>{
      this.storeId =  params.id;
    });

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      terms: ['', Validators.required],
      color:[''],
      productType:[''],
      isFile:[''],
      isTicket:[''],
      isGenerated:['']
    });

    this._commerce.findById(this.storeId).subscribe((store: any) => {
      this.store = store;
      console.log(store)
      this.imageLogo = store.logo ? BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + store.logo : null;
      this.imageGift = store.giftDesign ? BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + store.giftDesign : null;
      this.images = {
        logo: store.logo,
        giftDesign: store.giftDesign
      }
      this.form = this.formBuilder.group({
        name: [this.store.name, Validators.required],
        description: [this.store.description, Validators.required],
        terms: [this.store.terms, Validators.required],
        color:[this.store.color],
        productType:[this.store.productType],
        isFile:[this.store.isFile],
        isTicket:[this.store.isTicket],
        isGenerated:[this.store.isGenerated]
      });
      this.termsPdf = store.termsExcerpt;
    });
    
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.store.values.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.amountCtrl.setValue(null);
  }

  addState(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.store.currentStates.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.stateCtrl.setValue(null);
  }

  remove(amount: string): void {
    const index = this.store.values.indexOf(amount);

    if (index >= 0) {
      this.store.values.splice(index, 1);
    }
  }

  removeState(state: string): void {
    const index = this.store.currentStates.indexOf(state);

    if (index >= 0) {
      this.store.currentStates.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.store.values.push(event.option.viewValue);
    this.amountInput.nativeElement.value = '';
    this.amountCtrl.setValue(null);
  }

  selectedState(event: MatAutocompleteSelectedEvent): void {
    this.store.currentStates.push(event.option.viewValue);
    this.stateInput.nativeElement.value = '';
    this.stateCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.amounts.filter(amount => amount.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterState(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.states.filter(state => state.toLowerCase().indexOf(filterValue) === 0);
  }

  test(){
    console.log(this.form.value.productType)
    console.log(this.store.productType)
  }

  saveStore() {
    if(this.form.value.productType === "1"){
      this.store.isFile = this.form.value.isFile;
      this.store.isTicket = this.form.value.isTicket;
      this.store.isFile = this.form.value.isFile;
      this.store.isGenerated = this.form.value.isGenerated;
      this.store.color = this.form.value.color;
      this.store.productType = 1;
      this.store.isLink = false;
    }
    

    if(this.form.value.productType === "0"){
      this.store.isLink = true;
      this.store.isFile = false;
      this.store.isTicket = false;
      this.store.isGenerated = false;
      this.store.productType = 0;
      this.store.color = this.form.value.color;
    }

    this.store.color = this.form.value.color;


    this._commerce.patchAttributes(this.storeId, this.store).subscribe((newStore) => {
      console.log(newStore);
      if (this.uploader.queue.length) {
        this.uploader.uploadAll();
        this.uploader.onCompleteAll() ;
        this._container.removeFile('cloudgiftmaster', this.images.logo).subscribe((resp) => {
          console.log(resp);          
        });
          this.router.navigate(['lista-comercios']);
        
      } else {
        this.router.navigate(['lista-comercios']);
      }
      if (this.uploaderGift.queue.length) {
        this.uploaderGift.uploadAll();
        this._container.removeFile('cloudgiftmaster', this.images.giftDesign).subscribe((resp) => {
          console.log(resp);          
        });
      }
      if (this.uploaderTerm.queue.length) {
        this.uploaderTerm.uploadAll();
        if(this.termsPdf){
          this._container.removeFile('cloudgiftmaster', this.termsPdf).subscribe((resp) => {
            console.log(resp);          
          });
        }
      }
      
    });
    
  }

  uploadFileLogo(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageLogo = eventReader.target.result;
    };

    this.uploader.queue[0].file.name = UUID.UUID();
    this.store.logo = this.uploader.queue[0].file.name;
  }

  uploadFileGift(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageGift = eventReader.target.result;
    };

    this.uploaderGift.queue[0].file.name = UUID.UUID();
    this.store.giftDesign = this.uploaderGift.queue[0].file.name;
  }

  uploadFileTerm(event) {
    this.uploaderTerm.queue[0].file.name = UUID.UUID() +'.pdf';
    this.store.termsExcerpt = this.uploaderTerm.queue[0].file.name;
  }

}
