/* tslint:disable */
import {
  Company,
  CertificateCategory,
  Store,
  Gifts,
  CertTemplate,
  User,
  CertSchedule
} from '../index';

declare var Object: any;
export interface CertificateInterface {
  "userId"?: number;
  "categoryId"?: number;
  "companyId"?: number;
  "expirationDate"?: Date;
  "status"?: string;
  "value"?: number;
  "code"?: string;
  "card"?: string;
  "message"?: string;
  "templateId"?: number;
  "redemptionDate"?: Date;
  "amountRedeemed"?: number;
  "img"?: string;
  "title"?: string;
  "modify"?: any;
  "total"?: number;
  "scheduleId"?: number;
  "dateAvailable"?: Date;
  "createdAt"?: Date;
  "history"?: Array<any>;
  "isWelcome"?: boolean;
  "info"?: Array<any>;
  "rest"?: Array<any>;
  "color"?: string;
  "diploma"?: string;
  "design"?: Array<any>;
  "messagecert"?: string;
  "valuehistory"?: number;
  "id"?: number;
  company?: Company;
  category?: CertificateCategory;
  stores?: Store[];
  certificates?: Certificate[];
  gifts?: Gifts[];
  template?: CertTemplate;
  user?: User;
  schedule?: CertSchedule;
}

export class Certificate implements CertificateInterface {
  "userId": number;
  "categoryId": number;
  "companyId": number;
  "expirationDate": Date;
  "status": string;
  "value": number;
  "code": string;
  "card": string;
  "message": string;
  "templateId": number;
  "redemptionDate": Date;
  "amountRedeemed": number;
  "img": string;
  "title": string;
  "modify": any;
  "total": number;
  "scheduleId": number;
  "dateAvailable": Date;
  "createdAt": Date;
  "history": Array<any>;
  "isWelcome": boolean;
  "info": Array<any>;
  "rest": Array<any>;
  "color": string;
  "diploma": string;
  "design": Array<any>;
  "messagecert": string;
  "valuehistory": number;
  "id": number;
  company: Company;
  category: CertificateCategory;
  stores: Store[];
  certificates: Certificate[];
  gifts: Gifts[];
  template: CertTemplate;
  user: User;
  schedule: CertSchedule;
  constructor(data?: CertificateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Certificate`.
   */
  public static getModelName() {
    return "Certificate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Certificate for dynamic purposes.
  **/
  public static factory(data: CertificateInterface): Certificate{
    return new Certificate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Certificate',
      plural: 'Certificates',
      path: 'Certificates',
      idName: 'id',
      properties: {
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'available'
        },
        "value": {
          name: 'value',
          type: 'number'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "card": {
          name: 'card',
          type: 'string'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "templateId": {
          name: 'templateId',
          type: 'number'
        },
        "redemptionDate": {
          name: 'redemptionDate',
          type: 'Date'
        },
        "amountRedeemed": {
          name: 'amountRedeemed',
          type: 'number'
        },
        "img": {
          name: 'img',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "modify": {
          name: 'modify',
          type: 'any'
        },
        "total": {
          name: 'total',
          type: 'number'
        },
        "scheduleId": {
          name: 'scheduleId',
          type: 'number'
        },
        "dateAvailable": {
          name: 'dateAvailable',
          type: 'Date'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "history": {
          name: 'history',
          type: 'Array&lt;any&gt;'
        },
        "isWelcome": {
          name: 'isWelcome',
          type: 'boolean',
          default: false
        },
        "info": {
          name: 'info',
          type: 'Array&lt;any&gt;'
        },
        "rest": {
          name: 'rest',
          type: 'Array&lt;any&gt;'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "diploma": {
          name: 'diploma',
          type: 'string'
        },
        "design": {
          name: 'design',
          type: 'Array&lt;any&gt;'
        },
        "messagecert": {
          name: 'messagecert',
          type: 'string'
        },
        "valuehistory": {
          name: 'valuehistory',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'CertificateCategory',
          model: 'CertificateCategory',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
        stores: {
          name: 'stores',
          type: 'Store[]',
          model: 'Store',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'categoryId'
        },
        certificates: {
          name: 'certificates',
          type: 'Certificate[]',
          model: 'Certificate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        gifts: {
          name: 'gifts',
          type: 'Gifts[]',
          model: 'Gifts',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'certificateId'
        },
        template: {
          name: 'template',
          type: 'CertTemplate',
          model: 'CertTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'templateId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        schedule: {
          name: 'schedule',
          type: 'CertSchedule',
          model: 'CertSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'scheduleId',
          keyTo: 'id'
        },
      }
    }
  }
}
