import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder,FormGroup,Validators, PatternValidator } from '@angular/forms';
import {UserApi, CertificateApi, CertificateCategoryApi} from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import * as moment from 'moment';

@Component({
  selector: 'app-asigna-certificado',
  templateUrl: './asigna-certificado.component.html',
  styleUrls: ['./asigna-certificado.component.scss']
})
export class AsignaCertificadoComponent implements OnInit {
certificados = [];
  constructor(
    public dialogRef: MatDialogRef<AsignaCertificadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user: UserApi,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private _cert: CertificateApi
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.getCertificates();
  }

  getCertificates() {
    this._cert.find({
      where: {
        companyId: this.data.companyId,
        isWelcome: true
      }
    }).subscribe(certs => {
      certs.forEach((certificado: any) => {
        if(!certificado.img || certificado.img == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png"){
          certificado.imagen = certificado.img
        }
        if(certificado.img != "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png"){
          certificado.imagen = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + certificado.img
        }
        certificado.expirationDate = moment().add(30, 'days');
        // certificado.imagen = 'assets/images/cert.png'
      });
      this.certificados = certs;
    });
  }

  closeModal(){
    this.dialogRef.close();
  }

  asignar(cert) {
    const certificate = {
      categoryId: cert.categoryId,
      companyId: cert.companyId,
      expirationDate: cert.expirationDate,
      img: cert.img,
      message: cert.message,
      status: 'available',
      value: cert.value,
      userId: this.data.userId,
      createdAt: new Date(),
      isWelcome: false,
      history: [
        {
          status: 'available',
          date: new Date(),
          userId: this._user.getCurrentId()
        }
      ]
    }
    
    this._cert.create(certificate).subscribe((newCert) => {
      this.snackBar.open('Certificado asignado correctamente', 'Listo!', {
           duration: 5000
      });
      this.dialogRef.close(newCert);
    });
    
  }

}
