import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { CompanyApi, UserApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaEmpresaComponent implements OnInit {
  displayedColumns: string[] = ['name', 'attendantEmail', 'rfc', 'createdAt', 'actions'];
  dataSource = new MatTableDataSource();
  companies;
  

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('singlePaginator') paginator: MatPaginator;

  ngOnInit() {
    
  }

  constructor(
    private router: Router, 
    private _company: CompanyApi,
    private _user: UserApi,
    public dialog: MatDialog
    ) {
      this.getCompanies();
      
     }

  goNewCompany () {
    this.router.navigate(['/nueva-empresa']);
  }

  goEditCompany (row){
    console.log(row);    
    this.router.navigate(['/editar-empresa', row.id]);
  }

  getCompanies() {
    this._company.find({
      where: {
        isDeleted: false
      }, include: [{
        relation: 'stores'
      }, {
        relation: 'adminCompanies',
        scope: {
          include: 'admin'
        }
      }]
    }).subscribe((companies: any) => {
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.companies = companies;
        } else {
          this.companies = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id);
          });
        }
        this.dataSource = new MatTableDataSource(this.companies);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
        
      });
      
    });
  }

    openDialogDetails(row) {
      const dialogRef = this.dialog.open(DialogCompanyDetails, {
        width: '40%',
        data: row
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        
      });
    }

    openDialogShops(row) {
      const dialogRef = this.dialog.open(DialogCompanyShops, {
        width: '40%',
        data: row
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        
      });
    }

    openDialogDelete(row, index) {
      row.index =index;
      const dialogRef = this.dialog.open(DialogCompanyDelete, {
        width: '40%',
        data: row
      });
  
      dialogRef.afterClosed().subscribe(data => {
        console.log('The dialog was closed');
        if (data) {
          this.companies.splice(data.index, 1);
          this.dataSource = new MatTableDataSource(this.companies);

        }
        
      });
    }

    openDialogPassword(row) {
      const dialogRef = this.dialog.open(DialogCompanyPassword, {
        width: '40%',
        data: row
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.dataSource = new MatTableDataSource(this.companies);
      });
    }

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

}
//Componente para ver los detalles de la empresa
@Component({
  selector: 'dialog-company-details',
  templateUrl: 'modals/dialog-company-details.html'
})
export class DialogCompanyDetails {

  constructor(
    public dialogRef: MatDialogRef<DialogCompanyDetails>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data);
      data.admins = '';
      data.adminCompanies.forEach((element, index) => {
        data.admins += ' ' + element.admin.name;
        if (index < data.adminCompanies.length - 1 ){
          data.admins+=',';
        }
      });
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//Componente para mostrar los comercios
@Component({
  selector: 'dialog-company-shops',
  templateUrl: 'modals/dialog-company-shops.html'
})
export class DialogCompanyShops {

  constructor(
    public dialogRef: MatDialogRef<DialogCompanyDetails>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
      console.log(data);
      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
// Componente para borrar empresa
@Component({
  selector: 'dialog-company-delete',
  templateUrl: 'modals/dialog-company-delete.html'
})
export class DialogCompanyDelete{
accepted = false;
newPassword = '';
confirmPassword = '';

  constructor(
    public dialogRef: MatDialogRef<DialogCompanyDelete>,
    private _company: CompanyApi,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
          
    }

    delete() {
      this._company.patchAttributes(this.data.id,{
        isDeleted: true
      }).subscribe((companyUpdate: any) => {
        this._snackBar.open('Empresa eliminada', 'Listo!', {
          duration: 2000
        });
        this.dialogRef.close(this.data.index);
      });
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//Componente para cambiar password
@Component({
  selector: 'dialog-company-password',
  templateUrl: 'modals/dialog-company-password.html'
})
export class DialogCompanyPassword {
  form: FormGroup;
  newPassword;
  confirmPassword;

  constructor(
    public dialogRef: MatDialogRef<DialogCompanyPassword>,
    private _user: UserApi,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {      
      
      this.form = this.formBuilder.group({
        newpassword1: ['', Validators.required],
        newpassword2: ['', Validators.required ]
      })
    }

    updatePassword() {
      this._user.find({
        where:{
          email: this.data.attendantEmail
        }
      }).subscribe((user: any) => {
        console.log(user[0].id);
        this._user.updatePassword(user[0].id, this.newPassword).subscribe((resp) => {
          console.log(resp);
          this.dialogRef.close();
          this._snackBar.open('Contraseña actualizada!', 'Listo!', {
          duration: 4000
        });
        },error => {
          this._snackBar.open('Ocurrió un error', 'ERROR', {
            duration: 4000
          });
        });
      });
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
