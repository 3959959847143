import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserApi, StoreApi } from '../../../services/shared/sdk';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import { DetalleComercioComponent } from '../detalle-comercio/detalle-comercio.component';
import { DeleteComercioComponent } from '../delete-comercio/delete-comercio.component';

@Component({
  selector: 'app-lista-comercios',
  templateUrl: './lista-comercios.component.html',
  styleUrls: ['./lista-comercios.component.scss']
})
export class ListaComerciosComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['Lemon'];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  

shops = [];
  constructor(
    private router: Router, 
    private _commerce: StoreApi,
    public dialog: MatDialog,
  ) { 

    this._commerce.find({
      where: {
        isDeleted: false
      }
    }).subscribe((shops) => {     
      this.shops = shops;
      this.shops.forEach((shop) => {
        if (shop.logo){
          shop.image = 'url(' + BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + shop.logo + ')';
          shop.logoImage = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + shop.logo;
        } else {
          shop.image = "url(assets/images/commerce.png)"
          shop.logoImage = "assets/images/commerce.png"
        }
        if (shop.giftDesign) {
          shop.giftDesignImage = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + shop.giftDesign;
        } else {
          shop.giftDesignImage = 'assets/images/commerce.png';
        }
      });
    });
  }

  ngOnInit() {
  }

  openDialogDetailShop(row) {
     const dialogRef = this.dialog.open(DetalleComercioComponent, {
       width: '60%',
       height: '72%',
       maxHeight: '90vh',
       data: {
       store: row
       }
     });

     dialogRef.afterClosed().subscribe(balance => {
       
      
     });
  }

  openDialogDeleteShop(row, index) {
    console.log(index);
    
    const dialogRef = this.dialog.open(DeleteComercioComponent, {
      width: '50%',
      data: {
      store: row,
      index: index
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.shops.splice(data.index, 1);
      }
     
    });
 }


}
