import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource,MatPaginator} from '@angular/material';
import { Router } from '@angular/router';
import { CampusApi, CompanyApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NuevoCampusComponent } from '../nuevo-campus/nuevo-campus.component';
import { DetallesCampusComponent } from '../detalles-campus/detalles-campus.component';
import { EliminarCampusComponent } from '../eliminar-campus/eliminar-campus.component';
import { EditarCampusComponent } from '../editar-campus/editar-campus.component';


@Component({
  selector: 'app-lista-campus',
  templateUrl: './lista-campus.component.html',
  styleUrls: ['./lista-campus.component.scss']
})
export class ListaCampusComponent implements OnInit {
  displayedColumns: string[] = ['id','companyName','name', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('singlePaginator') paginator: MatPaginator;
  companies;

  constructor(
    private router: Router, 
    private _campus: CampusApi,
    private _company: CompanyApi,
    public dialog: MatDialog
  ) {
    this.getCampus();
    this.getCompanies();
   }

  ngOnInit() {
  }

  getCampus() {
    this._campus.find({include:{relation: 'company'}}).subscribe((campuses: any) => {
      campuses.forEach(element => {
        element.companyName = element.company.name;
      });
      
      this.dataSource = new MatTableDataSource(campuses);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    });
  }

  getCompanies(){
    this._company.find({where: {isDeleted: false}}).subscribe(resp => {
      this.companies = resp;
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openNewCampus(){
    const newCampus = this.dialog.open( NuevoCampusComponent, {
      width: '40%',
      data: this.companies
    });
    newCampus.afterClosed().subscribe(result => {
      if(result){
        console.log('The dialog was closed');
        this.getCampus();
      }
    })
  }

  goEditCampus(element){
    const editCampus = this.dialog.open( EditarCampusComponent, {
      width: '40%',
      data: {dataCampus: element,companies:this.companies}
    });
    editCampus.afterClosed().subscribe(result => {
      if(result){
        console.log('The dialog was closed');
        this.getCampus();
      }
    })
  }

  openDialogDetails(element){
    const details = this.dialog.open( DetallesCampusComponent, {
      width: '40%',
      data: element
    });
  }

  openDialogDelete(element, index){
    const deleteCampus = this.dialog.open( EliminarCampusComponent , {
      width: '30%',
      data: {element,index}
    });
    deleteCampus.afterClosed().subscribe(result => {
      if(result){
        console.log('The dialog was closed');
        this.getCampus();
      }
    });
  }

}
