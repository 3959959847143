import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserApi, Group_employeeApi, User, CampusApi } from '../../../services/shared/sdk';
import * as moment from "moment";
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CalendarDialogComponent } from '../../../components/calendar-dialog/calendar-dialog.component';

moment.locale('es');

@Component({
  selector: 'app-editar-colaborador',
  templateUrl: './editar-colaborador.component.html',
  styleUrls: ['./editar-colaborador.component.scss']
})
export class EditarColaboradorComponent implements OnInit {

  form: FormGroup;
  patronEmail = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";
  months = moment.months();

  displayedColumns: string[] = ['name', 'members', 'certificados', 'lastActivity', 'actions'];
  dataGroup;
  userId;
  dataUser: any;
  campuses: any[];
  states = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ];

  groups;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private _user: UserApi,
    private _group_emp: Group_employeeApi,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _campus: CampusApi
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(res => {
      this.userId = res.id;
    });

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.patronEmail)]],
      birthdate: [''],
      dateAdmission: [''],
      payrollNumber: [''],
      country: ['', Validators.required],
      campusId: [null, Validators.required],
      years: [null, Validators.required]
    });

    this._user.findById(this.userId, {
      include: [
        {
          relation: 'groupEmployees',
          scope: {
            include: {
              relation: 'group',
              scope: {
                include: {
                  relation: 'schedules'
                }
              }
            }
          }
        }]
    }).subscribe(async (User: User) => {
      if (User.groupEmployees) {
        User.groupEmployees.forEach((element: any) => {
          this._group_emp.find({ where: { groupId: element.groupId } }).subscribe(resp => {
            element.members = resp.length;
          });
        });
        this.groups = User.groupEmployees;
        this.dataGroup = new MatTableDataSource(this.groups);
      }
      this.dataUser = User;
      if (this.dataUser.dateAdmission) {
        var Admision = new Date(this.dataUser.dateAdmission);
      }
      if (this.dataUser.birthdate) {
        var Birthdate = new Date(this.dataUser.birthdate);
      }
      this.campuses = await this._campus.find({
        where: {
          companyId: User.companyId
        }
      }).toPromise()
      this.form.patchValue({
        name: this.dataUser.name,
        email: this.dataUser.email,
        birthdate: Birthdate,
        dateAdmission: Admision,
        payrollNumber: this.dataUser.payrollNumber,
        country: this.dataUser.country,
        campusId: this.dataUser.campusId,
        years: this.dataUser.years
      });
      this.form.markAsDirty()
    })
  }

  editUser() {

    console.log(this.form.value);
    const patched = { ...this.form.value }
    this._user.patchAttributes(this.userId, patched).subscribe(res => {
      console.log(res);
      this.snackBar.open('Colaborador editado', 'Listo!', {
        duration: 2000
      });
      this.router.navigate(['/lista-colaboradores']);
    })
  }

  getBirthdate() {
    let date = this.form.get('birthdate').value;
    return (date ? moment(date).format('DD/MM/YYYY') : 'Selecciona una fecha');
  }

  getAdmision() {
    let date = this.form.get('dateAdmission').value;
    return (date ? moment(date).format('DD/MM/YYYY') : 'Selecciona una fecha');
  }

  openCalendarDialog() {
    this.dialog.open(CalendarDialogComponent, {
      ...CalendarDialogComponent.box,
      data: {
        date: this.form.get('birthdate').value
      }
    }).afterClosed().subscribe(({ date } = { date: null }) => {
      this.form.get('birthdate').setValue(date);
    });
  }

  openCalendarDialog2() {
    this.dialog.open(CalendarDialogComponent, {
      ...CalendarDialogComponent.box,
      data: {
        date: this.form.get('dateAdmission').value
      }
    }).afterClosed().subscribe(({ date } = { date: null }) => {
      this.form.get('dateAdmission').setValue(date);
    });
  }

  // childrens(event){
  //   if(this.form.value.childrenBirthdays && this.form.value.childrenBirthdays.length == 0){
  //     for(let i = 0; i== event.target.value; i++){
  //       this.form.value.childrenBirthdays.push({day:1,month:'Enero'});
  //     }
  //   }
  //   if((this.form.value.childrenBirthdays && this.form.value.childrenBirthdays.length > 0) && this.form.value.childrenBirthdays < event.target.value){
  //     for(let i = this.form.value.childrenBirthdays.length; i == event.target.value; i++){
  //       this.form.value.childrenBirthdays.push({day:1,month:'Enero'});
  //     }
  //   }
  //   if((this.form.value.childrenBirthdays && this.form.value.childrenBirthdays.length > 0) && this.form.value.childrenBirthdays > event.target.value){
  //     for(let i = this.form.value.childrenBirthdays.length; i == event.target.value; i--)
  //     this.form.value.childrenBirthdays.pop();
  //   }
  // }

  childrenNumberChange() {
    let control = <FormArray>this.form.get('childrenBirthdates');
    control.clearValidators();
    for (let i = 0; i < this.form.get('childrenNumber').value; i++) {
      let day = null;
      let month = '';
      if (this.dataUser.childrenBirthdates[i]) {
        day = this.dataUser.childrenBirthdates[i].day;
        month = this.dataUser.childrenBirthdates[i].month;
      }
      const fc = new FormGroup({
        day: new FormControl(day, [Validators.required]),
        month: new FormControl(month, [Validators.required])
      });
      control.push(fc);
    }
  }

}
