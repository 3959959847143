/* tslint:disable */
import {
  Store,
  Company,
  User
} from '../index';

declare var Object: any;
export interface GiftsInterface {
  "createdAt"?: Date;
  "updatedAt"?: any;
  "status"?: string;
  "value"?: number;
  "userId"?: number;
  "certs"?: Array<any>;
  "storeId"?: number;
  "file"?: string;
  "orderId"?: string;
  "history"?: Array<any>;
  "companyId"?: number;
  "shippingInfo"?: any;
  "ticketInfo"?: any;
  "code"?: string;
  "card"?: string;
  "expiration"?: string;
  "link"?: string;
  "nip"?: string;
  "info"?: Array<any>;
  "expirationDate"?: Date;
  "uploadedDate"?: Date;
  "downloadHistory"?: Array<any>;
  "certificateDetail"?: Array<any>;
  "id"?: number;
  "certificateId"?: number;
  store?: Store;
  company?: Company;
  employee?: User;
}

export class Gifts implements GiftsInterface {
  "createdAt": Date;
  "updatedAt": any;
  "status": string;
  "value": number;
  "userId": number;
  "certs": Array<any>;
  "storeId": number;
  "file": string;
  "orderId": string;
  "history": Array<any>;
  "companyId": number;
  "shippingInfo": any;
  "ticketInfo": any;
  "code": string;
  "card": string;
  "expiration": string;
  "link": string;
  "nip": string;
  "info": Array<any>;
  "expirationDate": Date;
  "uploadedDate": Date;
  "downloadHistory": Array<any>;
  "certificateDetail": Array<any>;
  "id": number;
  "certificateId": number;
  store: Store;
  company: Company;
  employee: User;
  constructor(data?: GiftsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Gifts`.
   */
  public static getModelName() {
    return "Gifts";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Gifts for dynamic purposes.
  **/
  public static factory(data: GiftsInterface): Gifts{
    return new Gifts(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Gifts',
      plural: 'Gifts',
      path: 'Gifts',
      idName: 'id',
      properties: {
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'process'
        },
        "value": {
          name: 'value',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "certs": {
          name: 'certs',
          type: 'Array&lt;any&gt;'
        },
        "storeId": {
          name: 'storeId',
          type: 'number'
        },
        "file": {
          name: 'file',
          type: 'string'
        },
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "history": {
          name: 'history',
          type: 'Array&lt;any&gt;'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "shippingInfo": {
          name: 'shippingInfo',
          type: 'any'
        },
        "ticketInfo": {
          name: 'ticketInfo',
          type: 'any'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "card": {
          name: 'card',
          type: 'string'
        },
        "expiration": {
          name: 'expiration',
          type: 'string'
        },
        "link": {
          name: 'link',
          type: 'string'
        },
        "nip": {
          name: 'nip',
          type: 'string'
        },
        "info": {
          name: 'info',
          type: 'Array&lt;any&gt;'
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "uploadedDate": {
          name: 'uploadedDate',
          type: 'Date'
        },
        "downloadHistory": {
          name: 'downloadHistory',
          type: 'Array&lt;any&gt;'
        },
        "certificateDetail": {
          name: 'certificateDetail',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "certificateId": {
          name: 'certificateId',
          type: 'number'
        },
      },
      relations: {
        store: {
          name: 'store',
          type: 'Store',
          model: 'Store',
          relationType: 'belongsTo',
                  keyFrom: 'storeId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
