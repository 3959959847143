import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder,FormGroup,Validators, FormArray, FormControl } from '@angular/forms';
import { CampusApi } from '../../../services/shared/sdk';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: "app-editar-campus",
  templateUrl: "./editar-campus.component.html",
  styleUrls: ["./editar-campus.component.scss"]
})
export class EditarCampusComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditarCampusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _campus: CampusApi,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.data.dataCampus.name, Validators.required],
      companyId: [this.data.dataCampus.companyId]
    });
  }

  editCampus() {
    this._campus
      .patchAttributes(this.data.dataCampus.id, this.form.value)
      .subscribe(resp => {
        this.dialogRef.close(resp);
        this.snackBar.open("Campus/Sede editado", "Listo!", {
          duration: 2000
        });
      }, err => {
        console.error(err);
        this.snackBar.open("Ocurrió un error", "ERROR", {
          duration: 2000
        });
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
