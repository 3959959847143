import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-integrantes-grupo',
  templateUrl: './integrantes-grupo.component.html',
  styleUrls: ['./integrantes-grupo.component.scss']
})
export class IntegrantesGrupoComponent implements OnInit {

  members;

  constructor(
    public dialogRef: MatDialogRef<IntegrantesGrupoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.members = this.data.group.members;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
