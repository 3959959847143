import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, CertificateDesignApi, CompanyApi, LoopBackAuth, ContainerApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';


@Component({
  selector: 'app-edit-diseno',
  templateUrl: './edit-diseno.component.html',
  styleUrls: ['./edit-diseno.component.scss']
})
export class EditDisenoComponent implements OnInit {
  form: FormGroup;
  dropdownSettings = {};
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });
  image: any;
  design = {
    image: '',
    designs: []
  }
  defaults = [
    {name:'Cumpleaños', id: 'Cumpleaños'},
    {name:'Cumpleaños de pareja', id: 'Cumpleaños de pareja'},
    {name: 'Cumpleaños de hijo', id: 'Cumpleaños de hijo'},
    {name: 'Aniversario de pareja', id: 'Aniversario de pareja'},
    {name: 'Antigüedad en la empresa', id: 'Antigüedad en la empresa'},
    {name: 'N/A', id: 'N/A'}
  ];
  diseno: any = {
    designs: [],
    image: ''
  };
  nameImage = '';
  categories;
  img;
  des;


  constructor(
    public dialogRef: MatDialogRef<EditDisenoComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _design: CertificateDesignApi,
    private _user: UserApi,
    private _company: CompanyApi,
    private loopBackAuth: LoopBackAuth,
    private _container: ContainerApi,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data);
    this.form = this.formBuilder.group({
      image: ['', Validators.required],
      designs: ['', Validators.required],
      type: ['', Validators.required]
    }); 

    this.image = this.data.diseno.image;
    this.img  = this.data.diseno.img;
    let words = this.data.diseno.img.split('/');
    this.nameImage = words[words.length - 1]
    this.des = this.data.diseno.designs;

    this.data.nameCategory = this.data.diseno.categoryId ? this.data.categories.find((cat) => cat.id == this.data.diseno.categoryId).name : 'sin seleccionar';
    
   }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 20,
      allowSearchFilter: false
    };
    this.categories = this.data.categories;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  uploadFileLogo(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.img = eventReader.target.result;
    };

    this.uploader.queue[0].file.name = UUID.UUID();
    this.nameImage = this.uploader.queue[0].file.name;    
  }

  onItemSelect(event){
    console.log(event);
  }

  onSelectAll(event){
    console.log(event);
  }

  editDesign() {
    let desig = []
    this.des.forEach(element => {
      if(element.name){
        desig.push(element.name);
      }
      else {
        desig.push(element);
      }
    });
    console.log(desig);
    if (this.uploader.queue.length) {
      this.uploader.uploadAll();
      this._design.patchAttributes(this.data.diseno.id,{designs: desig, image: this.nameImage, categoryId: this.data.diseno.categoryId}).subscribe(resp => {
        this._snackBar.open('Diseño editado', 'Listo!', {
          duration: 4000
        });
        this.dialogRef.close(resp);
      },error => {
        this._snackBar.open('Ocurrió un error', 'Error', {
          duration: 4000
        });
        console.error(error);
        this.dialogRef.close();
      })
    }

    else {
      this._design.patchAttributes(this.data.diseno.id,{designs: desig, categoryId: this.data.diseno.categoryId}).subscribe(resp => {
        this._snackBar.open('Diseño editado', 'Listo!', {
          duration: 4000
        });
        resp.index = this.data.index;
        this.dialogRef.close(resp);
      },error => {
        this._snackBar.open('Ocurrió un error', 'Error', {
          duration: 4000
        });
        console.error(error);
        this.dialogRef.close();
      })
    }
    
    
    // this._design.patchAttributes(this.data.diseno.id,{designs: this.data.diseno.desings, image: this.nameImage, categoryId: this.data.diseno.categoryId} ).subscribe(() => {
    //   if (this.uploader.queue.length) {
    //     this.uploader.uploadAll();
    //     this._container.removeFile('cloudgiftmaster', this.nameImage).subscribe();
    //   }
    //   this.dialogRef.close(this.data);
    // });
    
  }

}
