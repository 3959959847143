/* tslint:disable */
import {
  Store,
  Company,
  Certificate,
  User
} from '../index';

declare var Object: any;
export interface OrderEmployeeInterface {
  "employeeId"?: number;
  "companyId"?: number;
  "status"?: string;
  "createdAt"?: Date;
  "dateSend"?: Date;
  "certificateId"?: number;
  "storeId"?: number;
  "amount"?: number;
  "downloadDate"?: Date;
  "file"?: string;
  "id"?: number;
  "userId"?: number;
  store?: Store;
  company?: Company;
  certificate?: Certificate;
  employee?: User;
}

export class OrderEmployee implements OrderEmployeeInterface {
  "employeeId": number;
  "companyId": number;
  "status": string;
  "createdAt": Date;
  "dateSend": Date;
  "certificateId": number;
  "storeId": number;
  "amount": number;
  "downloadDate": Date;
  "file": string;
  "id": number;
  "userId": number;
  store: Store;
  company: Company;
  certificate: Certificate;
  employee: User;
  constructor(data?: OrderEmployeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OrderEmployee`.
   */
  public static getModelName() {
    return "OrderEmployee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of OrderEmployee for dynamic purposes.
  **/
  public static factory(data: OrderEmployeeInterface): OrderEmployee{
    return new OrderEmployee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'OrderEmployee',
      plural: 'OrderEmployees',
      path: 'OrderEmployees',
      idName: 'id',
      properties: {
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "dateSend": {
          name: 'dateSend',
          type: 'Date'
        },
        "certificateId": {
          name: 'certificateId',
          type: 'number'
        },
        "storeId": {
          name: 'storeId',
          type: 'number'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "downloadDate": {
          name: 'downloadDate',
          type: 'Date'
        },
        "file": {
          name: 'file',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
      },
      relations: {
        store: {
          name: 'store',
          type: 'Store',
          model: 'Store',
          relationType: 'belongsTo',
                  keyFrom: 'storeId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        certificate: {
          name: 'certificate',
          type: 'Certificate',
          model: 'Certificate',
          relationType: 'belongsTo',
                  keyFrom: 'certificateId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
      }
    }
  }
}
