import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserApi, BalanceHistoryApi, CompanyApi } from '../../../../services/shared/sdk';
import * as moment from 'moment';

@Component({
  selector: 'app-abono-empresa',
  templateUrl: './abono-empresa.component.html',
  styleUrls: ['./abono-empresa.component.scss']
})
export class AbonoEmpresaComponent implements OnInit {
  form: FormGroup;
  amount: number;
  company: any;
  categoryId: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AbonoEmpresaComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _balance: BalanceHistoryApi,
    private _user: UserApi
  ) {
    console.log(data);
    this.company = data.company;
    this.form = this.formBuilder.group({
      abono: ['', Validators.required]
    });

  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createCompanybalance() {
    switch (this.company.balanceType) {
      case 0:
        this.createAbono();
        break;
      case 1:
        this.createCategoryBalance();
        break;
      default:
        break;
    }
  }

  createCategoryBalance() {
    console.log(this.categoryId)
    const selectedCategory = this.company.categories.find(cat => cat.id == this.categoryId);
    let balance: any = {
      companyId: this.company.id,
      balance: this.amount,
      balanceUsed: 0,
      adminId: this._user.getCurrentId(),
      status: 'inBalance',
      categoryId: this.categoryId
    };

    this._balance.create(balance).subscribe((newBalance) => {
      this.company.balanceHistories.push(newBalance);
      this.company.saldodisponible = this.company.saldodisponible + this.amount;
      this.company.abonado = this.company.abonado + this.amount;
      this.dialogRef.close(this.data);
    }, (err: any) => {
      this._snackBar.open(err.message, 'Error!', {
        duration: 2000
      });
    });

  }

  createAbono() {
    let unidad: any;
    let cantidad: any;
    let expirationDate = null;

    if (this.company.periodicity != 'N/A') {
      unidad = this.company.periodicity == 'Mensual' ? 'M' : 'days';
      cantidad = this.company.periodicity == 'Mensual' ? 1 : this.form.value.periodicity == 'Semanal' ? 7 : 1;
      expirationDate = moment(new Date()).endOf('day').add(cantidad, unidad);
    }
    this._balance.create({
      companyId: this.company.id,
      balance: this.amount,
      balanceUsed: 0,
      adminId: this._user.getCurrentId(),
      status: 'inBalance',
      expirationDate: expirationDate
    }).subscribe((newAbono) => {
      console.log(newAbono);
      this._snackBar.open('Abono creado', 'Listo!', {
        duration: 2000
      });
      this.company.balanceHistories.push(newAbono);
      this.company.saldodisponible = this.company.saldodisponible + this.amount;
      this.dialogRef.close(this.data);
    });
  }
}
