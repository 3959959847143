import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { FormControl } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CompanyApi, UserApi, StoreApi, CertificateApi, TicketApi, CertScheduleApi, GiftsApi } from '../../services/shared/sdk';
import * as moment from 'moment';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  activeCompanies: any;
  usersRegistered: any;
  availableCommerces: any;
  activeTickets: any;
  certificados: any;
  companies;
  companyActive;
  usersCompany;
  usersUnregistered: any = [];
  certsCompany;
  gifts;
  period = "15left";
  prueba;
  companyArray = [];
  FechaActual = new Date();
  FechaAnterior = moment(this.FechaActual).subtract(15, "days");
  giftGraf;
  companyId;
  companies2;
  data1 = [];
  data2 = [];
  data3 = [];
  data4 = [];
  data5 = [];
  CertEntr;
  RegaEntr;
  EstaCert;
  TimeLine;
  ComePref;
  certXexpire;

  constructor(
    private _company: CompanyApi,
    private _user: UserApi,
    private _commerce: StoreApi,
    private _certificado: CertificateApi,
    private _ticket: TicketApi,
    private _certschedule: CertScheduleApi,
    private _gifts: GiftsApi,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    this.fromDate = calendar.getNext(calendar.getToday(), "d", -8);
    this.toDate = calendar.getToday();
  }

  CreaGrafica(data) {

    let prueba = data;
    prueba.sort((a, b) => b.cert - a.cert);
    let labelS = [];
    this.data1 = [];

    prueba.forEach(element => {
      labelS.push(`${element.cert} ${element.name}`);
      this.data1.push(element.cert);
    });


    var ctx = document.getElementById("CertEntr");
    if (this.CertEntr) {
      this.CertEntr.clear();
      this.CertEntr.destroy();
    }

    this.CertEntr = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: labelS,
        datasets: [
          {
            //label: '# of Votes',
            data: this.data1,
            backgroundColor: [
              "rgba(189, 189, 189, 1)",
              "rgba(158, 158, 158, 1)",
              "rgba(117, 117, 117, 1)",
              "rgba(97, 97, 97, 1)",
              "rgba(66, 66, 66, 1)"
            ],
            borderColor: [
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)"
            ],
            borderWidth: 2.5
          }
        ]
      },
      options: {
        legend: {
          position: "left"
        }
      }
    });

  }


  countCompanies(FechaAnterior, FechaActual) {
    this._company
      .find({
        where: {
          and: [
            { isDeleted: false },
            { createdAt: { between: [FechaAnterior, FechaActual] } }
          ]
        }
      })
      .subscribe(resp => {
        this.activeCompanies = Object.keys(resp).length;
      });
  }

  countUsers(FechaAnterior, FechaActual) {
    this._user
      .find({
        where: {
          and: [
            { isDeleted: false },
            { createdAt: { between: [FechaAnterior, FechaActual] } }
          ]
        }
      })
      .subscribe(resp => {
        this.usersRegistered = Object.keys(resp).length;
      });
  }

  countCommerces(FechaAnterior, FechaActual) {
    this._commerce
      .find({
        where: {
          and: [
            { isDeleted: false },
            { createdAt: { between: [FechaAnterior, FechaActual] } }
          ]
        }
      })
      .subscribe(resp => {
        this.availableCommerces = Object.keys(resp).length;
      });
  }

  countGifts(FechaAnterior, FechaActual) {
    this._gifts
      .getGifts({
        where: {
          and: [
            {
              or: [
                { status: "available" },
                { status: "changed" },
                { status: "expired" }
              ]
            },
            { createdAt: { between: [FechaAnterior, FechaActual] } }
          ]
        }
      })
      .subscribe((resp: any) => {
        this.gifts = resp;
      });
  }

  countTickets(FechaAnterior, FechaActual) {
    this._ticket
      .find({
        where: {
          and: [
            { status: "activo" },
            { createdAt: { between: [FechaAnterior, FechaActual] } }
          ]
        }
      })
      .subscribe(resp => {
        this.activeTickets = Object.keys(resp).length;
      });
  }

  change() {
    // FILTRO PARA ÚLTIMOS 15 DÍAS
    if (this.period == "15left") {
      let FechaActual = new Date();
      let FechaAnterior = moment(FechaActual).subtract(15, "days");

      this.getCompanies(FechaAnterior, FechaActual);

      this.countCompanies(FechaAnterior, FechaActual);
      this.countUsers(FechaAnterior, FechaActual);
      this.countCommerces(FechaAnterior, FechaActual);
      this.countGifts(FechaAnterior, FechaActual);
      this.countTickets(FechaAnterior, FechaActual);


      this.commerces(FechaAnterior, FechaActual);

      if (this.companyId) {
        this.countCerts(FechaAnterior, FechaActual);
        this.obtenerGifts(FechaAnterior, FechaActual);
        this.obtenerSaldos(FechaAnterior, FechaActual);
      }

    }
    // FILTRO PARA LOS ÚLTIMOS 30 DÍAS
    if (this.period == "30left") {
      let FechaActual = new Date();
      let FechaAnterior = moment(FechaActual).subtract(1, "months");

      this.getCompanies(FechaAnterior, FechaActual);

      this.countCompanies(FechaAnterior, FechaActual);
      this.countUsers(FechaAnterior, FechaActual);
      this.countCommerces(FechaAnterior, FechaActual);
      this.countGifts(FechaAnterior, FechaActual);
      this.countTickets(FechaAnterior, FechaActual);

      this.commerces(FechaAnterior, FechaActual);

      if (this.companyId) {
        this.countCerts(FechaAnterior, FechaActual);
        this.obtenerGifts(FechaAnterior, FechaActual)
        this.obtenerSaldos(FechaAnterior, FechaActual);
      }
    }

    // FILTRO PARA TODO EL PERIODO

    if (this.period == "all") {
      let test = [];
      this._company
        .find({
          where: {
            isDeleted: false
          }, include: {
            relation: 'schedules',
            scope: {
              where: { or: [{ status: "accepted" }, { status: "done" }] }
            }
          }
        }).subscribe((companies) => {
          this.companies = companies;
          this.activeCompanies = Object.keys(companies).length;
          this.companies.forEach((company: any, index) => {
            if (company.schedules.length > 0) {
              test.push({ name: company.name, cert: company.schedules.length });
            }
            if (index == this.companies.length - 1) {
              this.CreaGrafica(test);
            }
          });


        });

      this._commerce
        .find({
          where: {
            isDeleted: false
          },
          include: {
            relation: 'gifts',
            scope: {
              where: {
                or: [
                  { status: "available" },
                  { status: "changed" },
                  { status: "expired" }
                ]
              }
            }
          }
        })
        .subscribe((commerces: any) => {
          this.availableCommerces = Object.keys(commerces).length;
          if (commerces) {
            console.log(commerces)
            let Coms = [];
            commerces.forEach((com: any, index) => {
              if (com.gifts && com.gifts.length > 0) {
                Coms.push({ name: com.name, gift: com.gifts.length })
              }
              if (index == commerces.length - 1) {
                this.grafGifts(Coms);
              }
            });
          }
        });

      if (this.companyId) {
        this.certXexpire = 0;
        let data = [0, 0, 0, 0];
        let Fecha1 = new Date();
        let Fecha2 = moment(Fecha1).subtract(3, "days");
        this._certificado.find({ where: { companyId: this.companyId } }).subscribe((resp: any) => {
          if (resp) {
            resp.forEach((cert: any, index) => {
              if (cert.status == "available") {
                data[0] += 1;
              }
              if (cert.status == "inBalance") {
                data[1] += 1;
              }
              if (cert.status == "changed") {
                data[2] += 1;
              }
              if (cert.status == "available" && (cert.expirationDate < Fecha1 && cert.expirationDate > Fecha2)) {
                data[3] += 1;
                this.certXexpire += 1;
              }
              if (index == resp.length - 1) {
                this.grafCerts(data);
              }
            });
          }

          if (resp.length == 0) {
            this.grafCerts(data);
          }
        });

        this._commerce
          .find({
            where: {
              isDeleted: false
            },
            include: {
              relation: 'gifts',
              scope: {
                where: {
                  and: [
                    { companyId: this.companyId },
                    {
                      or: [
                        { status: "available" },
                        { status: "changed" },
                        { status: "expired" }
                      ]
                    }
                  ]
                }
              }
            }
          })
          .subscribe((commerces: any) => {
            if (commerces) {
              console.log(commerces);
              let Coms = [];
              let Total = 0;
              commerces.forEach((com: any, index) => {
                if (com.gifts && com.gifts.length > 0) {
                  Coms.push({ name: com.name, gift: com.gifts.length })
                  Total += com.gifts.length;
                }
                if (index == commerces.length - 1) {
                  this.giftsPercent(Coms, Total);
                }
              });
            }
          });
      }


      this.getUsers();
      this.getGifts();
      this.getTickets();
    }

    // FILTRO PARA FECHAS DEL CALENDARIO

    if (this.period == "custom") {
      if (this.fromDate && this.toDate && (this.fromDate != this.toDate)) {
        let FechaActual = moment(this.toDate).subtract(1, "month");  //Ajuste de fecha debido al desface de un mes que tiene el metodo moment respecto al calendario que toma los datos
        let FechaAnterior = moment(this.fromDate).subtract(1, "month");

        this.getCompanies(FechaAnterior, FechaActual);

        this.countCompanies(FechaAnterior, FechaActual);
        this.countUsers(FechaAnterior, FechaActual);
        this.countCommerces(FechaAnterior, FechaActual);
        this.countGifts(FechaAnterior, FechaActual);
        this.countTickets(FechaAnterior, FechaActual);

        this.commerces(FechaAnterior, FechaActual);

        if (this.companyId) {
          this.countCerts(FechaAnterior, FechaActual);
          this.obtenerGifts(FechaAnterior, FechaActual);
          this.obtenerSaldos(FechaAnterior, FechaActual);
        }
      }
      if (this.fromDate && this.toDate && (this.fromDate == this.toDate)) {
        let FechaActual = moment(this.toDate).subtract(1, "month");  //Ajuste de fecha debido al desface de un mes que tiene el metodo moment respecto al calendario que toma los datos
        let FechaAnterior = moment(this.fromDate).subtract(1, "month");
        FechaActual.add(23, 'hour');
        FechaActual.add(59, 'minute');

        console.log(FechaAnterior);
        console.log(FechaActual);

        this.getCompanies(FechaAnterior, FechaActual);

        this.countCompanies(FechaAnterior, FechaActual);
        this.countUsers(FechaAnterior, FechaActual);
        this.countCommerces(FechaAnterior, FechaActual);
        this.countGifts(FechaAnterior, FechaActual);
        this.countTickets(FechaAnterior, FechaActual);

        this.commerces(FechaAnterior, FechaActual);

        if (this.companyId) {
          this.countCerts(FechaAnterior, FechaActual);
          this.obtenerGifts(FechaAnterior, FechaActual);
          this.obtenerSaldos(FechaAnterior, FechaActual);
        }
      }
    }
  }

  getCompanies(FechaAnterior, FechaActual) {
    let test = [];
    this._company
      .find({
        where: {
          isDeleted: false
        }, include: {
          relation: 'schedules',
          scope: {
            where: {
              and: [
                { or: [{ status: "accepted" }, { status: "done" }] },
                { createdAt: { between: [FechaAnterior, FechaActual] } }
              ]
            }
          }
        }
      }).subscribe((companies) => {
        this.companies = companies;
        this.companies.forEach((company: any, index) => {
          if (company.schedules.length > 0) {
            test.push({ name: company.name, cert: company.schedules.length });
          }
          if (index == this.companies.length - 1) {
            this.CreaGrafica(test);
          }
        });
      });

  }



  showCompany(event) {
    this.companyId = event.target.value;
    this._company
      .findById(this.companyId, { include: ["balanceHistories"] })
      .subscribe((resp: any) => {
        if (resp.balanceHistories) {
          resp.abonado = 0;
          resp.balanceHistories.forEach(element => {
            resp.abonado = resp.abonado + element.balance;
          });
        }
        this.companyActive = resp;
      });

    this._user
      .find({ where: { and: [{ companyId: this.companyId }, { isDeleted: false }] } })
      .subscribe((res: any) => {
        this.usersUnregistered = [];
        res.forEach((element: any) => {
          if (element.emailVerified != true) {
            this.usersUnregistered.push(element);
          }
        });
        this.usersCompany = res;
        console.log(`Usuarios en la compañía ${res}`);
      });

    this._certschedule
      .find({ where: { and: [{ companyId: this.companyId }, { status: "accepted" }] } })
      .subscribe(certs => {
        this.certsCompany = certs;
      });

    if (this.period == "15left") {
      let FechaActual = new Date();
      let FechaAnterior = moment(FechaActual).subtract(15, "days");
      this.countCerts(FechaAnterior, FechaActual)
      this.obtenerGifts(FechaAnterior, FechaActual)
      this.obtenerSaldos(FechaAnterior, FechaActual);
    }

    if (this.period == "30left") {
      let FechaActual = new Date();
      let FechaAnterior = moment(FechaActual).subtract(1, "months");
      this.countCerts(FechaAnterior, FechaActual)
      this.obtenerGifts(FechaAnterior, FechaActual)
      this.obtenerSaldos(FechaAnterior, FechaActual);
    }

    if (this.period == "custom") {
      if (this.fromDate && this.toDate && (this.fromDate != this.toDate)) {
        let FechaActual = moment(this.toDate).subtract(1, "month");  //Ajuste de fecha debido al desface de un mes que tiene el metodo moment respecto al calendario que toma los datos
        let FechaAnterior = moment(this.fromDate).subtract(1, "month");
        this.countCerts(FechaAnterior, FechaActual)
        this.obtenerGifts(FechaAnterior, FechaActual)
        this.obtenerSaldos(FechaAnterior, FechaActual);
      }
      if (this.fromDate && this.toDate && (this.fromDate == this.toDate)) {
        let FechaActual = moment(this.toDate).subtract(1, "month");  //Ajuste de fecha debido al desface de un mes que tiene el metodo moment respecto al calendario que toma los datos
        let FechaAnterior = moment(this.fromDate).subtract(1, "month");
        FechaActual.add(1, 'day');

        this.countCerts(FechaAnterior, FechaActual)
        this.obtenerGifts(FechaAnterior, FechaActual)
        this.obtenerSaldos(FechaAnterior, FechaActual);
      }
    }

    if (this.period == "all") {
      let data = [0, 0, 0, 0];
      let Fecha1 = new Date();
      let Fecha2 = moment(Fecha1).subtract(3, "days");
      this._certificado.find({ where: { companyId: this.companyId } }).subscribe((resp: any) => {
        if (resp) {
          resp.forEach((cert: any, index) => {
            if (cert.status == "available") {
              data[0] += 1;
            }
            if (cert.status == "inBalance") {
              data[1] += 1;
            }
            if (cert.status == "changed") {
              data[2] += 1;
            }
            if (cert.status == "available" && (cert.expirationDate < Fecha1 && cert.expirationDate > Fecha2)) {
              data[3] += 1;
            }
            if (index == resp.length - 1) {
              this.grafCerts(data);
            }
          });
        }

        if (resp.length == 0) {
          this.grafCerts(data);
        }
      });

      this._commerce
        .find({
          where: {
            isDeleted: false
          },
          include: {
            relation: 'gifts',
            scope: {
              where: {
                and: [
                  { companyId: this.companyId },
                  {
                    or: [
                      { status: "available" },
                      { status: "changed" },
                      { status: "expired" }
                    ]
                  }
                ]
              }
            }
          }
        })
        .subscribe((commerces: any) => {
          if (commerces) {
            let Coms = [];
            let Total = 0;
            commerces.forEach((com: any, index) => {
              if (com.gifts && com.gifts.length > 0) {
                Coms.push({ name: com.name, gift: com.gifts.length })
                Total += com.gifts.length;
              }
              if (index == commerces.length - 1) {
                this.giftsPercent(Coms, Total);
              }
            });
          }
        });
    }
  }

  getCompanies2() {
    this._company.find({
      where: {
        isDeleted: false
      }, include: 'adminCompanies'
    }).subscribe((companies) => {
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.companies2 = companies;
        } else {
          this.companies2 = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id);
          });
        }
      });
    })
  }

  getUsers() {
    this._user
      .find({
        where: {
          isDeleted: false
        }
      })
      .subscribe((users: any) => {
        this.usersRegistered = Object.keys(users).length;
      });
  }

  getCommerces() {
    this._commerce
      .find({
        where: {
          isDeleted: false
        }
      })
      .subscribe((commerces: any) => {
        this.availableCommerces = Object.keys(commerces).length;
      });
  }


  getTickets() {
    this._ticket
      .find({ where: { status: "activo" } })
      .subscribe((tickets: any) => {
        if (tickets) {
          this.activeTickets = Object.keys(tickets).length;
        }
      });
  }

  getGifts() {
    this._gifts
      .getGifts({
        where: {
          or: [
            { status: "available" },
            { status: "changed" },
            { status: "expired" }
          ]
        }
      })
      .subscribe((resp: any) => {
        this.gifts = resp;
      });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.change();
    }
    else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      this.fromDate == date
    ) {
      this.toDate = date;
      this.change();
    }

    else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  ngOnInit() {
    this.change();
    this.getCompanies2();
  }

  commerces(FechaA, FechaB) {
    this._commerce
      .find({
        where: {
          isDeleted: false
        },
        include: {
          relation: 'gifts',
          scope: {
            where: {
              and: [
                { createdAt: { between: [FechaA, FechaB] } },
                {
                  or: [
                    { status: "available" },
                    { status: "changed" },
                    { status: "expired" }
                  ]
                }
              ]
            }
          }
        }
      })
      .subscribe((commerces: any) => {
        if (commerces) {
          let Coms = [];
          commerces.forEach((com: any, index) => {
            if (com.gifts && com.gifts.length > 0) {
              Coms.push({ name: com.name, gift: com.gifts.length })
            }
            if (index == commerces.length - 1) {
              this.grafGifts(Coms);
            }
          });
        }
      });
  }

  grafGifts(data) {
    this.giftGraf = data;
    this.giftGraf.sort((a, b) => b.gift - a.gift);
    let labelS = [];
    this.data2 = [];

    this.giftGraf.forEach(element => {
      labelS.push(`${element.gift} ${element.name}`);
      this.data2.push(element.gift);
    });

    var ctx2 = document.getElementById("RegaEntr");

    if (this.RegaEntr) {
      this.RegaEntr.clear();
      this.RegaEntr.destroy();
    }

    this.RegaEntr = new Chart(ctx2, {
      type: "doughnut",
      data: {
        labels: labelS,
        datasets: [
          {
            //label: '# of Votes',
            data: this.data2,
            backgroundColor: [
              "rgba(189, 189, 189, 1)",
              "rgba(158, 158, 158, 1)",
              "rgba(117, 117, 117, 1)",
              "rgba(97, 97, 97, 1)",
              "rgba(66, 66, 66, 1)"
            ],
            borderColor: [
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)"
            ],
            borderWidth: 2.5
          }
        ]
      },
      options: {
        legend: {
          position: "left"
        }
      }
    });
  }

  countCerts(FechaAnterior, FechaActual) {

    let data = [0, 0, 0, 0];
    let Fecha1 = new Date();
    let Fecha2 = moment(Fecha1).subtract(3, "days");

    this._certificado.find({ where: { and: [{ companyId: this.companyId }, { createdAt: { between: [FechaAnterior, FechaActual] } }] } }).subscribe((resp: any) => {
      if (resp) {
        resp.forEach((cert: any, index) => {
          if (cert.status == "available") {
            data[0] += 1;
          }
          if (cert.status == "inBalance") {
            data[1] += 1;
          }
          if (cert.status == "changed") {
            data[2] += 1;
          }
          if (cert.status == "available" && (cert.expirationDate < Fecha1 && cert.expirationDate > Fecha2)) {
            data[3] += 1;
          }
          if (index == resp.length - 1) {
            this.grafCerts(data);
          }
        });
      }

      if (resp.length == 0) {
        this.grafCerts(data);
      }
    })
  }

  grafCerts(data) {
    if (data) {
      this.data4 = [];
      this.data4 = data;
      var ctx4 = document.getElementById("EstaCert");
      if (this.EstaCert) {
        this.EstaCert.clear();
        this.EstaCert.destroy();
      }

      this.EstaCert = new Chart(ctx4, {
        type: "bar",
        data: {
          labels: ["Programados", "Enviados", "Canjeados", "Por expirar"],
          datasets: [
            {
              label: "Estado",
              data: this.data4,
              backgroundColor: [
                "rgba(189, 189, 189, 1)",
                "rgba(158, 158, 158, 1)",
                "rgba(117, 117, 117, 1)",
                "rgba(97, 97, 97, 1)"
              ],
              borderColor: [
                "rgba(255, 255, 255)",
                "rgba(255, 255, 255)",
                "rgba(255, 255, 255)",
                "rgba(255, 255, 255)"
              ],
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  precision: 0
                }
              }
            ]
          }
        }
      });
    }
  }

  obtenerGifts(FechaAnterior, FechaActual) {
    this._commerce
      .find({
        where: {
          isDeleted: false
        },
        include: {
          relation: 'gifts',
          scope: {
            where: {
              and: [
                { companyId: this.companyId },
                { createdAt: { between: [FechaAnterior, FechaActual] } },
                {
                  or: [
                    { status: "available" },
                    { status: "changed" },
                    { status: "expired" }
                  ]
                }
              ]
            }
          }
        }
      })
      .subscribe((commerces: any) => {
        if (commerces) {
          let Coms = [];
          let Total = 0;
          commerces.forEach((com: any, index) => {
            if (com.gifts && com.gifts.length > 0) {
              Coms.push({ name: com.name, gift: com.gifts.length })
              Total += com.gifts.length;
            }
            if (index == commerces.length - 1) {
              this.giftsPercent(Coms, Total);
            }
          });
        }
      });
  }

  giftsPercent(data, Total) {
    this.giftGraf = data;
    this.giftGraf.sort((a, b) => b.gift - a.gift);
    let labelS = [];
    this.data5 = [];

    this.giftGraf.forEach(element => {
      let percent = Math.round((element.gift * 100) / Total);
      labelS.push(`${percent}% ${element.name}`);
      this.data5.push(element.gift);
    });
    console.log(this.data5)

    var ctx5 = document.getElementById("ComePref");

    if (this.ComePref) {
      this.ComePref.clear();
      this.ComePref.destroy();
    }

    this.ComePref = new Chart(ctx5, {
      type: "doughnut",
      data: {
        labels: labelS,
        datasets: [
          {
            data: this.data5,
            backgroundColor: [
              "rgba(189, 189, 189, 1)",
              "rgba(158, 158, 158, 1)",
              "rgba(117, 117, 117, 1)",
              "rgba(97, 97, 97, 1)",
              "rgba(66, 66, 66, 1)"
            ],
            borderColor: [
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)",
              "rgba(255, 255, 255)"
            ],
            borderWidth: 2.5
          }
        ]
      },
      options: {
        legend: {
          position: "left"
        }
      }
    });
  }

  obtenerSaldos(FechaAnterior, FechaActual) {
    let DatesArray = [];
    var Acumulador = 0;
    let Dif = moment(FechaActual).diff(FechaAnterior, 'day') + 1;

    for (let index = 1; index < Dif; index++) {
      let Fecha = moment(FechaAnterior).add(index, 'day');
      DatesArray.push({ date: moment(Fecha).format('DD MMM'), saldo: 0 })
    }

    this._company.findById(this.companyId, { include: { relation: 'balanceHistories' } }).subscribe((resp: any) => {
      if (resp.balanceHistories) {
        console.log(resp);

        DatesArray.forEach((da: any, index) => {
          resp.balanceHistories.forEach(element => {
            if (element.balanceUsed > 0 && element.updatedAt) {
              if (moment(element.updatedAt).format('DD MMM') === da.date) {
                da.saldo = Acumulador + element.balanceUsed;
                Acumulador = Acumulador + element.balanceUsed;
              }
              if (da.saldo == 0 && Acumulador > 0) {
                da.saldo = Acumulador;
              }
            }
          });
          if (index == DatesArray.length - 1) {
            let labels3 = [];
            this.data3 = [];
            DatesArray.forEach((element: any, index) => {
              labels3.push(element.date);
              this.data3.push(element.saldo);
              if (index == DatesArray.length - 1) {
                var ctx3 = document.getElementById("TimeLine");
                if (this.TimeLine) {
                  this.TimeLine.clear();
                  this.TimeLine.destroy();
                }
                this.TimeLine = new Chart(ctx3, {
                  type: "line",
                  data: {
                    labels: labels3,
                    datasets: [
                      {
                        label: "Saldo utilizado",
                        data: this.data3,
                        lineTension: 0
                      }
                    ]
                  },
                  options: {
                    legend: {
                      display: true,
                      position: "top",
                      labels: {
                        boxWidth: 80,
                        fontColor: "black"
                      }
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            precision: 0
                          },
                        },
                      ]
                    }

                  }
                });
              }
            });
          }
        });
      }
    })
  }

  createCSV() {
    let data = [{
      empresas: 'Empresas Activas',
      colab_actives: 'Colaboradores Registrados',
      coms_availables: 'Comercios Disponibles',
      gifts: 'Regalos Entregados',
      tickets: 'Reportes por Entregar'
    },
    {
      empresas: this.activeCompanies,
      colab_actives: this.usersRegistered,
      coms_availables: this.availableCommerces,
      gifts: this.gifts ? this.gifts.length : 0,
      tickets: this.activeTickets
    }];
    const archivo = new Angular5Csv(data, 'EstadísticasPlataforma-' + moment(new Date()).format('DD/MM/YYYY,h:mm a'));
  }
}