import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UserApi, GroupApi, Group_employeeApi, User } from '../../../services/shared/sdk';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-registrar-grupo',
  templateUrl: './registrar-grupo.component.html',
  styleUrls: ['./registrar-grupo.component.scss']
})
export class RegistrarGrupoComponent implements OnInit {

  form: FormGroup;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  colabCtrl = new FormControl();
  filteredColaboradores: Observable<User[]>;
  colaboradores: User[] = [];
  allUsers: User[] = [];
  created = new Date();

  @ViewChild('colaboradorInput') colaboradorInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public dialogRef: MatDialogRef<RegistrarGrupoComponent>,
    private formBuilder: FormBuilder,
    private _user: UserApi,
    private _group: GroupApi,
    private _groupUser: Group_employeeApi,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    console.log(this.data);
    this._user.find<User>({ where: { and: [{ isDeleted: false }, { companyId: this.data }] } }).subscribe(users => {
      if (users && users.length < 1000) this.colaboradores = users;
      this.allUsers = users;
    })
    this.filteredColaboradores = this.colabCtrl.valueChanges.pipe(
      startWith(null),
      map((colab: string | null) => colab ? this._filter(colab) : this.allUsers.slice())
    );
  }

  add(event: MatChipInputEvent): void {
    const exists = this.colaboradores.find(colab => colab.email == event.value);
    if (exists) return;
    const input = event.input;
    const value = event.value;

    const newColab = this.allUsers.find(user => user.email == value.trim());
    if (newColab) {
      this.colaboradores.push(newColab);
    }

    if (input) {
      input.value = '';
    }

    this.colabCtrl.setValue(null);
  }

  remove(colab: User): void {
    const index = this.colaboradores.findIndex(user => user.email == colab.email);
    console.log(colab);
    console.log(index);
    console.log(this.colaboradores);

    if (index == -1) {
      return;
    }
    this.colaboradores.splice(index, 1);
    console.log(this.colaboradores);

    //this.allUsers.unshift(colab);
    //this.colaboradorInput.nativeElement.value = '';
    //this.colabCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const exists = this.colaboradores.find(colab => colab.email == event.option.value);
    if (exists) return;
    this.colaboradores.push(event.option.value);
    let indexColab = this.allUsers.findIndex(user => user.email == event.option.value);
    this.allUsers.splice(indexColab, 1);
    this.colaboradorInput.nativeElement.value = '';
    this.colabCtrl.setValue(null);
  }

  private _filter(value: string | User): User[] {
    const filterValue = typeof value == 'string' ? value.toLowerCase() : value.email.toLowerCase();
    return this.allUsers.filter(colab => colab.email.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  createGroup() {
    this._group.create({ name: this.form.value.name, companyId: this.data, createdAt: this.created }).subscribe((resp: any) => {
      console.log(resp);
      let users = [];
      this.colaboradores.forEach((User: User) => {
        users.push({ groupId: resp.id, userId: User.id, companyId: this.data });
      });
      this._groupUser.createMany(users).subscribe(resp => {
        console.log(`Relaciones con el grupo creadas: ${resp}.`);
        this.dialogRef.close(resp);
      });
      this.snackBar.open('Grupo creado', 'Listo!', {
        duration: 2000
      });
    });
  }

}
