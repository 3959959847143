/* tslint:disable */
import {
  Company_store,
  Gifts
} from '../index';

declare var Object: any;
export interface StoreInterface {
  "name"?: string;
  "description"?: string;
  "values"?: Array<any>;
  "currentStates"?: Array<any>;
  "terms"?: string;
  "termsExcerpt"?: string;
  "logo"?: string;
  "giftDesign"?: string;
  "isDeleted"?: boolean;
  "createdAt"?: Date;
  "productType"?: number;
  "showMilenio"?: boolean;
  "showTec"?: boolean;
  "delivery"?: number;
  "isTicket"?: boolean;
  "isGenerated"?: boolean;
  "isFile"?: boolean;
  "isLink"?: boolean;
  "info"?: Array<any>;
  "rest"?: Array<any>;
  "color"?: string;
  "id"?: number;
  "categoryId"?: number;
  companyStores?: Company_store[];
  gifts?: Gifts[];
}

export class Store implements StoreInterface {
  "name": string;
  "description": string;
  "values": Array<any>;
  "currentStates": Array<any>;
  "terms": string;
  "termsExcerpt": string;
  "logo": string;
  "giftDesign": string;
  "isDeleted": boolean;
  "createdAt": Date;
  "productType": number;
  "showMilenio": boolean;
  "showTec": boolean;
  "delivery": number;
  "isTicket": boolean;
  "isGenerated": boolean;
  "isFile": boolean;
  "isLink": boolean;
  "info": Array<any>;
  "rest": Array<any>;
  "color": string;
  "id": number;
  "categoryId": number;
  companyStores: Company_store[];
  gifts: Gifts[];
  constructor(data?: StoreInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Store`.
   */
  public static getModelName() {
    return "Store";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Store for dynamic purposes.
  **/
  public static factory(data: StoreInterface): Store{
    return new Store(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Store',
      plural: 'Stores',
      path: 'Stores',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "values": {
          name: 'values',
          type: 'Array&lt;any&gt;'
        },
        "currentStates": {
          name: 'currentStates',
          type: 'Array&lt;any&gt;'
        },
        "terms": {
          name: 'terms',
          type: 'string'
        },
        "termsExcerpt": {
          name: 'termsExcerpt',
          type: 'string'
        },
        "logo": {
          name: 'logo',
          type: 'string'
        },
        "giftDesign": {
          name: 'giftDesign',
          type: 'string'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "productType": {
          name: 'productType',
          type: 'number'
        },
        "showMilenio": {
          name: 'showMilenio',
          type: 'boolean',
          default: true
        },
        "showTec": {
          name: 'showTec',
          type: 'boolean',
          default: true
        },
        "delivery": {
          name: 'delivery',
          type: 'number'
        },
        "isTicket": {
          name: 'isTicket',
          type: 'boolean',
          default: false
        },
        "isGenerated": {
          name: 'isGenerated',
          type: 'boolean',
          default: false
        },
        "isFile": {
          name: 'isFile',
          type: 'boolean',
          default: false
        },
        "isLink": {
          name: 'isLink',
          type: 'boolean',
          default: false
        },
        "info": {
          name: 'info',
          type: 'Array&lt;any&gt;'
        },
        "rest": {
          name: 'rest',
          type: 'Array&lt;any&gt;'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'number'
        },
      },
      relations: {
        companyStores: {
          name: 'companyStores',
          type: 'Company_store[]',
          model: 'Company_store',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'storeId'
        },
        gifts: {
          name: 'gifts',
          type: 'Gifts[]',
          model: 'Gifts',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'storeId'
        },
      }
    }
  }
}
