import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-eliminar-colaborador',
  templateUrl: './eliminar-colaborador.component.html',
  styleUrls: ['./eliminar-colaborador.component.scss']
})
export class EliminarColaboradorComponent implements OnInit {

  accepted = false;

  constructor(
    public dialogRef : MatDialogRef<EliminarColaboradorComponent>,
    private _user: UserApi,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  delete(){
    this._user.patchAttributes(this.data.user.id, {isDeleted: true}).subscribe((user) => {
      this.snackBar.open('Colaborador eliminado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(user);
    })
  }

}
