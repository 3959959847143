import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, MatPaginator} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TicketApi, CompanyApi, GroupApi, UserApi} from '../../../services/shared/sdk';
import { ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { PresetItem, Range } from 'ngx-mat-daterange-picker';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from "moment";


@Component({
  selector: 'app-lista-soporte',
  templateUrl: './lista-soporte.component.html',
  styleUrls: ['./lista-soporte.component.scss']
})
export class ListaSoporteComponent implements OnInit {

  displayedColumns: string[] = ['id','createdAt','companyName','type','userName', 'email','descriptionShort','actions'];
  displayedColumns2: string[] = ['id','createdAt','companyName2','type','userName', 'email','solvedAt','resolvedFor','actions'];
  
  @ViewChild('MatSort1') sort: MatSort;
  @ViewChild('MatSort2') sort2: MatSort;

  @ViewChild('singlePaginator') paginator: MatPaginator;
  @ViewChild('singlePaginator2') paginator2: MatPaginator;

  dataTickets;
  dataTicketsResolved;
  Ahora = Date.now();

  dataCreate = {companyId: 1,category:'gift', status:'activo', createdAt: this.Ahora, senderId:12, description: 'Absb asjakjs jkalksklaskl klalkkslaklaskl nnnoooouyshshsh.'};

  tickets: any = [];
  ticketsFiltered: any = [];
  ticketsFiltered2: any = [];

  ticketsResolved: any = [];

  companies;
  idCompany;

  dropdownSettings = {};
  range:Range = {fromDate:new Date(), toDate: new Date()};
  options:any;
  presets:Array<PresetItem> = [];

  constructor(
    public dialog: MatDialog,
    private _ticket: TicketApi,
    private _group: GroupApi,
    private _company: CompanyApi,
    private _user: UserApi,
    private route : ActivatedRoute
    ) { 
      this.route.params.subscribe(resp => {
        if(resp && resp.id){
          this.showCompany2(resp.id);
        }
        else {
          this.getTicketsOninit();
        }
      })
    }

  ngOnInit() {
    this.getCompanies();

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 25,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar empresa',
      closeDropDownOnSelection: true
    };
    const today = moment().startOf("day");
    const todyend = moment().endOf("day")

    this.options = {
      presets: [],
      format: 'mediumDate',
      range: {fromDate:today, toDate: todyend},
      applyLabel: "Seleccionar",
      cancelLabel: "Cancelar",
      locale: 'es'
    };
  }

  getCompanies(){
    
    this._company.find({where: {
      isDeleted: false      
    },include: 'adminCompanies'}).subscribe((companies) => {      
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.companies = companies;
        } else {
          this.companies = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id);
          });
        }
        
      });
      
    })
  }

  showCompany(event){
    this.idCompany = event.id;
    let name = event.name;
    document.getElementById('nameEmpresa').innerHTML = 'Tickets activos: ' + name;
    document.getElementById('nameEmpresa2').innerHTML = 'Tickets resueltos: ' + name; 
    this.getTickets(this.idCompany);
  }

  showCompany2(id){
    this.idCompany = id;
    this._company.findById(id).subscribe((resp:any) => {
      let name = resp.name;
      document.getElementById('nameEmpresa').innerHTML = 'Tickets activos: ' + name;
      document.getElementById('nameEmpresa2').innerHTML = 'Tickets resueltos: ' + name;
    })
    this.getTickets(this.idCompany);
  }

  filterType(event){
    let type = event.target.value;
    if(type == 'all'){
      this.dataTickets = new MatTableDataSource(this.tickets);
      setTimeout(() => this.dataTickets.paginator = this.paginator);
      return;
    }
    this.ticketsFiltered = [];
    this.tickets.forEach((ticket:any) => {
      if(ticket.type == type){  //Los tickets existentes tenian asignado el tipo en categoría
        this.ticketsFiltered.push(ticket);
      }
    });
    this.dataTickets = new MatTableDataSource(this.ticketsFiltered);
    setTimeout(() => this.dataTickets.paginator = this.paginator);
  }

  filterType2(event){
    let type = event.target.value;
    if(type == 'all'){
      this.dataTicketsResolved = new MatTableDataSource(this.ticketsResolved);
      setTimeout(() => this.dataTicketsResolved.paginator = this.paginator2);
      return;
    }
    this.ticketsFiltered2 = [];
    this.ticketsResolved.forEach((ticket:any) => {
      if(ticket.type == type){
        this.ticketsFiltered2.push(ticket);
      }
    });
    this.dataTicketsResolved = new MatTableDataSource(this.ticketsFiltered2);
    setTimeout(() => this.dataTicketsResolved.paginator = this.paginator2);
  }

  createTicket(){
    this._ticket.create(this.dataCreate).subscribe(ticket => {
      console.log('El ticket se creó correctamente');
      console.log(ticket);
    })
  }

  // getTicketsActives(id){
  //   this._ticket.find({where: {and: [{status:'activo'},{companyId: id}]}}).subscribe((tickets: any)=>{
  //     tickets.forEach(tckt => {
  //       tckt.descriptionShort = tckt.description.substring(0,30) + '...';
  //       this._user.findById(tckt.senderId).subscribe((user : any) => {
  //         tckt.userName = user.name;
  //       });
  //     });
  //     this.tickets = tickets;
  //     this.dataTickets = new MatTableDataSource(this.tickets);
  //   });
  // }

  // getTicketsResolved(id){
  //   this._ticket.find({where: {and: [{status:'resuelto'},{companyId: id}]}}).subscribe((tickets: any) => {
  //     tickets.forEach(tckt => {
  //       tckt.descriptionShort = tckt.description.substring(0,30) + '...';
  //       tckt.resolvedFor = 'Smart Developer 1';
  //       tckt.resolvedAt = tckt.createdAt;
  //       this._user.findById(tckt.senderId).subscribe((user : any) => {
  //         tckt.userName = user.name;
  //       });
  //     })
  //     this.ticketsResolved = tickets;
  //     this.dataTicketsResolved = new MatTableDataSource(this.ticketsResolved);
  //   });
  // }

  getTicketsOninit(){
    this.tickets = [];
    this.ticketsResolved = [];
    this._ticket.find({include : ['employee','solved']}).subscribe((tickets: any) => {
        tickets.forEach(tckt => {
          const compName = this.companies.find((company: any) => company.id == tckt.companyId)
        tckt.companyName = compName ? compName.name : ''
        tckt.companyName2 = compName ? compName.name : ''
        tckt.descriptionShort = tckt.description.substring(0,30) + '...';
        if(tckt.solved){
          tckt.resolvedFor = tckt.solved.name;
        }
        if(tckt.employee){
          tckt.userName = tckt.employee.name;
          tckt.email = tckt.employee.email;
        }
        if(tckt.groupId){
          this._group.findById(tckt.groupId).subscribe((resp:any) => {
            tckt.groupName = resp.name;
          });
        }
        if(tckt.status == 'activo'){
          this.tickets.push(tckt);
        }
        else {
          this.ticketsResolved.push(tckt);
        }
      })

      this.tickets.reverse();
      this.dataTickets = new MatTableDataSource(this.tickets);
      this.dataTickets.sort = this.sort;
      setTimeout(() => this.dataTickets.paginator = this.paginator);
      this.ticketsResolved.reverse();
      this.dataTicketsResolved = new MatTableDataSource(this.ticketsResolved);
      this.dataTicketsResolved.sort = this.sort2;
      setTimeout(() => this.dataTicketsResolved.paginator = this.paginator2);
    });
  }

  getTickets(id){
    this.tickets = [];
    this.ticketsResolved = [];
    this._ticket.find({where: {companyId:id},include: ['employee','solved']}).subscribe((tickets: any) => {
        tickets.forEach(tckt => {
        const compName = this.companies.find((company: any) => company.id == tckt.companyId)
        tckt.companyName = compName ? compName.name : '';
        tckt.descriptionShort = tckt.description.substring(0,30) + '...';
        if(tckt.solved){
          tckt.resolvedFor = tckt.solved.name;
        }
        if(tckt.employee){
          tckt.userName = tckt.employee.name;
          tckt.email = tckt.employee.email;
        }
        if(tckt.groupId){
          this._group.findById(tckt.groupId).subscribe((resp:any) => {
            tckt.groupName = resp.name;
          });
        }
        if(tckt.status == 'activo'){
          this.tickets.push(tckt);
        }
        else {
          this.ticketsResolved.push(tckt);
        }
      })
      this.dataTickets = new MatTableDataSource(this.tickets);
      this.dataTickets.sort = this.sort;
      setTimeout(() => this.dataTickets.paginator = this.paginator);
      this.dataTicketsResolved = new MatTableDataSource(this.ticketsResolved);
      this.dataTicketsResolved.sort = this.sort2;
      setTimeout(() => this.dataTicketsResolved.paginator = this.paginator2);
    });
  }

  openModalInfoTicket(row,index){
    const modalEditAdmin = this.dialog.open(InfoTickets, {
      width: '35%',
      data: {
        Ticket: row, index
      }
    })
  }

  openResolve(row,index){
    const modalResolve = this.dialog.open(ResolveTickets, {
      width: '35%',
      data: {
        Ticket: row, index
      }
    });
    modalResolve.afterClosed().subscribe(res => {
      if(res){
        console.log('The dialog was closed');
        this.getTickets(this.idCompany);
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataTickets.filter = filterValue.trim().toLowerCase();
    if (this.dataTickets.paginator) {
      this.dataTickets.paginator.firstPage();
    }
  }

  applyFilter2(filterValue: string) {
    this.dataTicketsResolved.filter = filterValue.trim().toLowerCase();
    if (this.dataTicketsResolved.paginator) {
      this.dataTicketsResolved.paginator.firstPage();
    }
  }

  updateRange(range: Range){
    this.range = range;
  } 

  downloadReport(){
    const start = this.range.fromDate
    const end = this.range.toDate
    let data:any = [
      {
        title: 'Reporte de soporte',
        fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY')
      },
      {
        status: "Status",
        id: "ID",
        sendDate: "Enviado el",
        company: "Empresa",
        type: "Tipo de ticket",
        sender: "Remitente",
        email: "Correo",
        description: "Descripción",
        resolvedAt: "Resuelto el",
        resolvedBy: "Resuelto por"
      }
    ];

    this.tickets.forEach(ticket => {
      const ticketData = {
        status: 'Activo',
        id: ticket.id,
        sendDate: moment(ticket.createdAt).format('DD/MM/YYYY,h:mm a'),
        company: ticket.companyName,
        type: (ticket.type && ticket.type == 'gift') ? 'Regalo' : (ticket.type
          && ticket.type == 'deprecate') ? 'Dato obsoleto' : (ticket.type && ticket.type == 'wrong') ? 'Dato incorrecto' : (ticket.type && ticket.type == 'sendCertificate') ? 'Envío de certificado' : (ticket.type && ticket.type == 'unsubscribeUser')
          ? 'Baja de usuario' : (ticket.type && ticket.type == 'unsubscribeGroup') ? 'Baja de grupo' : (ticket.type && ticket.type == 'other') ? 'Otro' : 'No asignado',
        sender: ticket.userName,
        email: ticket.email,
        description: ticket.description,
        resolvedAt: '-',
        resolvedBy: '-'
      }
      
      if (start <= new Date(ticket.createdAt) && new Date(ticket.createdAt) <= end){
        data.push(ticketData)
      }
    });

    this.ticketsResolved.forEach(ticket => {
      const ticketData = {
        status: 'Resuelto',
        id: ticket.id,
        sendDate: moment(ticket.createdAt).format('DD/MM/YYYY,h:mm a'),
        company: ticket.companyName,
        type: (ticket.type && ticket.type == 'gift') ? 'Regalo' : (ticket.type
          && ticket.type == 'deprecate') ? 'Dato obsoleto' : (ticket.type && ticket.type == 'wrong') ? 'Dato incorrecto' : (ticket.type && ticket.type == 'sendCertificate') ? 'Envío de certificado' : (ticket.type && ticket.type == 'unsubscribeUser')
          ? 'Baja de usuario' : (ticket.type && ticket.type == 'unsubscribeGroup') ? 'Baja de grupo' : (ticket.type && ticket.type == 'other') ? 'Otro' : 'No asignado',
        sender: ticket.userName,
        email: ticket.email,
        description: ticket.description,
        resolvedAt: moment(ticket.solvedAt).format('DD/MM/YYYY,h:mm a'),
        resolvedBy: ticket.resolvedFor
      }
      
      if (start <= new Date(ticket.createdAt) && new Date(ticket.createdAt) <= end){
        data.push(ticketData)
      }
    });

    const archivo = new Angular5Csv(data, 'ReporteSoporte-'+moment(new Date()).format('DD/MM/YYYY,h:mm a'));
  }
  

}


@Component({
  selector: 'mod-info-tickets',
  template:`
  <div class="container-fluid">
    <div id="_header-container" class="row" style="align-content:space-between">
      <div id="title" class="col-lg-9">
        <div style="font-size: 22px">Información del Ticket</div>
      </div>
      <div id="button-close" class="col-lg-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
    <br>
    <div id="_body-container">
      <div>Tipo: 
      <img style="padding-bottom: 4px" *ngIf="ticket.type && ticket.type == 'gift'" src="../../../../assets/svg/soporte/regalo.svg" alt="regalo">
      <img style="padding-bottom: 4px" *ngIf="ticket.type && ticket.type == 'deprecate'" src="../../../../assets/svg/soporte/dato_obsoleto.svg" alt="dato_obsoleto">
      <img style="padding-bottom: 4px" *ngIf="ticket.type && ticket.type == 'wrong'" src="../../../../assets/svg/soporte/dato_incorrecto.svg" alt="dato_incorrecto">
      <img style="padding-bottom: 4px" *ngIf="ticket.type && ticket.type == 'sendCertificate'" src="../../../../assets/svg/soporte/envio.svg" alt="envío">
      <img style="padding-bottom: 4px" *ngIf="ticket.type && ticket.type == 'unsubscribeUser'" src="../../../../assets/svg/soporte/usuario.svg" alt="baja_usuario">
      <img style="padding-bottom: 4px" *ngIf="ticket.type && ticket.type == 'unsubscribeGroup'" src="../../../../assets/svg/soporte/grupo.svg" alt="baja_grupo">
      <span style="color: darkgrey">{{(ticket.type && ticket.type == 'gift') ? 'Regalo' : (ticket.type && ticket.type == 'deprecate') ? 'Dato obsoleto' : (ticket.type && ticket.type == 'wrong') ? 'Dato incorrecto' : (ticket.type && ticket.type == 'sendCertificate') ? 'Envío de certificado' : (ticket.type && ticket.type == 'unsubscribeUser') ? 'Baja de usuario' : (ticket.type && ticket.type == 'unsubscribeGroup') ? 'Baja de grupo' : (ticket.type && ticket.type == 'other') ? 'Otro' : 'No asignado'}}</span></div>
      <div>ID: <span style="color: darkgrey">{{ticket.id}}</span> </div>
      <div>Enviado el: <span style="color: darkgrey">{{ticket.createdAt | date:'dd/MM/yyyy'}}</span></div>
      <br>
      <div>Colaborador o grupo:</div>
      <div style="color: darkgrey">{{ticket.userName ? ticket.userName : ticket.groupName}}</div>
      <br>
      <div>Descripción:</div>
      <div style="color: darkgrey">{{ticket.description}}</div>
    </div>
  </div> `
})

export class InfoTickets {

  constructor(public dialogRef : MatDialogRef<InfoTickets>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    ticket: any = this.data.Ticket;

  closeModal(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'mod-resolve-tickets',
  template:`
  <div class="container-fluid">
  <div id="_header-container" class="row">
    <div id="button-close" class="col-lg-12 col-md-12">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
    <div id="title" class="col-lg-12 col-md-12 text-center">
      <h5>Ticket de Soporte</h5>
    </div>
    <div class="col-lg-12 text-center">Enviado por:<div>
    <div id="adminName" class="col-lg-12">
      <div style="color: blue">{{ticket.userName}}</div>
    </div>
  </div>
  <br>
  <div id="_body-container" class="row text-center">
    <div class="col-lg-3"></div>
    <div id="_question" class="sure col-lg-6">
      <div>¿Desea marcar este ticket como resuelto?</div>
      <div id="check-container">
          <mat-checkbox [(ngModel)]="solved">De acuerdo</mat-checkbox>
        </div>
    </div>
    <div class="col-lg-3"></div>
  </div>
  <div id="alert-container" class="col-lg-12">
    <div style="font-size: 12px; color: red">No podrás deshacer esta acción</div>
  </div>
  <br>
  <div [formGroup]="form">
    <div class="col-lg-12 col-md-6">
        <label>Respuesta para el colaborador</label>
        <input type="text" class="form-control" id="response" required formControlName="response">
      </div>
  </div>
  <br>
  <div class="col-lg-12 col-md-3 col-xs-3 row" style="margin: 0; padding-right: 0">
    <div class="w-100 text-center">
      <button class="btn-gift btn fw-70" [disabled]="!solved || !form.valid" (click)="resolve()">
        Resolver ticket
      </button>
    </div>
  </div> `,
  styleUrls: ['../../administradores/borrar-admin/borrar-admin.component.scss']
})

export class ResolveTickets {

  solved = false;
  currentId;
  form: FormGroup;

  constructor(
    public dialogRef : MatDialogRef<ResolveTickets>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _ticket: TicketApi,
    private _user: UserApi,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    ) {
      this.currentId = this._user.getCurrentId();
      this.form = this.formBuilder.group({
        response: ['', Validators.required]
      });
    }

    ticket: any = this.data.Ticket;

  closeModal(): void {
    this.dialogRef.close();
  }

  resolve(){
    this._ticket.patchAttributes(this.data.Ticket.id, {status:'resuelto', solvedAt: new Date(),solvedBy:this.currentId, response: this.form.value.response}).subscribe(resp => {
      this.snackBar.open('Ticket resuelto', 'Listo!', {
        duration: 4000
      });
      this.dialogRef.close(resp);
    },error => {
      this.snackBar.open('OCURRIO UN ERROR', 'ERROR', {
        duration: 4000
      });
      console.error(error);
    });

    this._ticket.resolveTicket({userId:this.data.Ticket.senderId, description:this.data.Ticket.description, message:this.form.value.response}).subscribe(resp => {
      console.log('Email enviado!', resp);
    }, error => {
      console.error("Fallo al enviar el email", error);
    })
  }
}