/* tslint:disable */
import {
  Company,
  Group_employee,
  OrderEmployee,
  Gifts,
  Certificate,
  Employee_event,
  Event,
  Response,
  CertificateCategory
} from '../index';

declare var Object: any;
export interface UserInterface {
  "name"?: string;
  "birthdate"?: Date;
  "maritalStatus"?: string;
  "anniversaryDate"?: any;
  "birthdateCouple"?: any;
  "parentType"?: string;
  "childrenBirthdates"?: Array<any>;
  "dateAdmission"?: Date;
  "payrollNumber"?: string;
  "image"?: string;
  "typeLogin"?: string;
  "balance"?: number;
  "companyId"?: number;
  "isAdmin"?: boolean;
  "adminId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "isDeleted"?: boolean;
  "registered"?: boolean;
  "country"?: string;
  "isSuperAdmin"?: boolean;
  "pass"?: string;
  "deletionRequest"?: boolean;
  "type"?: string;
  "system"?: boolean;
  "years"?: string;
  "video"?: boolean;
  "gender"?: boolean;
  "city"?: string;
  "isTec"?: boolean;
  "isTecMilenio"?: boolean;
  "isSorteosTec"?: boolean;
  "isTecSalud"?: boolean;
  "isTecSec"?: boolean;
  "downloadedDiplomaDate"?: Date;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "campusId"?: number;
  "password"?: string;
  accessTokens?: any[];
  company?: Company;
  company2?: Company[];
  groupEmployees?: Group_employee[];
  orderEmployees?: OrderEmployee[];
  gifts?: Gifts[];
  certs?: Certificate[];
  assistances?: Employee_event[];
  event?: Event[];
  responses?: Response[];
  categories?: CertificateCategory[];
}

export class User implements UserInterface {
  "name": string;
  "birthdate": Date;
  "maritalStatus": string;
  "anniversaryDate": any;
  "birthdateCouple": any;
  "parentType": string;
  "childrenBirthdates": Array<any>;
  "dateAdmission": Date;
  "payrollNumber": string;
  "image": string;
  "typeLogin": string;
  "balance": number;
  "companyId": number;
  "isAdmin": boolean;
  "adminId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "isDeleted": boolean;
  "registered": boolean;
  "country": string;
  "isSuperAdmin": boolean;
  "pass": string;
  "deletionRequest": boolean;
  "type": string;
  "system": boolean;
  "years": string;
  "video": boolean;
  "gender": boolean;
  "city": string;
  "isTec": boolean;
  "isTecMilenio": boolean;
  "isSorteosTec": boolean;
  "isTecSalud": boolean;
  "isTecSec": boolean;
  "downloadedDiplomaDate": Date;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "campusId": number;
  "password": string;
  accessTokens: any[];
  company: Company;
  company2: Company[];
  groupEmployees: Group_employee[];
  orderEmployees: OrderEmployee[];
  gifts: Gifts[];
  certs: Certificate[];
  assistances: Employee_event[];
  event: Event[];
  responses: Response[];
  categories: CertificateCategory[];
  constructor(data?: UserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `User`.
   */
  public static getModelName() {
    return "User";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of User for dynamic purposes.
  **/
  public static factory(data: UserInterface): User{
    return new User(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'User',
      plural: 'Users',
      path: 'Users',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "birthdate": {
          name: 'birthdate',
          type: 'Date',
          default: new Date(0)
        },
        "maritalStatus": {
          name: 'maritalStatus',
          type: 'string',
          default: 'null'
        },
        "anniversaryDate": {
          name: 'anniversaryDate',
          type: 'any',
          default: <any>null
        },
        "birthdateCouple": {
          name: 'birthdateCouple',
          type: 'any',
          default: <any>null
        },
        "parentType": {
          name: 'parentType',
          type: 'string',
          default: 'null'
        },
        "childrenBirthdates": {
          name: 'childrenBirthdates',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "dateAdmission": {
          name: 'dateAdmission',
          type: 'Date',
          default: new Date(0)
        },
        "payrollNumber": {
          name: 'payrollNumber',
          type: 'string'
        },
        "image": {
          name: 'image',
          type: 'string'
        },
        "typeLogin": {
          name: 'typeLogin',
          type: 'string'
        },
        "balance": {
          name: 'balance',
          type: 'number',
          default: 0
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "isAdmin": {
          name: 'isAdmin',
          type: 'boolean'
        },
        "adminId": {
          name: 'adminId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "registered": {
          name: 'registered',
          type: 'boolean',
          default: false
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "isSuperAdmin": {
          name: 'isSuperAdmin',
          type: 'boolean',
          default: false
        },
        "pass": {
          name: 'pass',
          type: 'string'
        },
        "deletionRequest": {
          name: 'deletionRequest',
          type: 'boolean',
          default: false
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "system": {
          name: 'system',
          type: 'boolean'
        },
        "years": {
          name: 'years',
          type: 'string'
        },
        "video": {
          name: 'video',
          type: 'boolean',
          default: false
        },
        "gender": {
          name: 'gender',
          type: 'boolean'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "isTec": {
          name: 'isTec',
          type: 'boolean',
          default: false
        },
        "isTecMilenio": {
          name: 'isTecMilenio',
          type: 'boolean',
          default: false
        },
        "isSorteosTec": {
          name: 'isSorteosTec',
          type: 'boolean',
          default: false
        },
        "isTecSalud": {
          name: 'isTecSalud',
          type: 'boolean',
          default: false
        },
        "isTecSec": {
          name: 'isTecSec',
          type: 'boolean',
          default: false
        },
        "downloadedDiplomaDate": {
          name: 'downloadedDiplomaDate',
          type: 'Date'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "campusId": {
          name: 'campusId',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        company2: {
          name: 'company2',
          type: 'Company[]',
          model: 'Company',
          relationType: 'hasMany',
          modelThrough: 'Admin_company',
          keyThrough: 'companyId',
          keyFrom: 'id',
          keyTo: 'userId'
        },
        groupEmployees: {
          name: 'groupEmployees',
          type: 'Group_employee[]',
          model: 'Group_employee',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        orderEmployees: {
          name: 'orderEmployees',
          type: 'OrderEmployee[]',
          model: 'OrderEmployee',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        gifts: {
          name: 'gifts',
          type: 'Gifts[]',
          model: 'Gifts',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        certs: {
          name: 'certs',
          type: 'Certificate[]',
          model: 'Certificate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        assistances: {
          name: 'assistances',
          type: 'Employee_event[]',
          model: 'Employee_event',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        event: {
          name: 'event',
          type: 'Event[]',
          model: 'Event',
          relationType: 'hasMany',
          modelThrough: 'Employee_event',
          keyThrough: 'eventId',
          keyFrom: 'id',
          keyTo: 'userId'
        },
        responses: {
          name: 'responses',
          type: 'Response[]',
          model: 'Response',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        categories: {
          name: 'categories',
          type: 'CertificateCategory[]',
          model: 'CertificateCategory',
          relationType: 'hasMany',
          modelThrough: 'User_categories',
          keyThrough: 'categoryId',
          keyFrom: 'id',
          keyTo: 'adminId'
        },
      }
    }
  }
}
