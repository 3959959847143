import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-borrar-admin',
  templateUrl: './borrar-admin.component.html',
  styleUrls: ['./borrar-admin.component.scss']
})
export class BorrarAdminComponent implements OnInit {

  accepted = false;

  constructor(
    public dialogRef : MatDialogRef<BorrarAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user : UserApi,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit() {
  }

  delete(){
    this._user.patchAttributes(this.data.Admin.id, {isDeleted: true}).subscribe((user) => {
      this.snackBar.open('Administrador eliminado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(user);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
