import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserApi, LoopBackAuth } from './../../services/shared/sdk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from './../../services/base-url';


@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.scss']
})
export class AdministracionComponent implements OnInit {
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });
  showUploadFile = true;
  fileUploadResponse = {
    created: [],
    errors: []
  };
  fileUploaded = false;

  constructor(
    private router: ActivatedRoute,
    private _user: UserApi,
    private loopBackAuth: LoopBackAuth,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {

  }

  createPlantilla() {
    const data = [
      {
        payrollNumber: 'Nómina',
        name: 'Nombre',
        email: 'Correo',
        system: 'Sistema 0->tec 1-> tecmilenio',
        companyId: 'Empresa Id',
        campusId: 'Campus Id',
        country: 'Estado',
        years: 'Antigüedad'
      }
    ];
    const archivo = new Angular5Csv(data, 'PlantillaUsuarios');

  }

  renderCSV(lines: string[]): void {
    const myTable = document.getElementById("myTable");
    //myTable.innerHTML = '';
    console.log()
    //loop all rows
    for (var count = 0; count < lines.length; count++) {
      //create a tr element
      var row = document.createElement("tr");
      //split each row content
      var rowContent = lines[count].split(",");
      //loop throw all columns of a row
      for (var i = 0; i < rowContent.length; i++) {
        //create td element
        var cellElement = document.createElement("td");
        if (count == 0) {
          cellElement = document.createElement("th");
        } else {
          cellElement = document.createElement("td");
        }
        //add a row element as a node for table
        var cellContent = document.createTextNode(rowContent[i]);

        cellElement.appendChild(cellContent);
        //append row child
        row.appendChild(cellElement);
      }
      //append table contents
      myTable.appendChild(row);
    }
  }

  uploadFile(event) {
    this.showUploadFile = false;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      console.log(e.target);
      
      console.log(e.target.result.split(/\r?\n|\r/));
      // document.getElementById('output').innerText = e.target.result;
      //this.renderCSV(e.target.result.split(/\r?\n|\r/))
    }
    this.uploader.queue[0].file.name = UUID.UUID();
    if (this.uploader.queue.length) {
      let file = this.uploader.queue[0].file
      // this.uploader.uploadAll();
      this.uploader.queue[0].upload();
      reader.readAsText(new Blob([file.rawFile], { type: file.type }))
    }

    this.uploader.onSuccessItem = (response: any) => {
      this._user.createEmployeesFile(this.uploader.queue[0].file.name).subscribe(resp => {
        this.uploader.clearQueue();
        this.fileUploadResponse = resp;
        this.fileUploaded = true;
        console.log(this.fileUploadResponse);
        if(resp.created.length) {
          this._snackBar.open(`${resp.created.length} colaboradores creados`, 'Listo!', {
            duration: 2000
          });
        }
        if(resp.errors.length) {
          this._snackBar.open(`Errores al registrar: ${resp.errors.length}`, 'Error', {
            duration: 2000
          });
        }
        this.showUploadFile = true;
      }, (error) => {
        this.showUploadFile = true;
        this.fileUploaded = false;
        this.uploader.clearQueue();
        this._snackBar.open('Ocurrió un error', 'Error!', {
          duration: 2000
        });
      });
    }

    this.uploader.onErrorItem = () => {
      this.showUploadFile = true;
      this.uploader.clearQueue();
      this._snackBar.open('No se subió archivo', 'Error!', {
        duration: 2000
      });
    }


  }


}
