import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CampusApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-eliminar-campus',
  templateUrl: './eliminar-campus.component.html',
  styleUrls: ['./eliminar-campus.component.scss']
})
export class EliminarCampusComponent implements OnInit {

  accepted = false;

  constructor(
    public dialogRef: MatDialogRef<EliminarCampusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _campus: CampusApi,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  delete(){
    this._campus.patchAttributes(this.data.element.id, {isDeleted: true}).subscribe((resp) => {
      this.snackBar.open('Campus eliminado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(resp);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(){
    this.dialogRef.close();
  }

}
