/* tslint:disable */
import {
  Event,
  User
} from '../index';

declare var Object: any;
export interface Employee_eventInterface {
  "eventId": number;
  "userId": number;
  "status"?: boolean;
  "guest": number;
  "isActive"?: boolean;
  "id"?: number;
  event?: Event;
  user?: User;
}

export class Employee_event implements Employee_eventInterface {
  "eventId": number;
  "userId": number;
  "status": boolean;
  "guest": number;
  "isActive": boolean;
  "id": number;
  event: Event;
  user: User;
  constructor(data?: Employee_eventInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Employee_event`.
   */
  public static getModelName() {
    return "Employee_event";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Employee_event for dynamic purposes.
  **/
  public static factory(data: Employee_eventInterface): Employee_event{
    return new Employee_event(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Employee_event',
      plural: 'Employee_events',
      path: 'Employee_events',
      idName: 'id',
      properties: {
        "eventId": {
          name: 'eventId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'boolean',
          default: false
        },
        "guest": {
          name: 'guest',
          type: 'number',
          default: 0
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        event: {
          name: 'event',
          type: 'Event',
          model: 'Event',
          relationType: 'belongsTo',
                  keyFrom: 'eventId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
