/* tslint:disable */

declare var Object: any;
export interface Campus_commerceInterface {
  "campusId"?: number;
  "commerceId"?: number;
  "id"?: number;
}

export class Campus_commerce implements Campus_commerceInterface {
  "campusId": number;
  "commerceId": number;
  "id": number;
  constructor(data?: Campus_commerceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Campus_commerce`.
   */
  public static getModelName() {
    return "Campus_commerce";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Campus_commerce for dynamic purposes.
  **/
  public static factory(data: Campus_commerceInterface): Campus_commerce{
    return new Campus_commerce(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Campus_commerce',
      plural: 'Campus_commerces',
      path: 'Campus_commerces',
      idName: 'id',
      properties: {
        "campusId": {
          name: 'campusId',
          type: 'number'
        },
        "commerceId": {
          name: 'commerceId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
