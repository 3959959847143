import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, BalanceHistoryApi, CompanyApi } from '../../../services/shared/sdk';

@Component({
  selector: 'app-editar-saldo',
  templateUrl: './editar-saldo.component.html',
  styleUrls: ['./editar-saldo.component.scss']
})
export class EditarSaldoComponent implements OnInit {
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditarSaldoComponent>,
    private _balance: BalanceHistoryApi,
    private formBuilder: FormBuilder,
    private _user: UserApi,
    private _company: CompanyApi
  ) {
    console.log(data);
    this.form = this.formBuilder.group({
      companyId: [''],
      balance: [''],
      limit: ['', Validators.required],
      periodicity: ['']
    });     
   }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  editBalance(){
    this._company.patchAttributes(this.data.balance.id, {limitUse: this.data.balance.limitUse, periodicity: this.data.balance.periodicity}).subscribe((updateBalance) => {
      console.log(updateBalance);
      this.dialogRef.close(this.data);
    })
  }



}
