import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GroupApi } from '../../../services/shared/sdk';
import * as moment from "moment";

import localeEsMx from '@angular/common/locales/es-MX';

moment.locale('es-MX');

registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'app-perfil-colaborador',
  templateUrl: './perfil-colaborador.component.html',
  styleUrls: ['./perfil-colaborador.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'es-MX' }]
})
export class PerfilColaboradorComponent implements OnInit {

  FechaActual = moment(new Date());
  Edad: any;
  Antiguedad: any;

  constructor(
    public dialogRef: MatDialogRef<PerfilColaboradorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _group: GroupApi
  ) { }

  ngOnInit() {
    console.log('this.data.user', this.data.user);

    if (this.data.user.birthdate) {
      let birthdate = moment(this.data.user.birthdate);
      this.Edad = this.FechaActual.diff(birthdate, 'years');
    }
    if (this.data.user.dateAdmission) {
      let Admission = moment(this.data.user.dateAdmission);
      this.Antiguedad = this.FechaActual.diff(Admission, 'weeks',);
      if (this.Antiguedad >= 52) {
        this.Antiguedad = this.FechaActual.diff(Admission, 'years');
        if (this.Antiguedad == 1) {
          this.Antiguedad = this.Antiguedad + " año";
        }
        else {
          this.Antiguedad = this.Antiguedad + " años"
        }
      }
      else {
        this.Antiguedad = this.Antiguedad + " semanas"
      }
    }
    if (this.data.user.groupEmployees && this.data.user.groupEmployees.length) {
      this.data.user.groupEmployees.forEach(group => {
        this._group.findById(group.groupId).subscribe((res: any) => {
          group.name = res.name;
        })
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
