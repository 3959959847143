import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CertificateDesignApi } from '../../../services/shared/sdk';

@Component({
  selector: 'app-delete-diseno',
  templateUrl: './delete-diseno.component.html',
  styleUrls: ['./delete-diseno.component.scss']
})
export class DeleteDisenoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteDisenoComponent>,
    private _snackBar: MatSnackBar,
    private _design: CertificateDesignApi
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete() {
    this._design.deleteById(this.data.diseno.id).subscribe(() => {
      this._snackBar.open('Diseño eliminado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(this.data);
    });
  }

}
