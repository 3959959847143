import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, BalanceHistoryApi, CompanyApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-nuevo-saldo',
  templateUrl: './nuevo-saldo.component.html',
  styleUrls: ['./nuevo-saldo.component.scss']
})
export class NuevoSaldoComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NuevoSaldoComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _balance: BalanceHistoryApi,
    private _user: UserApi,
    private _company: CompanyApi,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      companyId: ['', Validators.required],
      balance: ['', Validators.required],
      limit: ['', Validators.required],
      periodicity: ['']
    });  

    this.data.companies = this.data.companies.filter(company => {
      return company.limitUse == null && (company.periodicity == null || company.periodicity == '')
    });
   }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createBalance () {
    let unidad: any;
    let cantidad: any;
    let expirationDate = null;

    if (this.form.value.periodicity != 'N/A') {
      unidad = this.form.value.periodicity == 'Mensual'? 'M': 'days'; 
      cantidad = this.form.value.periodicity == 'Mensual' ? 1 : this.form.value.periodicity == 'Semanal' ? 7 : 1;
      expirationDate = moment(new Date()).endOf('day').add(cantidad, unidad);
    }
    
    let balance: any = {
      companyId: this.form.value.companyId,
      balance: this.form.value.balance,
      balanceUsed: 0,
      adminId: this._user.getCurrentId(),
      status: 'inBalance',
      expirationDate: expirationDate
    };
    
    
    this._company.patchAttributes(balance.companyId, {periodicity: this.form.value.periodicity, limitUse: this.form.value.limit, total: this.form.value.limit}).subscribe((companyupdate) =>{
      this._balance.create(balance).subscribe((balanceNew) => {      
        this.dialogRef.close({
          balanceNew: balanceNew,
          company: companyupdate
        });        
      }, (err:any) => {
        this._snackBar.open(err.message, 'Error!', {
          duration: 2000
        });
      });
    });

      
  }

}
