/* tslint:disable */
import {
  Company,
  CertificateCategory
} from '../index';

declare var Object: any;
export interface CertificateDesignInterface {
  "image"?: string;
  "designs"?: Array<any>;
  "companyId"?: number;
  "categoryId"?: number;
  "id"?: number;
  company?: Company;
  category?: CertificateCategory;
}

export class CertificateDesign implements CertificateDesignInterface {
  "image": string;
  "designs": Array<any>;
  "companyId": number;
  "categoryId": number;
  "id": number;
  company: Company;
  category: CertificateCategory;
  constructor(data?: CertificateDesignInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CertificateDesign`.
   */
  public static getModelName() {
    return "CertificateDesign";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CertificateDesign for dynamic purposes.
  **/
  public static factory(data: CertificateDesignInterface): CertificateDesign{
    return new CertificateDesign(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CertificateDesign',
      plural: 'CertificateDesigns',
      path: 'CertificateDesigns',
      idName: 'id',
      properties: {
        "image": {
          name: 'image',
          type: 'string'
        },
        "designs": {
          name: 'designs',
          type: 'Array&lt;any&gt;'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'CertificateCategory',
          model: 'CertificateCategory',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
      }
    }
  }
}
