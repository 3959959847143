import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { EventApi, LoopBackAuth, Employee_eventApi, UserApi } from '../../../services/shared/sdk';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import * as moment from 'moment'

@Component({
  selector: 'app-asistencias',
  templateUrl: './asistencias.component.html',
  styleUrls: ['./asistencias.component.scss']
})
export class AsistenciasComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'campus', 'date', 'status', 'guest', 'actions'];
  dataSource = new MatTableDataSource();
  asistencias;
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });
  eventId;
  companyId;
  event;
  @ViewChild('singlePaginator') paginator: MatPaginator;
  showUploadFile = true;

  constructor(
    private router: ActivatedRoute,
    private _event: EventApi,
    private _employeeEvent: Employee_eventApi,
    private _user: UserApi,
    public dialog: MatDialog,
    private loopBackAuth: LoopBackAuth,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.router.params.subscribe((params) => {
      if (params.id) {
        this.eventId = params.id;
        this.getAssistances();
      }
    });
  }

  getAssistances() {
    this._event.find({
      where: {
        id: this.eventId
      }, include: ['campus', 'company', {
        relation: 'assistances',
        scope: {
          include: 'user'
        }
      }]
    }).subscribe((event: any) => {
      console.log(event);
      this.event = event[0];

      this.asistencias = event[0].assistances;
      if (this.asistencias) {
        this.asistencias.forEach(asistencia => {
          asistencia.companyName = event[0].company.name;
          asistencia.campusName = event[0].campus.name;
          switch (this.event.id) {
            case 34:
              asistencia.companyName = 'Tecnológico de Monterrey';
              break;
            case 35:
              asistencia.companyName = 'Sorteos Tec';
              break;
            case 36:
              asistencia.companyName = 'Secundarias';
              break;
            default:
              break;
          }
          asistencia.username = asistencia.user.name;
          asistencia.date = moment(event[0].date).add(5, 'hours');
          asistencia.email = asistencia.user.email;
          asistencia.status = asistencia.status == null ? 'Sin confirmación' : (asistencia.status == true ? 'Asistirá' : 'No asistirá');
        });
      }
      this.dataSource = new MatTableDataSource(this.asistencias);
      setTimeout(() => this.dataSource.paginator = this.paginator);

      console.log(this.asistencias);

    });
  }

  createPlantilla() {
    console.log(this.event.companyId);

    let employees = [];
    this._user.find({
      where: {
        companyId: this.event.companyId,
        isDeleted: false
      },
      include: [{
        relation: 'assistances',
        scope: {
          where: {
            eventId: this.eventId
          }
        }
      }]
    }).subscribe(users => {
      employees = users.filter((user: any) => {
        return !user.assistances.length;
      }).map((user: any) => { return { email: user.email, userId: user.id, eventId: this.eventId } });
      const data = [{ email: 'Correo de colaborador', userId: 'Id de usuario', eventId: 'Id de evento' }].concat(employees);
      const archivo = new Angular5Csv(data, 'Invitaciones_' + this.event.campus.name);
    });
  }

  uploadFile(event) {
    this.showUploadFile = false;

    const reader = new FileReader();

    this.uploader.queue[0].file.name = UUID.UUID();
    if (this.uploader.queue.length) {
      this.uploader.uploadAll();
    }

    this.uploader.onSuccessItem = (response) => {
      this._user.createAssistancesFile(this.uploader.queue[0].file.name).subscribe(resp => {
        this._snackBar.open('Asistencias creadas', 'Listo!', {
          duration: 2000
        });
        this.showUploadFile = true;
      }, (error) => {
        this.showUploadFile = true;
        this._snackBar.open('Ocurrió un error', 'Error!', {
          duration: 2000
        });
      });
    }

    this.uploader.onErrorItem = () => {
      this.showUploadFile = true;
      this._snackBar.open('No se subió archivo', 'Error!', {
        duration: 2000
      });
    }


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  downloadReport() {
    let data = [{
      company: 'Empresa',
      campus: 'Campus',
      name: 'Colaborador',
      email: 'Correo',
      status: 'Status'
      //guest: 'Invitados'
    }];
    this.asistencias.forEach((asistencia) => {
      data.push({
        company: asistencia.companyName,
        campus: asistencia.campusName,
        name: asistencia.username,
        email: asistencia.email,
        status: asistencia.status
        //guest: asistencia.guest
      });
    });

    const archivo = new Angular5Csv(data, 'Reporte_' + this.event.campus.name);
  }

}
