import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyApi, UserApi, StoreApi, Company_storeApi, LoopBackAuth, ContainerApi, Admin_companyApi, Codigo_postalApi } from '../../../services/shared/sdk';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-edit-empresa',
  templateUrl: './edit-empresa.component.html',
  styleUrls: ['./edit-empresa.component.scss']
})
export class EditEmpresaComponent implements OnInit {

  dropdownSettings = {};

  form: FormGroup;
  admins: any = null;
  shops: any;
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });
  uploaderLogoWhite: FileUploader = new FileUploader({ url: this.uploadURL });
  uploaderGraphic: FileUploader = new FileUploader({ url: this.uploadURL });
  uploaderBanner: FileUploader = new FileUploader({ url: this.uploadURL });
  imageLogo: any;
  imageLogoWhite: any;
  imageGraphic: any;
  company: any = {};
  color: any = '#ff000073';
  banner: any = 'url(assets/images/banner.jpg)';
  companyId;
  images: any = {
    logo: '',
    logoWhite: '',
    graphic: '',
    banner: ''
  }
  patronEmail = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";

  states = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ];

  colonias = [];
  relations;
  Frase;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _company: CompanyApi,
    private _commerce: StoreApi,
    private _commerceCompany: Company_storeApi,
    private _admin: UserApi,
    private _snackBar: MatSnackBar,
    private loopBackAuth: LoopBackAuth,
    private _container: ContainerApi,
    private _admin_company: Admin_companyApi,
    private _code: Codigo_postalApi
  ) {

    this.route.params.subscribe((params) => {
      this.companyId = params.id;
    });

    this._commerceCompany.find({ where: { companyId: this.companyId } }).subscribe(resp => {
      this.relations = resp;
    })

    this.form = this.formBuilder.group({
      name: [this.company.name, Validators.required],
      admin: [this.company.adminId, Validators.required],
      attendantName: [this.company.attendantName, Validators.required],
      attendantEmail: [this.company.attendantEmail, [Validators.required, Validators.pattern(this.patronEmail)]],
      attendantPhone: [this.company.attendantPhone, Validators.required],
      country: [this.company.country, Validators.required],
      state: [this.company.state, Validators.required],
      zipCode: [this.company.zipCode, Validators.required],
      suburb: [this.company.suburb, Validators.required],
      street: [this.company.street, Validators.required],
      businessName: [this.company.businessName, Validators.required],
      typePerson: [this.company.typePerson, Validators.required],
      rfc: [this.company, Validators.required],
      addressNumber: [this.company.rfc, Validators.required],
      logo: [this.company.logo],
      logoWhite: [this.company.logoWhite],
      graphic: [this.company.graphic],
      banner: [this.company.banner],
      color: [this.company.color],
      shops: [this.company.shops],
      phrase: [this.company.phrase],
      balanceType: [this.company.balanceType]
    });
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 20,
      allowSearchFilter: false
    };

    this._company.findById(this.companyId, {
      include: ['stores',
        {
          relation: 'adminCompanies',
          scope: {
            include: 'admin'
          }
        }
      ]
    }).subscribe((company: any) => {
      this.colonias.push(company.suburb);
      this.zipCodeOne(company.zipCode);
      this.Frase = company.phrase;

      this._admin.find({
        where: {
          isAdmin: true
        }
      }).subscribe((admins: any) => {
        this.admins = admins;
      });

      this.company = company;
      this.company.admins = company.adminCompanies.map((ac: any) => {
        return {
          id: ac.adminId,
          name: ac.admin.name,
          acId: ac.id
        };
      });
      this.images = {
        logo: company.logo,
        logoWhite: company.logoWhite,
        graphic: company.graphic,
        banner: company.banner
      }
      this.imageLogo = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + this.company.logo;
      this.imageLogoWhite = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + this.company.logoWhite;
      this.imageGraphic = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + this.company.graphic;
      this.banner = 'url(' + BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + this.company.banner + ')';
      this.color = this.company.color;
      this._commerce.find({ where: { isDeleted: false } }).subscribe((shops: any) => {
        this.shops = [];
        let storesCompany = this.company.stores.map((shop) => shop.id);
        shops.forEach(shop => {
          this.shops.push({
            id: shop.id,
            selected: storesCompany.includes(shop.id) ? true : false,
            name: shop.name
          });
        });

      });
    });
  }



  saveCompany() {

    this.Arreglos();

    this.company.color = this.color;
    this.company.banner = this.company.banner;
    this.company.logo = this.company.logo;
    this.company.logoWhite = this.company.logoWhite;
    this.company.graphic = this.company.graphic;
    this.company.phrase = this.Frase;

    this._company.patchAttributes(this.companyId, this.company).subscribe((newCompany: any) => {
      this._snackBar.open('Empresa actualizada', 'Listo!', {
        duration: 6000
      });

      let acs = this.company.adminCompanies.map((admcom: any) => { return admcom.adminId });
      let cas = this.company.admins.map((ca: any) => { return ca.id });
      this.company.admins.forEach((admin) => {
        if (!acs.includes(admin.id)) {
          this._admin_company.create({
            adminId: admin.id,
            companyId: newCompany.id
          }).subscribe(resp => {
            console.log(resp);
          });
        }
      });

      acs.forEach(ac => {
        console.log(ac);
        console.log(cas);

        if (!cas.includes(ac)) {
          let admCompany = this.company.adminCompanies.find((admc: any) => { return admc.adminId == ac && admc.companyId == this.company.id })
          this._admin_company.deleteById(admCompany.id);
        }
      });

      // Subir imagenes
      if (this.uploader.queue.length) {
        this.uploader.uploadAll();
        this._container.removeFile('cloudgiftmaster', this.images.logo).subscribe((resp) => {
          console.log(resp);
        });
      }
      if (this.uploaderLogoWhite.queue.length) {
        this.uploaderLogoWhite.uploadAll();
        this._container.removeFile('cloudgiftmaster', this.images.logoWhite).subscribe((resp) => {
          console.log(resp);
        });
      }
      if (this.uploaderGraphic.queue.length) {
        this.uploaderGraphic.uploadAll();
        this._container.removeFile('cloudgiftmaster', this.images.graphic).subscribe((resp) => {
          console.log(resp);
        });
      }
      if (this.uploaderBanner.queue.length) {
        this.uploaderBanner.uploadAll();
        this._container.removeFile('cloudgiftmaster', this.images.banner).subscribe((resp) => {
          console.log(resp);
        });
      }

      this.router.navigate(['/lista-empresas']);

    });
  }

  Arreglos() {
    let shopsSelected = this.shops.filter((shop) => {
      return shop.selected;
    }).map((shop) => {
      return shop.id
    });

    let storesCompany = this.company.stores.map((shop) => shop.id);

    let ArregloA = storesCompany;
    let ArregloB = shopsSelected;


    storesCompany.forEach((A: any, index1) => {
      shopsSelected.forEach((B: any, index2) => {
        if (A == B) {
          ArregloA.splice(index1, 1, null);
          ArregloB.splice(index2, 1, null);
        }
      });
    });

    if (ArregloA && ArregloA.length) {
      let relDele = [];
      ArregloA.forEach(element => {
        this.relations.forEach(rel => {
          if (element == rel.storeId) {
            relDele.push(rel.id);
          }
        });
      });

      if (relDele && relDele.length) {
        relDele.forEach(element => {
          this._commerceCompany.deleteById(element).subscribe(resp => {
            console.log("Comercio borrado", resp);
          })
        });
      }
    }

    if (ArregloB && ArregloB.length) {
      ArregloB.forEach(element => {
        if (element != null) {
          this._commerceCompany.create({ storeId: element, companyId: this.companyId }).subscribe(resp => {
            console.log("Comercio agregado", resp);
          })
        }
      });
    }

  }

  getAdmin() {
    this._admin.find({
      where: {
        isAdmin: true
      }
    }).subscribe((admins: any) => {
      this.admins = admins;
    });
  }

  getCommers() {
    this._commerce.find({ where: { isDeleted: false } }).subscribe((shops: any) => {
      this.shops = [];
      let storesCompany = this.company.stores.map((shop) => shop.id);
      console.log(storesCompany);
      shops.forEach(shop => {
        this.shops.push({
          id: shop.id,
          selected: storesCompany.includes(shop.id) ? true : false,
          name: shop.name
        });
      });

    });
  }

  uploadFileLogo(event) {
    console.log("avatar");

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageLogo = eventReader.target.result;
    };

    this.uploader.queue[0].file.name = UUID.UUID();
    this.company.logo = this.uploader.queue[0].file.name;
  }

  uploadFileLogoWhite(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageLogoWhite = eventReader.target.result;
    };

    this.uploaderLogoWhite.queue[0].file.name = UUID.UUID();
    this.company.logoWhite = this.uploaderLogoWhite.queue[0].file.name;
  }

  uploadFileGraphic(event) {
    console.log("avatar");

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.imageGraphic = eventReader.target.result;
    };

    this.uploaderGraphic.queue[0].file.name = UUID.UUID();
    this.company.graphic = this.uploaderGraphic.queue[0].file.name;
  }

  uploadFileBanner(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.banner = 'url(' + eventReader.target.result + ')';
    };

    this.uploaderBanner.queue[0].file.name = UUID.UUID();
    this.company.banner = this.uploaderBanner.queue[0].file.name;
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.company.admins);

  }
  onSelectAll(items: any) {
    console.log(items);
  }

  onDeSelect(item: any) {
    console.log(item);
    console.log(this.company.admins);

  }

  zipCodeFinder(event) {
    if (event.length >= 5) {
      this.colonias = []
      this._code.find({ where: { cp: event } }).subscribe((cp: any) => {
        if (cp) {
          cp.forEach(data => {
            this.colonias.push(data.colonia)
          });
        }
      })
    }
  }

  zipCodeOne(code) {
    this._code.find({ where: { cp: code } }).subscribe((cp: any) => {
      if (cp) {
        cp.forEach(data => {
          this.colonias.push(data.colonia)
        });
      }
    })
  }

}
