import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserApi } from './shared/sdk';

@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {

  constructor(
    private router: Router,
    private _user: UserApi
  ) { }

  canActivate() {
    if (this._user.isAuthenticated()) {
      return true;
    } else {
      console.log('No tienes permiso para ver esta página');
      this.router.navigate(['loginv2']);
      return false;
    }
  }
}