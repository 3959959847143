import { Component, OnInit ,ViewChild, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CompanyApi, Admin_companyApi, UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-editar-admin',
  templateUrl: './editar-admin.component.html',
  styleUrls: ['./editar-admin.component.scss']
})
export class EditarAdminComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  companyCtrl = new FormControl();
  filteredCompanies: Observable<string[]>;
  companies: any = [];
  Companies: any = [];
  otherCompanies: any = [];
  allCompanies: any = [];

  patronEmail = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";

  @ViewChild('companyInput') companyInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  form: FormGroup;
  adminEdit: any;

  constructor(
    private formBuilder: FormBuilder,
    private _company: CompanyApi,
    private _admComp: Admin_companyApi,
    private _user: UserApi,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditarAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
     }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['',Validators.required],
      email: ['',[Validators.required, Validators.pattern(this.patronEmail)]]
    });

    this.form.get('name').setValue(this.data.Admin.name);
    this.form.get('email').setValue(this.data.Admin.email);
    if(!this.data.Admin.isSuperAdmin) this.getCompanies();
  }

 async getCompanies(){
    this._company.find({where: {isDeleted: false}}).subscribe((comps) => {
      this.otherCompanies = comps;
      this.Companies = comps;
      this.otherCompanies.forEach((comp:any, index) => {
        this.data.Admin.yourCompanies.forEach((yourComp) => {
          if(yourComp.companyId == comp.id){
            this.companies.push(comp.name);
            comp.borrado = true;
          }
        });
      });
        this.otherCompanies.forEach((compa) => {
          if(!compa.borrado){
            this.allCompanies.push(compa.name);
          }
      });
      this.filteredCompanies = this.companyCtrl.valueChanges.pipe(
        startWith(null),
        map((company: string | null) => company ? this._filter(company): this.allCompanies.slice())
      );
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log(input);
    console.log(value);

    if((value || '').trim()) {
      this.companies.push(value.trim());
    }

    if(input) {
      input.value = '';
    }

    this.companyCtrl.setValue(null);
  }

  remove(company: string): void {
    const index = this.companies.indexOf(company);
    this.Companies.forEach((comp : any) => {
      if(comp.name == company){
        if(comp.enter && comp.enter == true){
          comp.enter = false;
        }
        else {
          comp.exit = true;
        }
      }
    });

    if (index >= 0) {
      this.companies.splice(index,1);
    }
     this.allCompanies.unshift(company);
     this.companyInput.nativeElement.value = '';
     this.companyCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.companies.push(event.option.viewValue);

    this.Companies.forEach((comp: any) => {
      if(comp.name == event.option.viewValue){
        if(comp.exit && comp.exit == true){
          comp.exit = false;
        }
        else {
          comp.enter = true;
        }
      }
    })

    let indexCompany = this.allCompanies.indexOf(event.option.viewValue);
     this.allCompanies.splice(indexCompany,1);
    this.companyInput.nativeElement.value = '';
    this.companyCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allCompanies.filter(company => company.toLowerCase().indexOf(filterValue) === 0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  EditAdmin(){
    this.Companies.forEach((company) => {
      if(company.enter && company.enter == true){
        this._admComp.create({adminId: this.data.Admin.id, companyId: company.id}).subscribe(resp =>{
          console.log(`La compañía ${company.name} fue asignada. Los datos son ${resp}`);
        });
      }
      if(company.exit && company.exit == true){
        this.data.Admin.yourCompanies.forEach((yourCompany) => {
          if(yourCompany.companyId == company.id){
            this._admComp.deleteById(yourCompany.id).subscribe(res =>{
              console.log(`Se desasigno la compañía ${company.name}. ${res}`);
            });
          }
        });
      }
    });
    let userData = {...this.form.value}
    if(this.data.Admin.isSuperAdmin) userData = {...userData, type: 'admin'}
    this._user.patchAttributes(this.data.Admin.id, userData).subscribe(resp => {
      console.log(resp);
      this._snackBar.open('Administrador editado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(resp);
    })
  }

}
