/* tslint:disable */
import {
  Company,
  Group_employee,
  OrderEmployee
} from '../index';

declare var Object: any;
export interface EmployeeInterface {
  "name"?: string;
  "dateBirth"?: Date;
  "maritalStatus"?: string;
  "anniversaryDate"?: Date;
  "birthdayCouple"?: Date;
  "gender"?: string;
  "isParent"?: boolean;
  "numberChildren"?: number;
  "childrenBirthday"?: Array<any>;
  "dateAdmission"?: Date;
  "payrollNumber"?: string;
  "image"?: string;
  "typeLogin"?: string;
  "balance"?: number;
  "companyId"?: number;
  "isAdmin"?: boolean;
  "adminId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "isDeleted"?: boolean;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "password"?: string;
  accessTokens?: any[];
  company?: Company;
  groupEmployees?: Group_employee[];
  orderEmployees?: OrderEmployee[];
}

export class Employee implements EmployeeInterface {
  "name": string;
  "dateBirth": Date;
  "maritalStatus": string;
  "anniversaryDate": Date;
  "birthdayCouple": Date;
  "gender": string;
  "isParent": boolean;
  "numberChildren": number;
  "childrenBirthday": Array<any>;
  "dateAdmission": Date;
  "payrollNumber": string;
  "image": string;
  "typeLogin": string;
  "balance": number;
  "companyId": number;
  "isAdmin": boolean;
  "adminId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "isDeleted": boolean;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "password": string;
  accessTokens: any[];
  company: Company;
  groupEmployees: Group_employee[];
  orderEmployees: OrderEmployee[];
  constructor(data?: EmployeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Employee`.
   */
  public static getModelName() {
    return "Employee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Employee for dynamic purposes.
  **/
  public static factory(data: EmployeeInterface): Employee{
    return new Employee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Employee',
      plural: 'Employees',
      path: 'Employees',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "dateBirth": {
          name: 'dateBirth',
          type: 'Date'
        },
        "maritalStatus": {
          name: 'maritalStatus',
          type: 'string'
        },
        "anniversaryDate": {
          name: 'anniversaryDate',
          type: 'Date'
        },
        "birthdayCouple": {
          name: 'birthdayCouple',
          type: 'Date'
        },
        "gender": {
          name: 'gender',
          type: 'string'
        },
        "isParent": {
          name: 'isParent',
          type: 'boolean'
        },
        "numberChildren": {
          name: 'numberChildren',
          type: 'number'
        },
        "childrenBirthday": {
          name: 'childrenBirthday',
          type: 'Array&lt;any&gt;'
        },
        "dateAdmission": {
          name: 'dateAdmission',
          type: 'Date'
        },
        "payrollNumber": {
          name: 'payrollNumber',
          type: 'string'
        },
        "image": {
          name: 'image',
          type: 'string'
        },
        "typeLogin": {
          name: 'typeLogin',
          type: 'string'
        },
        "balance": {
          name: 'balance',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "isAdmin": {
          name: 'isAdmin',
          type: 'boolean'
        },
        "adminId": {
          name: 'adminId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        groupEmployees: {
          name: 'groupEmployees',
          type: 'Group_employee[]',
          model: 'Group_employee',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'employeeId'
        },
        orderEmployees: {
          name: 'orderEmployees',
          type: 'OrderEmployee[]',
          model: 'OrderEmployee',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'employeeId'
        },
      }
    }
  }
}
