import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { StoreApi, UserApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-delete-comercio',
  templateUrl: './delete-comercio.component.html',
  styleUrls: ['./delete-comercio.component.scss']
})
export class DeleteComercioComponent implements OnInit {

  accepted = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteComercioComponent>,
    private _store: StoreApi,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
          
    }

    delete() {
      this._store.patchAttributes(this.data.store.id,{
        isDeleted: true
      }).subscribe((storeUpdate: any) => {
        this._snackBar.open('Comercio eliminado', 'Listo!', {
          duration: 2000
        });
        this.dialogRef.close(this.data);
      });
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
