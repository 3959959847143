import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyApi, OrderEmployeeApi, GiftsApi, UserApi, StoreApi, CertificateApi, CertificateCategoryApi, Employee, CampusApi, Gifts } from '../../../services/shared/sdk';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog } from '@angular/material';
import { InfoPedidoComponent } from '../info-pedido/info-pedido.component';
import { EntregaRegalosComponent } from '../entrega-regalos/entrega-regalos.component';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { registerLocaleData } from '@angular/common';
import * as momentTz from 'moment-timezone';

import localeEsMx from '@angular/common/locales/es-MX';
import { Moment } from 'moment';

export enum statusType {
  'available' = 'Entregado',
  'expired' = 'Expirado',
  'changed' = 'Usado',
  'dowloded' = 'Descargado',
  'pending' = 'Pendiente'
}

moment.locale('es-MX');

registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'app-lista-pedidos-colaboradores',
  templateUrl: './lista-pedidos-colaboradores.component.html',
  styleUrls: ['./lista-pedidos-colaboradores.component.scss']
})
export class ListaPedidosColaboradoresComponent implements OnInit {

  hoveredDate: NgbDate | null = null;

  fromDate: any;
  toDate: any | null = null;
  fromDateReport: any
  toDateReport: any
  FechaActual = new Date();
  FechaAnterior = moment(this.FechaActual).subtract(15, "days");

  companies;
  stores;
  orders;
  certificados;
  categorias;
  companyId = 'all';
  status = 'all';
  storeId = 'all';
  certtype = "all";
  ordersCompany;
  ordersFiltered;
  dropdownSettings = {};
  dropdownSettings2 = {};
  campuses: any;
  searchFrom: string;
  searchTo: string;

  dataOrders: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('singlePaginator') paginator: MatPaginator;

  statusType = [
    "en revisión",
    "programado",
    "enviado",
    "canjeado",
    "por expirar",
    "expirado",
    "cancelado"
  ];
  displayedColumns = ["createdAt", "employeeName", "companyName", "storeName", "employeeEmail", "uploadedDate", "categories", "value", "status", "actions"];

  constructor(
    public dialog: MatDialog,
    private _company: CompanyApi,
    private _orders: OrderEmployeeApi,
    private _user: UserApi,
    private _gifts: GiftsApi,
    private _store: StoreApi,
    private _certificate: CertificateApi,
    private _certCategory: CertificateCategoryApi,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private _campus: CampusApi
  ) { }

  ngOnInit() {
    this.getCompanies();
    this.getStores();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 25,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar empresa',
      closeDropDownOnSelection: true
    };
    this.dropdownSettings2 = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 25,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar comercio',
      closeDropDownOnSelection: true
    };
    this._campus.find().subscribe((campuses: any) => {
      this.campuses = campuses
    })
  }

  getCompanies() {
    this._company.find({ where: { isDeleted: false }, include: 'adminCompanies' }).subscribe((companies) => {
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.companies = companies;
          this.companies.unshift({ name: 'MOSTRAR TODO', id: 'all' });
        } else {
          this.companies = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id);
          });
          this.companies.unshift({ name: 'MOSTRAR TODO', id: 'all' });
        }
        //this.getGifts();
        this.getCertCategories();
        const start = momentTz().tz('Mexico/General').startOf('day')
        const end = momentTz().tz('Mexico/General').endOf('day')
        this.fromDateReport = new Date()
        this.toDateReport = new Date()

        this.OrdenesPorFecha(start, end)
      });
    })
  }

  getStores() {
    this._store.find({ where: { isDeleted: false }, include: 'companyStores' }).subscribe((stores: any) => {
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.stores = stores;
          this.stores.unshift({ name: 'MOSTRAR TODO', id: 'all' });
        } else {
          this.stores = stores.filter((sto: any) => {
            return sto.companyStores.map((admin) => admin.adminId).includes(admin.id);
          });
          this.stores.unshift({ name: 'Mostrar todas', id: 'all' });
        }
      });
    })
  }

  // getOrders(){
  //   this._orders.find({include: ['employee','company','certificate']}).subscribe((orders: any) => {
  //     orders.forEach(element => {
  //       element.companyName = element.company.name;
  //       element.employeeName = element.employee.name;
  //       element.employeeEmail = element.employee.email;
  //     });
  //     this.orders = orders;
  //     this.dataOrders = new MatTableDataSource(this.orders);
  //   })
  // }

  getGifts() {
    this._gifts.getGifts({
      include: [
        {
          relation: 'employee',
          scope: { include: { relation: 'company' } }
        },
        'store'
      ]
    }).subscribe((gifts: any) => {
      gifts.forEach(element => {
        /* if (element.certs && element.certs.length > 0) {
          element.categories = [];
          element.certs.forEach(Cert => {
            this._certificate.findById(Cert, { include: 'category' }).subscribe((cert: any) => {
              element.categories.push(cert.category.name)
            })
          });
        } */
        if (element.employee) {
          element.employeeName = element.employee.name;
          element.employeeEmail = element.employee.email;
          if (element.employee.company) {
            element.companyName = element.employee.company.name;
          }
        }
        element.storeName = element.store.name;
        element.createdAt = moment(element.createdAt).format('DD/MM/YYYY hh:mm a')
        if (!element.uploadedDate) { element.uploadedDate = "Sin asignar" }
        else { element.uploadedDate = moment(element.uploadedDate).format('DD/MM/YYYY hh:mm a') }

        if (element.status == 'available') { element.status = 'Entregado' }
        else if (element.status == 'expired') { element.status = 'Expirado' }
        else if (element.status == 'changed') { element.status == 'Usado' }
        else { element.status = 'Pendiente' }
        //element.status == 'available' ? 'Entregado' : element.status == 'expired' ? 'Expirado' : element.status == 'changed' ? 'Usado' : 'Pendiente'
      });

      this.orders = gifts.filter((gift) => this.companies.map((company) => company.id).includes(gift.companyId)).reverse();
      this.dataOrders = new MatTableDataSource(this.orders);
      this.dataOrders.sort = this.sort;
      setTimeout(() => this.dataOrders.paginator = this.paginator);
    })
  }

  getCertCategories() { //Funcion para traer las categorias de los certificados
    this._certCategory.find({ where: { isDeleted: false } }).subscribe(resp => {
      let ArregloNombres = [];
      let certs = resp.filter((cert: any) => { //Filtrado de certificados, unicamente los que pertenecen a las compañías activas
        return this.companies.map((com: any) => com.id).includes(cert.companyId);
      })
      certs.forEach((element: any) => { //Llenado de arreglo con los nombres
        ArregloNombres.push(element.name);
      });
      this.certificados = Array.from(new Set(ArregloNombres)); //Filtrado del arreglo de nombres, dejando sin repetidos para el select
    })
  }

  filterCert(event) {
    this.certtype = event.target.value;
    this.ordersFiltered = [];

    if (this.companyId != 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.employee) {
          if (order.status == this.status && order.employee.company.id == this.companyId && order.store.id == this.storeId && order.categories[0] == this.certtype) {
            this.ordersFiltered.push(order);
          }
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status && order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.store.id == this.storeId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.status == this.status && order.store.id == this.storeId && order.categories[0] == this.certtype)) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.status == this.status && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if (order.status == this.status) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.store.id == this.storeId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if (order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else {
      this.ordersFiltered = this.orders;
    }

    this.dataOrders = new MatTableDataSource(this.ordersFiltered);
    setTimeout(() => this.dataOrders.paginator = this.paginator);
  }

  selectCompany(event) {
    this.ordersFiltered = [];
    this.companyId = event.id;
    if (this.companyId != 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.employee) {
          if (order.status == this.status && order.employee.company.id == this.companyId && order.store.id == this.storeId && order.categories[0] == this.certtype) {
            this.ordersFiltered.push(order);
          }
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status && order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.store.id == this.storeId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.status == this.status && order.store.id == this.storeId && order.categories[0] == this.certtype)) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.status == this.status && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if (order.status == this.status) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.store.id == this.storeId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if (order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else {
      this.ordersFiltered = this.orders;
    }

    this.dataOrders = new MatTableDataSource(this.ordersFiltered);
    setTimeout(() => this.dataOrders.paginator = this.paginator);
  }

  selectStore(event) {
    this.ordersFiltered = [];
    this.storeId = event.id;

    if (this.companyId != 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.employee) {
          if (order.status == this.status && order.employee.company.id == this.companyId && order.store.id == this.storeId && order.categories[0] == this.certtype) {
            this.ordersFiltered.push(order);
          }
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status && order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.status == this.status) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.store.id == this.storeId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId != 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if ((order.employee && order.employee.company) && order.employee.company.id == this.companyId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if ((order.status == this.status && order.store.id == this.storeId && order.categories[0] == this.certtype)) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.status == this.status && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all' && this.storeId == 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if (order.status == this.status) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.store.id == this.storeId && order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId != 'all' && this.certtype == "all") {
      this.orders.forEach((order: any) => {
        if (order.store.id == this.storeId) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status == 'all' && this.storeId == 'all' && this.certtype != "all") {
      this.orders.forEach((order: any) => {
        if (order.categories[0] == this.certtype) {
          this.ordersFiltered.push(order);
        }
      });
    }

    else {
      this.ordersFiltered = this.orders;
    }

    this.dataOrders = new MatTableDataSource(this.ordersFiltered);
    setTimeout(() => this.dataOrders.paginator = this.paginator);
  }

  filterType(event) {
    this.status = event.target.value;
    return this.applyFilter(this.status == 'all' ? '' : this.status)
  }

  // create(){  // Generar pedidos de prueba hardcodeados
  //   this._orders.create({employeeId:9,companyId:7, status:'en revisión', createdAt: new Date(), amount: 3000}).subscribe(orden => {
  //     console.log(`La orden fue creada ${orden}`);
  //     this.getGifts();
  //   })
  // }

  applyFilter(filterValue: string) {
    this.dataOrders.filter = filterValue.trim().toLowerCase();

    if (this.dataOrders.paginator) {
      this.dataOrders.paginator.firstPage();
    }
  }

  OpenInfo(order) {
    const InforOrder = this.dialog.open(InfoPedidoComponent, {
      width: '50%',
      data: order
    });
  }

  OpenEntrega(order) {
    const EntregaOrder = this.dialog.open(EntregaRegalosComponent, {
      width: '50%',
      data: order
    });
    EntregaOrder.afterClosed().subscribe((result) => {
      if (result) {
        this.getGifts();
      }
    })
  }

  onDateSelection(date?: NgbDate) {
    if (
      !this.fromDate &&
      !this.toDate
    ) {
      this.fromDate = date;
      this.fromDateReport = date
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.toDateReport = date
      const start = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
      const end = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`

      let FechaAnt = momentTz(start).tz('Mexico/General').startOf('day')
      let FechaAct = momentTz(end).tz('Mexico/General').endOf('day')

      this.OrdenesPorFecha(FechaAnt, FechaAct);
    }
    else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      this.fromDate == date
    ) {
      const start = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
      const end = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`

      let FechaAnt = momentTz(start).tz('Mexico/General').startOf('day')
      let FechaAct = momentTz(end).tz('Mexico/General').endOf('day')

      this.OrdenesPorFecha(FechaAnt, FechaAct)
    }
    else if (
      this.fromDate &&
      this.toDate
    ) {
      const start = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
      const end = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`

      let FechaAnt = momentTz(start).tz('Mexico/General').startOf('day')
      let FechaAct = momentTz(end).tz('Mexico/General').endOf('day')

      this.OrdenesPorFecha(FechaAnt, FechaAct)
    }
    else {
      this.toDate = null;
      this.fromDate = date;
      this.toDateReport = null;
      this.fromDateReport = date;
    }

  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  OrdenesPorFecha(FechaAnterior: Moment, FechaActual: Moment) {
    this.searchFrom = FechaAnterior.format('LL')
    this.searchTo = FechaActual.format('LL')
    const from = FechaAnterior.toISOString()
    const to = FechaActual.toISOString()
    this.orders = []

    this._gifts.getGifts({ where: { createdAt: { between: [from, to] } }, include: [{ relation: 'employee', scope: { include: { relation: 'company' } } }, 'store'] }).subscribe((gifts: Gifts[]) => {
      let tableData = [];
      gifts.forEach(gift => {
        let element: any = { ...gift };
        /* if (gift.certs && gift.certs.length > 0) {
          element.categories = [];
          gift.certs.forEach(Cert => {
            this._certificate.findById(Cert, { include: 'category' }).subscribe((cert: any) => {
              element.categories.push(cert.category.name)
            })
          });
        } */
        if (gift.employee) {
          element.employeeName = gift.employee.name;
          element.employeeEmail = gift.employee.email;
          if (gift.employee.company) {
            element.companyName = gift.employee.company.name;
          }
        }
        element.storeName = gift.store.name;
        element.createdAt = moment(gift.createdAt).format('DD/MM/YYYY hh:mm a')
        if (!gift.uploadedDate) { element.uploadedDate = "Sin asignar" }
        else { element.uploadedDate = moment(gift.uploadedDate).format('DD/MM/YYYY hh:mm a') }

        element.status =
          gift.status
            && gift.downloadHistory
            && gift.downloadHistory.length
            ? 'Descargado'
            : gift.status != null
              && statusType[gift.status]
              ? statusType[gift.status]
              : statusType.pending

        tableData = tableData.concat(element)
      });

      this.orders = tableData.filter((gift) => this.companies.map((company) => company.id).includes(gift.companyId));
      this.dataOrders.data = this.orders;
      this.dataOrders.sort = this.sort;
      setTimeout(() => this.dataOrders.paginator = this.paginator);
      this.fromDate = null
      this.toDate = null
    })
  }

  descargarReporteFiltrado() {
    const orders = (this.companyId != 'all' || this.status != 'all' || this.storeId != 'all' || this.certtype != 'all') ?
      this.ordersFiltered :
      this.orders;
    let fechaAnterior = moment(this.fromDateReport).subtract(1, 'month');
    let fechaActual = moment(this.toDateReport).subtract(1, 'month');
    let data: any = [
      {
        title: 'Reporte Filtrado de pedidos . Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'),
        fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY hh:mm a')
      },
      {
        fecha: 'FECHA DEL PEDIDO',
        name: 'NOMBRE',
        //campus: 'CAMPUS',
        empresa: 'EMPRESA',
        //email: 'EMAIL',
        //status: 'ESTADO DEL PEDIDO',
        comercio: 'COMERCIO',
        //entrega: 'FECHA DE ENTREGA',
        certificado: 'CERTIFICADO',
        value: 'MONTO'
        /* infoticket: 'REGALO DIGITAL',
         shippinginfo: 'REGALO FÍSICO',
         calle: 'CALLE',
         numero: 'NÚMERO',
         colonia: 'COLONIA',
         cp: 'CP',
         ciudad: 'CIUDAD',
         estado: 'ESTADO',
         telefono: 'N. TELEFÓNICO',
         referencias: 'REFERENCIAS'*/
      }
    ];


    orders.forEach(element => {
      const campus = this.campuses.find((camp: any) => camp.id == element.employee.campusId)
      let tempValue = 0;
      for (let index = 0; index < element.certificateDetail.length; index++) {
        const detail = element.certificateDetail[index];
        const last = index == element.certificateDetail.length - 1;
        let lineValue = last ? element.value - tempValue : detail.amount;
        tempValue += detail.amount;
        let order = {
          fecha: element.createdAt,
          name: element.employeeName,
          //campusId: campus.name,
          empresa: element.companyName,
          //email: element.employeeEmail,
          //status: element.status,
          comercio: element.store.name,
          //entrega: element.updatedAt ? moment(element.updatedAt).format('DD/MM/YYYY hh:mm a') : 'Sin asignar',
          certificado: element.categories[index],
          value: lineValue
          /*infoticket: element.ticketInfo ? element.ticketInfo.name + ' ' + element.ticketInfo.fLastName + ' ' + element.ticketInfo.mLastName + ',' + ' ' + element.ticketInfo.city + ';' + ' ' + element.employee.country + ',' + ' ' + 'Teléfono: ' + element.ticketInfo.phone : '-',
          shippinginfo: element.shippingInfo ? 'Si' : '-',
          calle: element.shippingInfo ? element.shippingInfo.street : '-',
          numero: element.shippingInfo ? element.shippingInfo.number : '-',
          colonia: element.shippingInfo ? element.shippingInfo.settlement : '-',
          cp: element.shippingInfo ? element.shippingInfo.zipCode : '-',
          ciudad: element.shippingInfo ? element.shippingInfo.city : '-',
          estado: element.shippingInfo ? element.shippingInfo.state : '-',
          telefono: element.shippingInfo ? element.shippingInfo.phone : '-',
          referencias: element.shippingInfo ? element.shippingInfo.schedule : '-'*/
        }

        data.push(order);
      }
    });
    const archivo = new Angular5Csv(data, 'Reporte Filtrado de pedidos. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'));
  }


  descargarReporte() {

    if (this.companyId != 'all' || this.status != 'all' || this.storeId != 'all' || this.certtype != 'all') {
      if (this.fromDateReport && this.toDateReport) {
        let fechaAnterior = moment(this.fromDateReport).subtract(1, 'month');
        let fechaActual = moment(this.toDateReport).subtract(1, 'month');
        let data: any = [
          {
            title: 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'),
            fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY hh:mm a')
          },
          {
            fecha: 'FECHA DEL PEDIDO',
            name: 'NOMBRE',
            campus: 'CAMPUS',
            empresa: 'EMPRESA',
            email: 'EMAIL',
            status: 'ESTADO DEL PEDIDO',
            comercio: 'COMERCIO',
            entrega: 'FECHA DE ENTREGA',
            certificado: 'CERTIFICADO',
            value: 'MONTO',
            infoticket: 'REGALO DIGITAL',
            shippinginfo: 'REGALO FÍSICO',
            calle: 'CALLE',
            numero: 'NÚMERO',
            colonia: 'COLONIA',
            cp: 'CP',
            ciudad: 'CIUDAD',
            estado: 'ESTADO',
            telefono: 'N. TELEFÓNICO',
            referencias: 'REFERENCIAS'
          }
        ];
        this.ordersFiltered.forEach(element => {
          const campus = this.campuses.find((camp: any) => camp.id == element.employee.campusId)

          let order = {
            fecha: element.createdAt,
            name: element.employeeName,
            campusId: campus.name,
            empresa: element.companyName,
            email: element.employeeEmail,
            status: element.status,
            comercio: element.store.name,
            entrega: element.updatedAt ? moment(element.updatedAt).format('DD/MM/YYYY hh:mm a') : 'Sin asignar',
            certificado: element.categories[0],
            value: element.value,
            infoticket: element.ticketInfo ? element.ticketInfo.name + ' ' + element.ticketInfo.fLastName + ' ' + element.ticketInfo.mLastName + ',' + ' ' + element.ticketInfo.city + ';' + ' ' + element.employee.country + ',' + ' ' + 'Teléfono: ' + element.ticketInfo.phone : '-',
            shippinginfo: element.shippingInfo ? 'Si' : '-',
            calle: element.shippingInfo ? element.shippingInfo.street : '-',
            numero: element.shippingInfo ? element.shippingInfo.number : '-',
            colonia: element.shippingInfo ? element.shippingInfo.settlement : '-',
            cp: element.shippingInfo ? element.shippingInfo.zipCode : '-',
            ciudad: element.shippingInfo ? element.shippingInfo.city : '-',
            estado: element.shippingInfo ? element.shippingInfo.state : '-',
            telefono: element.shippingInfo ? element.shippingInfo.phone : '-',
            referencias: element.shippingInfo ? element.shippingInfo.schedule : '-'
          }
          data.push(order);
        });
        const archivo = new Angular5Csv(data, 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'));
      }
      else {
        if (this.fromDateReport && this.toDateReport) {
          let fechaAnterior = moment(this.fromDateReport).subtract(1, 'month');
          let fechaActual = moment(this.toDateReport).subtract(1, 'month');
          let data: any = [
            {
              title: 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'),
              fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY hh:mm a')
            },
            {
              fecha: 'FECHA DEL PEDIDO',
              name: 'NOMBRE',
              campus: 'CAMPUS',
              empresa: 'EMPRESA',
              email: 'EMAIL',
              status: 'ESTADO DEL PEDIDO',
              comercio: 'COMERCIO',
              entrega: 'FECHA DE ENTREGA',
              certificado: 'CERTIFICADO',
              value: 'MONTO',
              infoticket: 'REGALO DIGITAL',
              shippinginfo: 'REGALO FÍSICO',
              calle: 'CALLE',
              numero: 'NÚMERO',
              colonia: 'COLONIA',
              cp: 'CP',
              ciudad: 'CIUDAD',
              estado: 'ESTADO',
              telefono: 'N. TELEFÓNICO',
              referencias: 'REFERENCIAS'
            }
          ];
          this.ordersFiltered.forEach(element => {
            const campus = this.campuses.find((camp: any) => camp.id == element.employee.campusId)

            let order = {
              fecha: element.createdAt,
              name: element.employeeName,
              campusId: campus.name,
              empresa: element.companyName,
              email: element.employeeEmail,
              status: element.status,
              comercio: element.store.name,
              entrega: element.updatedAt ? moment(element.updatedAt).format('DD/MM/YYYY hh:mm a') : 'Sin asignar',
              certificado: element.categories[0],
              value: element.value,
              infoticket: element.ticketInfo ? element.ticketInfo.name + ' ' + element.ticketInfo.fLastName + ' ' + element.ticketInfo.mLastName + ',' + ' ' + element.ticketInfo.city + ';' + ' ' + element.employee.country + ',' + ' ' + 'Teléfono: ' + element.ticketInfo.phone : '-',
              shippinginfo: element.shippingInfo ? 'Si' : '-',
              calle: element.shippingInfo ? element.shippingInfo.street : '-',
              numero: element.shippingInfo ? element.shippingInfo.number : '-',
              colonia: element.shippingInfo ? element.shippingInfo.settlement : '-',
              cp: element.shippingInfo ? element.shippingInfo.zipCode : '-',
              ciudad: element.shippingInfo ? element.shippingInfo.city : '-',
              estado: element.shippingInfo ? element.shippingInfo.state : '-',
              telefono: element.shippingInfo ? element.shippingInfo.phone : '-',
              referencias: element.shippingInfo ? element.shippingInfo.schedule : '-'
            }
            data.push(order);
          });
          const archivo = new Angular5Csv(data, 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'));
        }
      }
    }
    else {
      if (this.fromDateReport && this.toDateReport) {
        let fechaAnterior = moment(this.fromDateReport).subtract(1, 'month');
        let fechaActual = moment(this.toDateReport).subtract(1, 'month');
        let data: any = [
          {
            title: 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'),
            fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY hh:mm a')
          },
          {
            fecha: 'FECHA DEL PEDIDO',
            name: 'NOMBRE',
            campus: 'CAMPUS',
            empresa: 'EMPRESA',
            email: 'EMAIL',
            status: 'ESTADO DEL PEDIDO',
            comercio: 'COMERCIO',
            entrega: 'FECHA DE ENTREGA',
            certificado: 'CERTIFICADO',
            value: 'MONTO',
            infoticket: 'REGALO DIGITAL',
            shippinginfo: 'REGALO FÍSICO',
            calle: 'CALLE',
            numero: 'NÚMERO',
            colonia: 'COLONIA',
            cp: 'CP',
            ciudad: 'CIUDAD',
            estado: 'ESTADO',
            telefono: 'N. TELEFÓNICO',
            referencias: 'REFERENCIAS'
          }
        ];
        this.orders.forEach(element => {
          const campus = this.campuses.find((camp: any) => camp.id == element.employee.campusId)

          let order = {
            fecha: element.createdAt,
            name: element.employeeName,
            campusId: campus.name,
            empresa: element.companyName,
            email: element.employeeEmail,
            status: element.status,
            comercio: element.store.name,
            entrega: element.updatedAt ? moment(element.updatedAt).format('DD/MM/YYYY hh:mm a ') : 'Sin asignar',
            certificado: element.categories[0],
            value: element.value,
            infoticket: element.ticketInfo ? element.ticketInfo.name + ' ' + element.ticketInfo.fLastName + ' ' + element.ticketInfo.mLastName + ',' + ' ' + element.ticketInfo.city + ';' + ' ' + element.employee.country + ',' + ' ' + 'Teléfono: ' + element.ticketInfo.phone : '-',
            shippinginfo: element.shippingInfo ? 'Si' : '-',
            calle: element.shippingInfo ? element.shippingInfo.street : '-',
            numero: element.shippingInfo ? element.shippingInfo.number : '-',
            colonia: element.shippingInfo ? element.shippingInfo.settlement : '-',
            cp: element.shippingInfo ? element.shippingInfo.zipCode : '-',
            ciudad: element.shippingInfo ? element.shippingInfo.city : '-',
            estado: element.shippingInfo ? element.shippingInfo.state : '-',
            telefono: element.shippingInfo ? element.shippingInfo.phone : '-',
            referencias: element.shippingInfo ? element.shippingInfo.schedule : '-'
          }
          data.push(order);
        });
        const archivo = new Angular5Csv(data, 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'));
      }
      else {
        if (this.fromDateReport && this.toDateReport) {
          let fechaAnterior = moment(this.fromDateReport).subtract(1, 'month');
          let fechaActual = moment(this.toDateReport).subtract(1, 'month');
          let data: any = [
            {
              title: 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'),
              fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY hh:mm a')
            },
            {
              fecha: 'FECHA DEL PEDIDO',
              name: 'NOMBRE',
              campus: 'CAMPUS',
              empresa: 'EMPRESA',
              email: 'EMAIL',
              status: 'ESTADO DEL PEDIDO',
              comercio: 'COMERCIO',
              entrega: 'FECHA DE ENTREGA',
              certificado: 'CERTIFICADO',
              value: 'MONTO',
              infoticket: 'REGALO DIGITAL',
              shippinginfo: 'REGALO FÍSICO',
              calle: 'CALLE',
              numero: 'NÚMERO',
              colonia: 'COLONIA',
              cp: 'CP',
              ciudad: 'CIUDAD',
              estado: 'ESTADO',
              telefono: 'N. TELEFÓNICO',
              referencias: 'REFERENCIAS'
            }
          ];
          this.orders.forEach(element => {
            const campus = this.campuses.find((camp: any) => camp.id == element.employee.campusId)

            let order = {
              fecha: element.createdAt,
              name: element.employeeName,
              campusId: campus.name,
              empresa: element.companyName,
              email: element.employeeEmail,
              status: element.status,
              comercio: element.store.name,
              entrega: element.updatedAt ? moment(element.updatedAt).format('DD/MM/YYYY hh:mm a') : 'Sin asignar',
              certificado: element.categories[0],
              value: element.value,
              infoticket: element.ticketInfo ? element.ticketInfo.name + ' ' + element.ticketInfo.fLastName + ' ' + element.ticketInfo.mLastName + ',' + ' ' + element.ticketInfo.city + ';' + ' ' + element.employee.country + ',' + ' ' + 'Teléfono: ' + element.ticketInfo.phone : '-',
              shippinginfo: element.shippingInfo ? 'Si' : '-',
              calle: element.shippingInfo ? element.shippingInfo.street : '-',
              numero: element.shippingInfo ? element.shippingInfo.number : '-',
              colonia: element.shippingInfo ? element.shippingInfo.settlement : '-',
              cp: element.shippingInfo ? element.shippingInfo.zipCode : '-',
              ciudad: element.shippingInfo ? element.shippingInfo.city : '-',
              estado: element.shippingInfo ? element.shippingInfo.state : '-',
              telefono: element.shippingInfo ? element.shippingInfo.phone : '-',
              referencias: element.shippingInfo ? element.shippingInfo.schedule : '-'
            }
            data.push(order);
          });
          const archivo = new Angular5Csv(data, 'Reporte de pedidos de colaboradores. Del ' + fechaAnterior.format('DD/MM/YYYY') + ' al ' + fechaActual.format('DD/MM/YYYY'));
        }
      }
    }
  }
}


