/* tslint:disable */
import {
  Company,
  User
} from '../index';

declare var Object: any;
export interface Admin_companyInterface {
  "adminId"?: number;
  "companyId"?: number;
  "id"?: number;
  company?: Company;
  admin?: User;
}

export class Admin_company implements Admin_companyInterface {
  "adminId": number;
  "companyId": number;
  "id": number;
  company: Company;
  admin: User;
  constructor(data?: Admin_companyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Admin_company`.
   */
  public static getModelName() {
    return "Admin_company";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Admin_company for dynamic purposes.
  **/
  public static factory(data: Admin_companyInterface): Admin_company{
    return new Admin_company(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Admin_company',
      plural: 'Admin_companies',
      path: 'Admin_companies',
      idName: 'id',
      properties: {
        "adminId": {
          name: 'adminId',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        admin: {
          name: 'admin',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'adminId',
          keyTo: 'id'
        },
      }
    }
  }
}
