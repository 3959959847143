/* tslint:disable */
import {
  Company,
  BalanceHistory,
  CertificateDesign,
  User
} from '../index';

declare var Object: any;
export interface CertificateCategoryInterface {
  "name"?: string;
  "companyId"?: number;
  "createdAt"?: Date;
  "isDeleted"?: boolean;
  "isValidated"?: boolean;
  "images"?: Array<any>;
  "description"?: string;
  "dateDefault"?: string;
  "active"?: boolean;
  "designs"?: Array<any>;
  "balance"?: number;
  "id"?: number;
  company?: Company;
  balanceHistories?: BalanceHistory[];
  certificateDesigns?: CertificateDesign[];
  admins?: User[];
}

export class CertificateCategory implements CertificateCategoryInterface {
  "name": string;
  "companyId": number;
  "createdAt": Date;
  "isDeleted": boolean;
  "isValidated": boolean;
  "images": Array<any>;
  "description": string;
  "dateDefault": string;
  "active": boolean;
  "designs": Array<any>;
  "balance": number;
  "id": number;
  company: Company;
  balanceHistories: BalanceHistory[];
  certificateDesigns: CertificateDesign[];
  admins: User[];
  constructor(data?: CertificateCategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CertificateCategory`.
   */
  public static getModelName() {
    return "CertificateCategory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CertificateCategory for dynamic purposes.
  **/
  public static factory(data: CertificateCategoryInterface): CertificateCategory{
    return new CertificateCategory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CertificateCategory',
      plural: 'CertificateCategories',
      path: 'CertificateCategories',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "isValidated": {
          name: 'isValidated',
          type: 'boolean'
        },
        "images": {
          name: 'images',
          type: 'Array&lt;any&gt;'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "dateDefault": {
          name: 'dateDefault',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "designs": {
          name: 'designs',
          type: 'Array&lt;any&gt;'
        },
        "balance": {
          name: 'balance',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        balanceHistories: {
          name: 'balanceHistories',
          type: 'BalanceHistory[]',
          model: 'BalanceHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        certificateDesigns: {
          name: 'certificateDesigns',
          type: 'CertificateDesign[]',
          model: 'CertificateDesign',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'categoryId'
        },
        admins: {
          name: 'admins',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
          modelThrough: 'User_categories',
          keyThrough: 'adminId',
          keyFrom: 'id',
          keyTo: 'categoryId'
        },
      }
    }
  }
}
