import { Component } from '@angular/core';
import { LoopBackConfig } from "./services/shared/sdk";
import { LOCAL_BASE_URL, BASE_URL, API_VERSION } from './services/base-url';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Giftpedia-Admin';

  constructor() {
    if (window.location.hostname === 'localhost') {
      LoopBackConfig.setBaseURL(LOCAL_BASE_URL);
    } else {
      LoopBackConfig.setBaseURL(BASE_URL);
    }

    LoopBackConfig.setApiVersion(API_VERSION);
  }
}


