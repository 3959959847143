import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-reenviar-invitacion',
  templateUrl: './reenviar-invitacion.component.html',
  styleUrls: ['./reenviar-invitacion.component.scss']
})
export class ReenviarInvitacionComponent implements OnInit {

  constructor(
    public dialogRef : MatDialogRef<ReenviarInvitacionComponent>,
    private _user: UserApi,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  send(){
    this._user.resendInvitation({userId: this.data.id}).subscribe(resp => {
      this.snackBar.open('Invitación enviada', 'Listo!', {
        duration: 6000
      });
      this.dialogRef.close(resp);
    }, error => {
      console.error("Ocurrió un error inesperado", error);
      this.snackBar.open('ERROR AL INTENTAR ENVIAR', 'ERROR', {
        duration: 6000
      });
    })
  }

}
