import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, CertificateCategoryApi } from '../../../services/shared/sdk';


@Component({
  selector: 'app-nueva-categoria',
  templateUrl: './nueva-categoria.component.html',
  styleUrls: ['./nueva-categoria.component.scss']
})
export class NuevaCategoriaComponent implements OnInit {
  form: FormGroup;
  category: any = {
    name: '',
    date: new Date(),
    isValidated: true,
    active: true    
  };

  defaults = [
    'Cumpleaños',
    'Cumpleaños de pareja',
    'Cumpleaños de hijo',
    'Aniversario de pareja',
    'Antigüedad en la empresa',
    'N/A'
  ];

  designs =  [
    {name: 'Agradecimiento', selected: false},
    {name: 'Celebración', selected: false},
    {name: 'Cumpleaños',selected: false},
    {name: 'Día de las madres',selected: false},
    {name: 'Día del padre',selected: false},
    {name: 'Felicidades',selected: false},
    {name: 'Halloween',selected: false},
    {name: 'Hijos',selected: false},
    {name: 'Navidad',selected: false},
    {name: 'Pareja',selected: false},
    {name: 'Pascua',selected: false},
    {name: 'Trabajo',selected: false}
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NuevaCategoriaComponent>,
    private formBuilder: FormBuilder,
    private _user: UserApi,
    private _category: CertificateCategoryApi
  ) { 
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      date: [''],
      dateDefault: [''],
      desings: ['']
    });

    this.category.companyId = this.data.companyId;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createCategory() {    
    this.category.designs = this.designs.filter((des) => {
      return des.selected
    }).map((des) => {
      return des.name
    });
    this._category.create(this.category).subscribe((newCategory) => {
      console.log(newCategory);
      this.dialogRef.close(newCategory);
    });
  }
}
