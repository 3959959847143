import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { EventApi, CompanyApi, CampusApi } from '../../../services/shared/sdk';

@Component({
  selector: 'app-nuevo-evento',
  templateUrl: './nuevo-evento.component.html',
  styleUrls: ['./nuevo-evento.component.scss']
})
export class NuevoEventoComponent implements OnInit {
  form: FormGroup;
  companies = [];
  campuses = [];
  allCampuses = [];
  event = {
    companyId:0
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NuevoEventoComponent>,
    private formBuilder: FormBuilder,
    private _campus: CampusApi,
    private _company: CompanyApi,
    private _event: EventApi
  ) {
    console.log(data);
    this.getCompany();
    this.form = this.formBuilder.group({
      companyId: ['', Validators.required],
      date: ['', Validators.required],
      place: ['', Validators.required],
      campusId: ['', Validators.required],
      startTime: ['', Validators.required]
    });
   }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  getCompany(){
    this._company.find({where: { isDeleted: false}}).subscribe(companies => {
      this.companies = companies;
    }) ;
  }

  getCampus(id){
    this._campus.find({
      where: {
        companyId: id
      }
    }).subscribe((campus) => {      
      this.campuses = campus;
      console.log(campus);
      
    });
  }

  onChange(event){
    console.log(event);
    this.getCampus(event)
  }

  createEvent() {

    this._event.create(this.event).subscribe((event) => {      
      this.dialogRef.close(event);
    });
  }

}
