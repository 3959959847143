import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, CertificateCategoryApi } from '../../../services/shared/sdk';

@Component({
  selector: 'app-editar-categoria',
  templateUrl: './editar-categoria.component.html',
  styleUrls: ['./editar-categoria.component.scss']
})
export class EditarCategoriaComponent implements OnInit {
  form: FormGroup;
  category: any = {
    name: '',
    date: new Date(),
    isValidated: true,
    active: true    
  };

  defaults = [
    'Cumpleaños',
    'Cumpleaños de pareja',
    'Cumpleaños de hijo',
    'Aniversario de pareja',
    'Antigüedad en la empresa',
    'N/A'
  ];

  designs = [];
  designs2 = [];

 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditarCategoriaComponent>,
    private formBuilder: FormBuilder,
    private _user: UserApi,
    private snackBar: MatSnackBar,
    private _category: CertificateCategoryApi
  ) { 
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      date: [''],
      dateDefault: [''],
      desings: ['']
    });

    this.designs =  [
      {name: 'Agradecimiento'},
      {name: 'Celebración'},
      {name: 'Cumpleaños'},
      {name: 'Día de las madres'},
      {name: 'Día del padre'},
      {name: 'Felicidades'},
      {name: 'Halloween'},
      {name: 'Hijos'},
      {name: 'Navidad'},
      {name: 'Pareja'},
      {name: 'Pascua'},
      {name: 'Trabajo'}
    ]

    this.designs.forEach((des) => {
      des.selected =  false;
      if(this.data.categoria.designs){
        this.data.categoria.designs.forEach(element => {
          if (des.name == element) {
            des.selected = true;
          }
        });
      }
      
    });
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveCategory() {
    this.designs.forEach(des => {
      if(des.selected == true){
        this.designs2.push(des.name);
      }
    });
    this.data.categoria.designs = this.designs2;
    this._category.patchAttributes(this.data.categoria.id, this.data.categoria).subscribe((resp) => {
      this.snackBar.open('Categoría editada correctamente', 'Listo!', {
        duration: 5000
   });
   this.dialogRef.close(resp);
    },error => {
      this.snackBar.open('Ocurrió un error', error, {
        duration: 5000
   });
   this.dialogRef.close();
    })
  }

}