/* tslint:disable */
import {
  Company,
  User,
  Group,
  CertificateCategory
} from '../index';

declare var Object: any;
export interface CertScheduleInterface {
  "userId"?: number;
  "groupId"?: number;
  "img"?: string;
  "date"?: Date;
  "value"?: number;
  "certificateCategoryId"?: number;
  "description"?: string;
  "companyId"?: number;
  "orderId"?: string;
  "status"?: string;
  "history"?: Array<any>;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "expirationDate"?: Date;
  "info"?: Array<any>;
  "rest"?: Array<any>;
  "color"?: string;
  "diploma"?: string;
  "design"?: Array<any>;
  "messagecert"?: string;
  "id"?: number;
  company?: Company;
  user?: User;
  group?: Group;
  category?: CertificateCategory;
}

export class CertSchedule implements CertScheduleInterface {
  "userId": number;
  "groupId": number;
  "img": string;
  "date": Date;
  "value": number;
  "certificateCategoryId": number;
  "description": string;
  "companyId": number;
  "orderId": string;
  "status": string;
  "history": Array<any>;
  "createdAt": Date;
  "updatedAt": Date;
  "expirationDate": Date;
  "info": Array<any>;
  "rest": Array<any>;
  "color": string;
  "diploma": string;
  "design": Array<any>;
  "messagecert": string;
  "id": number;
  company: Company;
  user: User;
  group: Group;
  category: CertificateCategory;
  constructor(data?: CertScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CertSchedule`.
   */
  public static getModelName() {
    return "CertSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CertSchedule for dynamic purposes.
  **/
  public static factory(data: CertScheduleInterface): CertSchedule{
    return new CertSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CertSchedule',
      plural: 'CertSchedules',
      path: 'CertSchedules',
      idName: 'id',
      properties: {
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
        "img": {
          name: 'img',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "value": {
          name: 'value',
          type: 'number'
        },
        "certificateCategoryId": {
          name: 'certificateCategoryId',
          type: 'number'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'accepted'
        },
        "history": {
          name: 'history',
          type: 'Array&lt;any&gt;'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "info": {
          name: 'info',
          type: 'Array&lt;any&gt;'
        },
        "rest": {
          name: 'rest',
          type: 'Array&lt;any&gt;'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "diploma": {
          name: 'diploma',
          type: 'string'
        },
        "design": {
          name: 'design',
          type: 'Array&lt;any&gt;'
        },
        "messagecert": {
          name: 'messagecert',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'groupId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'CertificateCategory',
          model: 'CertificateCategory',
          relationType: 'belongsTo',
                  keyFrom: 'certificateCategoryId',
          keyTo: 'id'
        },
      }
    }
  }
}
