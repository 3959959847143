import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyApi, OrderCompanyApi, CertScheduleApi, GroupApi, UserApi } from '../../../services/shared/sdk';
import {MatTableDataSource, MatDialog, MatSort, MatPaginator} from '@angular/material';
import { InfoPedidoEmpresaComponent } from '../info-pedido-empresa/info-pedido-empresa.component';
import { EvaluacionPedidoComponent } from '../evaluacion-pedido/evaluacion-pedido.component';
@Component({
  selector: 'app-lista-pedidos-empresas',
  templateUrl: './lista-pedidos-empresas.component.html',
  styleUrls: ['./lista-pedidos-empresas.component.scss']
})
export class ListaPedidosEmpresasComponent implements OnInit {

  companies;
  status = 'all';
  companyId = 'all';
  displayedColumns: string[] = ['createdAt','id','companyName','ordeN','totaL','status','actions'];
  orders;
  ordersFiltered;
  ordersCompany;
  dataOrders;
  dropdownSettings = {};
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('singlePaginator') paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private _company: CompanyApi,
    private _orders: OrderCompanyApi,
    private _schedules: CertScheduleApi,
    private _user: UserApi,
    private _group: GroupApi
  ) { }

  ngOnInit() {
    this.getCompanies();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 25,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar empresa',
      closeDropDownOnSelection: true
    };
    
  }

  getCompanies(){
    this._company.find({where : {isDeleted: false}, include: 'adminCompanies'}).subscribe((companies) => {
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.companies = companies;
          this.companies.unshift({name:'MOSTRAR TODO',id:'all'});
        } else {
          this.companies = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id);
          });
          this.companies.unshift({name:'MOSTRAR TODO',id:'all'});
        }
        this.getSchedules();
      });
    })
  }

  getSchedules(){

    this._schedules.find({include: ['company','user','group','category']}).subscribe((schedules: any) => {
      schedules.forEach((element: any) => {
        if(element.company){
          element.companyName = element.company.name;
        }        
        if (element.group){
          this._group.findById(element.group.id, {include: {relation:'users'}}).subscribe((data:any) => {
            if(data.users && data.users.length){
              element.group.users = data.users;
              element.ordeN = data.users.length + " certificados";
              element.totaL = element.value * data.users.length;
            }
          })
        }
        else {
          element.ordeN = "1 certificado";
          element.totaL = element.value;
        }

      });
      this.orders = schedules.filter((schedule) => this.companies.map((company) => company.id).includes(schedule.companyId)).reverse();
      this.dataOrders = new MatTableDataSource(this.orders);
      this.dataOrders.sort = this.sort;
      setTimeout(() => this.dataOrders.paginator = this.paginator);
    })
  }

  filterType(event){
    this.status = event.target.value;
    this.ordersFiltered = [];
    if(this.companyId != 'all' && this.status != 'all'){
      this.orders.forEach((order:any) => {
        if(order.status == this.status && order.companyId == this.companyId){
          this.ordersFiltered.push(order);
        }
      });
    }

    else if (this.companyId == 'all' && this.status != 'all') {
      this.orders.forEach((order:any) => {
        if(order.status == this.status){
          this.ordersFiltered.push(order);
        }
      });
    }

    else if(this.companyId != 'all' && this.status == 'all'){
      this.orders.forEach((order:any) => {
        if(order.companyId == this.companyId){
          this.ordersFiltered.push(order);
        }
      });
    }

    else {
      this.ordersFiltered = this.orders;
    }
    this.dataOrders = new MatTableDataSource(this.ordersFiltered);
    setTimeout(() => this.dataOrders.paginator = this.paginator);
  }

  selectCompany(event){
    this.ordersCompany = [];
    this.companyId = event.id;
    if(this.companyId != 'all' && this.status != 'all'){
      this.orders.forEach((order:any) => {
        if(order.status == this.status && order.companyId == this.companyId){
          this.ordersCompany.push(order);
        }
      });
    }
    else if(this.companyId != 'all' && this.status == 'all') {
      this.orders.forEach((order:any) => {
        if(order.companyId == this.companyId){
          this.ordersCompany.push(order);
        }
      });
    }

    else if(this.companyId == 'all' && this.status != 'all'){
      this.orders.forEach((order:any) => {
        if(order.status == this.status){
          this.ordersCompany.push(order);
        }
      });
    }

    else {
      this.ordersCompany = this.orders;
    }
    this.dataOrders = new MatTableDataSource(this.ordersCompany);
    setTimeout(() => this.dataOrders.paginator = this.paginator);
  }

  // selectStatus(event){
  //   this.status = event.target.value;
  //   if(this.companyId){
  //     this._orders.find({where: {and: [{companyId: this.companyId},{status: this.status}]}}).subscribe((orders) => {
  //     this.orders = orders;
  //     this.dataOrders = new MatTableDataSource(this.orders);
  //     });
  //   }
  //   else {
  //       this._orders.find({where: {status: this.status}}).subscribe((orders) => {
  //       this.orders = orders;
  //       this.dataOrders = new MatTableDataSource(this.orders);
  //     });
  //   }
  // }

  applyFilter(filterValue: string) {
    this.dataOrders.filter = filterValue.trim().toLowerCase();
    if (this.dataOrders.paginator) {
      this.dataOrders.paginator.firstPage();
    }
  }

  create(){
    this._schedules.create({userId: 1, date: new Date(), value: 500, description: "Prueba de creación de schedule", companyId: 1}).subscribe(resp => {
      console.log(`Se creo correctamente el schedule ${resp}`);
      this.getSchedules();
    })
  }

  OpenInfo(order){
    const InforOrder = this.dialog.open(InfoPedidoEmpresaComponent, {
      width: '50%',
      data: order
    });
  }

  OpenEval(order){
    const EvalOrder = this.dialog.open(EvaluacionPedidoComponent, {
      width: '50%',
      data: order
    });
    EvalOrder.afterClosed().subscribe((result)=>{
      if(result){
        console.log('The dialog was closed');
        this.getSchedules();
      }
    })
  }

}
