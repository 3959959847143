import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CertTemplateApi } from '../../../services/shared/sdk';

@Component({
  selector: 'app-delete-frase',
  templateUrl: './delete-frase.component.html',
  styleUrls: ['./delete-frase.component.scss']
})
export class DeleteFraseComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteFraseComponent>,
    private _snackBar: MatSnackBar,
    private _template: CertTemplateApi
  ) { }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  delete() {
    this._template.deleteById(this.data.frase.id).subscribe(() => {
      this._snackBar.open('Plantilla eliminada', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(this.data);
    });
  }
}
