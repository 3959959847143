import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserApi } from '../../services/shared/sdk';
import { MatSnackBar } from "@angular/material";
import {MatDialog} from '@angular/material/dialog';
import { ForgotPasswordComponent } from './modal-forgot/forgot-password/forgot-password.component';

@Component({
  selector: 'app-loginv2',
  templateUrl: './loginv2.component.html',
  styleUrls: ['./loginv2.component.scss']
})
export class Loginv2Component implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    rememberme: new FormControl(false)
  });

  constructor(private router: Router, private _user: UserApi, public snackBar: MatSnackBar,public dialog: MatDialog) { }

  ngOnInit() {
  }

  login() {
   this._user.login(this.loginForm.value).subscribe((resp: any) => {
     if(resp.user.type == 'admin'){
      this.router.navigate(['/inicio']);
       if (this.loginForm.value.rememberme) {
        localStorage.setItem('token', resp.id);
        localStorage.setItem('userId', resp.userId);
      } else {
        sessionStorage.setItem('token', resp.id);
        sessionStorage.setItem('userId', resp.userId);
      }
     }
     else{
      this._user.logout();
      this.router.navigate(['loginv2']);
      this.snackBar.open('¡No tienes permiso para acceder!', 'Error!', {
        duration: 3000
      });
     }
   }, (err: any) => {
     this.processError(err);
   });
 }

 processError(error) {
  let message;
  switch (error.code) {
    case 'LOGIN_FAILED':
      message = 'Correo electrónico o contraseña incorrecta.';
      break;
    case 'AUTHORIZATION_REQUIRED':
      message = 'No cuentas con permisos para acceder a esta información.';
      break;
    case 'INVALID_PASSWORD':
      message = 'La contraseña actual es incorrecta.';
      break;
    default:
      message = error.name || 'Error';
      console.error(error.service + ':', error);
  }
  this.snackBar.open(message, 'ERROR', { duration: 4000 });
}

openModalPass(): void {
  const modalForgot = this.dialog.open(ForgotPasswordComponent, {
    width: '40%'
  });
}

}
