import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, CertificateDesignApi, CompanyApi, LoopBackAuth } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { UUID } from 'angular2-uuid';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-nuevo-diseno',
  templateUrl: './nuevo-diseno.component.html',
  styleUrls: ['./nuevo-diseno.component.scss']
})
export class NuevoDisenoComponent implements OnInit {
  form: FormGroup;
  dropdownSettings = {};
  categories = [];
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });
  image: any;
  design = {
    image: '',
    designs: []
  }
  defaults = [
    {name:'Cumpleaños', id: 'Cumpleaños'},
    {name:'Cumpleaños de pareja', id: 'Cumpleaños de pareja'},
    {name: 'Cumpleaños de hijo', id: 'Cumpleaños de hijo'},
    {name: 'Aniversario de pareja', id: 'Aniversario de pareja'},
    {name: 'Antigüedad en la empresa', id: 'Antigüedad en la empresa'},
    {name: 'N/A', id: 'N/A'}
  ];
  diseno: any = {
    designs: [],
    image: ''
  };

  constructor(
    public dialogRef: MatDialogRef<NuevoDisenoComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _design: CertificateDesignApi,
    private _user: UserApi,
    private _company: CompanyApi,
    private loopBackAuth: LoopBackAuth,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    
    this.form = this.formBuilder.group({
      image: ['', Validators.required],
      designs: ['', Validators.required],
      type: ['', Validators.required]
    }); 
   }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 20,
      allowSearchFilter: false
    };
    this.categories = this.data.categories;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  uploadFileLogo(event) {

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (eventReader: any) => {
      this.image = eventReader.target.result;
    };

    this.uploader.queue[0].file.name = UUID.UUID();
    this.diseno.image = this.uploader.queue[0].file.name;
  }

  createDesign() {
    this.diseno.companyId = this.data.companyId;
    this.diseno.designs = this.diseno.designs.map((de) => de.name);
    
    this._design.create(this.diseno).subscribe((newDiseno) => {
      if (this.uploader.queue.length) {
        this.uploader.uploadAll();
      }
      this.dialogRef.close(newDiseno);
    });
  }

  onItemSelect(item: any) {
    console.log(item);
    
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
