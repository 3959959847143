import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { UserApi, EventApi, LoopBackAuth } from '../../../services/shared/sdk';
import { MatDialog } from '@angular/material/dialog';
import { NuevoEventoComponent } from '../nuevo-evento/nuevo-evento.component';
import { DetalleEventoComponent } from '../detalle-evento/detalle-evento.component';
import { EditarEventoComponent } from '../editar-evento/editar-evento.component';
import { EliminarEventoComponent } from '../eliminar-evento/eliminar-evento.component';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { FileUploader } from 'ng2-file-upload';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import { SolicitudEventoComponent } from '../solicitud-evento/solicitud-evento.component';
import * as moment from 'moment'
import { SubirEventoComponent } from '../subir-evento/subir-evento.component';

@Component({
  selector: 'app-lista-evento',
  templateUrl: './lista-evento.component.html',
  styleUrls: ['./lista-evento.component.scss']
})
export class ListaEventoComponent implements OnInit {
  displayedColumns: string[] = ['eventid', 'companyName', 'date', 'place', 'campusName', 'startTime', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild('MatSort1') sort: MatSort;
  @ViewChild('singlePaginator') paginator: MatPaginator;
  events;
  uploadURL = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/upload?access_token=' + this.loopBackAuth.getToken().id;
  uploader: FileUploader = new FileUploader({ url: this.uploadURL });

  constructor(
    private router: Router,
    private _event: EventApi,
    private _user: UserApi,
    public dialog: MatDialog,
    private loopBackAuth: LoopBackAuth
  ) {
    this.getEvents();
  }

  ngOnInit() {
  }

  getEvents() {
    this._event.find({
      include: ['company', 'campus'],
      where: {
        //isDeleted: false
      }
    }).subscribe((events: any) => {
      this.events = events;
      this.events.forEach((event) => {
        event.date = moment(event.date).add(5, 'hours');
        event.campusName = event.campus.name;
        event.companyName = event.company.name;
        switch (event.id) {
          case 34:
            event.companyName = 'Tecnológico de Monterrey';
            break;
          case 35:
            event.companyName = 'Sorteos Tec';
            break;
          case 36:
            event.companyName = 'Secundarias';
            break;
        }
        event.eventid = event.isValidated == false ? event.id + ' (Pendiente)' : event.id;
      });

      this.dataSource = new MatTableDataSource(events);
      this.dataSource.sort = this.sort;
      setTimeout(() => this.dataSource.paginator = this.paginator);
    });
  }

  openNewEvent() {
    const dialogRef = this.dialog.open(NuevoEventoComponent, {
      width: '60%',
      data: this.events
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.getEvents();

      }
    });
  }

  openEditEvent(row, index) {
    const dialogRef = this.dialog.open(EditarEventoComponent, {
      width: '60%',
      data: {
        event: row,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log('The dialog was closed');
      if (data) {
        this.events[data.index] = data.event;
      }
    });
  }

  openDetailEvent(row) {
    const dialogRef = this.dialog.open(DetalleEventoComponent, {
      width: '60%',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  openEliminarEvent(row, index) {
    const dialogRef = this.dialog.open(EliminarEventoComponent, {
      width: '60%',
      data: {
        event: row,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed', data);
      if (data) {
        this.events.splice(data.index, 1);
        this.dataSource = new MatTableDataSource(this.events);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      }
    });
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createPlantilla() {
    let data = [{
      empresa: 'Empresa',
      fecha: 'Fecha',
      lugar: 'Lugar',
      sede: 'Campus/Sede',
      hora: 'Horario'
    },
    {
      empresa: 'Demco',
      fecha: '2020-12-20',
      lugar: 'Sala de conferencia',
      sede: 'Ejemplo',
      hora: '18:00'
    }];
    const archivo = new Angular5Csv(data, 'plantilla');
  }

  uploadPLantilla() {
    const dialogRef = this.dialog.open(SubirEventoComponent, {
      panelClass: 'col-11 col-lg-7 col-xl-5'.split(' ')
    });
  }

  solicitud(row) {
    const dialog = this.dialog.open(SolicitudEventoComponent, {
      width: '50%',
      data: row
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.getEvents();
      }
    })
  }

}
