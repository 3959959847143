/* tslint:disable */
import {
  Campus,
  Company,
  User,
  Employee_event
} from '../index';

declare var Object: any;
export interface EventInterface {
  "date": Date;
  "place": string;
  "campusId": number;
  "startTime": string;
  "endTime"?: string;
  "system"?: number;
  "companyId"?: number;
  "isDeleted"?: boolean;
  "active"?: boolean;
  "isValidated"?: boolean;
  "link"?: string;
  "note"?: string;
  "id"?: number;
  campus?: Campus;
  company?: Company;
  users?: User[];
  assistances?: Employee_event[];
}

export class Event implements EventInterface {
  "date": Date;
  "place": string;
  "campusId": number;
  "startTime": string;
  "endTime": string;
  "system": number;
  "companyId": number;
  "isDeleted": boolean;
  "active": boolean;
  "isValidated": boolean;
  "link": string;
  "note": string;
  "id": number;
  campus: Campus;
  company: Company;
  users: User[];
  assistances: Employee_event[];
  constructor(data?: EventInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Event`.
   */
  public static getModelName() {
    return "Event";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Event for dynamic purposes.
  **/
  public static factory(data: EventInterface): Event{
    return new Event(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Event',
      plural: 'Events',
      path: 'Events',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "place": {
          name: 'place',
          type: 'string'
        },
        "campusId": {
          name: 'campusId',
          type: 'number'
        },
        "startTime": {
          name: 'startTime',
          type: 'string'
        },
        "endTime": {
          name: 'endTime',
          type: 'string'
        },
        "system": {
          name: 'system',
          type: 'number'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "isValidated": {
          name: 'isValidated',
          type: 'boolean'
        },
        "link": {
          name: 'link',
          type: 'string'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        campus: {
          name: 'campus',
          type: 'Campus',
          model: 'Campus',
          relationType: 'belongsTo',
                  keyFrom: 'campusId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        users: {
          name: 'users',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
          modelThrough: 'Employee_event',
          keyThrough: 'userId',
          keyFrom: 'id',
          keyTo: 'eventId'
        },
        assistances: {
          name: 'assistances',
          type: 'Employee_event[]',
          model: 'Employee_event',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'eventId'
        },
      }
    }
  }
}
