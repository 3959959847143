import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CompanyApi, UserApi, BalanceHistoryApi, Company } from '../../../services/shared/sdk';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { NuevoSaldoComponent } from '../nuevo-saldo/nuevo-saldo.component';
import { HistoryComponent } from '../modals/history/history.component';
import { AbonoEmpresaComponent } from '../modals/abono-empresa/abono-empresa.component';
import { EditarSaldoComponent } from '../editar-saldo/editar-saldo.component'

@Component({
  selector: 'app-lista-saldos',
  templateUrl: './lista-saldos.component.html',
  styleUrls: ['./lista-saldos.component.scss']
})
export class ListaSaldosComponent implements OnInit {
  displayedColumns: string[] = ['name', 'balanceType', 'saldodisponible', 'ultimouso', 'abonado', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('singlePaginator') paginator: MatPaginator;
  companies = [];
  balances = [];

  constructor(
    private router: Router,
    private _company: CompanyApi,
    private _user: UserApi,
    public dialog: MatDialog,
    private _balance: BalanceHistoryApi
  ) { }

  ngOnInit() {
    this.getCompanies();

    this._company.find(
      {
        where: {
          isDeleted: false
        },
        include: [
          'balanceHistories',
          'adminCompanies',
          {
            relation: 'categories',
            scope: {
              include: ['balanceHistories']
            }
          }
        ]
      }
    ).subscribe((companies) => {
      this.balances = [];
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.companies = companies;
        } else {
          this.companies = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id);
          });
        }
        console.log(this.companies)
        this.companies.forEach((com: any) => {
          com.saldodisponible = 0;
          com.ultimouso = false;
          com.abonado = 0;
          com.limiteuso = 0;
          if (com.balanceType == 1) {
            com.saldodisponible = com.categories.reduce((acc, current) => acc += current.balance, 0);
            com.categories.map(category => {
              com.abonado += category.balanceHistories.filter(bal => bal.status == 'inBalance').reduce((acc, current) => acc += current.balance, 0);
            })
          } else {
            if (com.balanceHistories.length) {
              com.balanceHistories.forEach((bal: any) => {
                if (bal.status == 'inBalance') {
                  com.saldodisponible += (bal.balance - bal.balanceUsed);
                  com.ultimouso = bal.updatedAt;
                  com.abonado += bal.balance;
                }

              });
            }
          }
          this.balances.push(com);

        })

        this.dataSource = new MatTableDataSource(this.balances);
        this.dataSource.sort = this.sort;
        setTimeout(() => this.dataSource.paginator = this.paginator);

      });

    });
  }
  onChange(companyId) {
    console.log(companyId);

    let aux = this.balances.filter((balance) => {
      return balance.id == companyId
    });
    this.dataSource = new MatTableDataSource(aux);
  }

  getCompanies() {
    this._company.find({
      where: {
        isDeleted: false
      }
    }).subscribe((companies: any) => {
      this.companies = companies;
    });
  }

  openDialogSaldo() {
    const dialogRef = this.dialog.open(NuevoSaldoComponent, {
      width: '80%',
      data: {
        companies: this.companies
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      console.log(resp);
      if (resp) {
        let com = {
          saldodisponible: resp.balanceNew.balance,
          ultimouso: resp.balanceNew.createdAt,
          abonado: resp.balanceNew.balance,
          limitUse: resp.company.limitUse,
          periodicity: resp.company.periodicity,
          name: resp.company.name
        }

        this.balances.push(com);
        console.log(this.balances);
        this.companies.forEach((company) => {
          if (company.id == resp.company.id) {
            company.limitUse = resp.company.limitUse,
              company.periodicy = resp.company.periodicity
          }
        });
        this.dataSource = new MatTableDataSource(this.balances);
      }

    });
  }

  openDialogHistory(item) {
    const dialogRef = this.dialog.open(HistoryComponent, {
      width: '60%',
      autoFocus: false,
      maxHeight: '70vh',
      data: {
        balance: item
      }
    });

    dialogRef.afterClosed().subscribe(balance => {
      console.log('The dialog was closed');

    });
  }

  openDialogAddBalance(item, index) {
    const dialogRef = this.dialog.open(AbonoEmpresaComponent, {
      width: '50%',
      data: {
        company: item,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.balances[resp.index] = resp.company;
      }
    });
  }

  openDialogEditBalance(item, index) {

    const dialogRef = this.dialog.open(EditarSaldoComponent, {
      width: '80%',
      data: {
        company: item,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.balances[data.index] = data.company;
      }

    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
