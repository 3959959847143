import { Component, OnInit } from '@angular/core';
import { StoreApi, GiftsApi } from '../../services/shared/sdk';
import { EntregaRegalosComponent } from '../../views/pedidos-colaboradores/entrega-regalos/entrega-regalos.component';
import { ActivatedRoute } from '@angular/router';
import * as moment from "moment";
import {MatDialog} from '@angular/material';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../services/base-url';

@Component({
  selector: 'app-cert-template',
  templateUrl: './cert-template.component.html',
  styleUrls: ['./cert-template.component.scss']
})
export class CertTemplateComponent implements OnInit {

  idGift;

   Certificado;
   date = new Date();
   date1;

   contact = [
      "help@demco.mx",
      "(045) (81) 10 24 61 68"
   ];

   openHours = [
      "Lunes a Viernes 9:30 AM - 6:30 PM"
   ];

   imgError = "src/assets/images/cert.png"

    instrucciones = [
      "1. Ingresa a la página del comercio.",
      "2. Selecciona los artículos de tu preferencia.",
      "3. Al realizar el pago selecciona la opción de certificado de regalo, el número de folio y NIP que aparecen en tu certificado de regalo."
    ];
    restricciones = [
      "-Este certificdo no es reembolsable ni intercambiable por dinero en efectivo.",
      "-Valida únicamente para redención en www.gandhi.com.mx",
      "-Envíos en página son gratis en compras superiores a los $100 pesos. Cambios en política de envíos sin previo aviso."
    ];
  

  constructor(
    private _Store : StoreApi,
    private _Gift : GiftsApi,
    private route : ActivatedRoute,
    public dialog: MatDialog,
    ) {
      this.route.params.subscribe(resp => {
        if(resp && resp.id){
          this.idGift = resp.id;
        }
      })
     }

  ngOnInit() { 
    this.BuscarGift(this.idGift);
  }

  BuscarGift(id){
    this._Gift.findById(id,{include: ['store','employee']}).subscribe((resp:any) => {
      this.Certificado = resp;
      this.Certificado.employeeName = resp.employee.name;
      console.log(this.Certificado);
      this.Certificado.logo = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + resp.store.logo;
      this.date1 = moment(this.date).add(3,'months').format('DD/MMM/YYYY');
      this.Certificado.color = resp.store.color ? resp.store.color : "#cf3c1f";
    })
  }

  Descargar(){
    document.getElementById('download').style.visibility="hidden";
    document.getElementById('volver').style.visibility="hidden";
    alert('Verifique el Nombre del archivo PDF y la ubicación donde será guardado.')
    window.print();
    document.getElementById('download').style.visibility="visible";
    document.getElementById('volver').style.visibility="visible";
  }

  OpenEntrega(){
    const EntregaOrder = this.dialog.open(EntregaRegalosComponent, {
      width: '50%',
      data: this.Certificado
    });
    EntregaOrder.afterClosed().subscribe((result)=>{
      if(result){
        console.log('The dialog was closed');
        this.BuscarGift(this.idGift);
      }
    })
  }
}
