import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, PatternValidator } from '@angular/forms';
import { UserApi } from '../../../services/shared/sdk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AsignaCertificadoComponent } from '../asigna-certificado/asigna-certificado.component';

@Component({
  selector: 'app-registrar-colaborador',
  templateUrl: './registrar-colaborador.component.html',
  styleUrls: ['./registrar-colaborador.component.scss']
})
export class RegistrarColaboradorComponent implements OnInit {

  form: FormGroup;

  campuses: any[];

  patronEmail = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";

  states = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ];


  constructor(
    public dialogRef: MatDialogRef<RegistrarColaboradorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user: UserApi,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      payrollNumber: [''],
      dateAdmission: [null],
      email: ['', [Validators.required, Validators.pattern(this.patronEmail)]],
      country: ['', Validators.required],
      campusId: [null, Validators.required],
      companyId: [this.data.companyId],
      years: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.campuses = this.data.campuses
    console.log('this.campuses', this.campuses);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }

  createUser() {
    let user = this.form.value;
    user.password = '3' + Math.floor(1000000 + Math.random() * 9000000);
    user.pass = user.password;
    user.type = 'employee';

    this._user.create(user).subscribe((User) => {
      console.log('newUser', User);
      this.snackBar.open('Colaborador creado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(User);
      this.openAddCertificate(User.id);
    }, (error => {
      console.log('newUSer error', error);

      this.snackBar.open('Ocurrió un problema', 'ERROR', {
        duration: 5000
      });
      this.dialogRef.close();
    }));
  }

  openAddCertificate(userId) {
    const dialog = this.dialog.open(AsignaCertificadoComponent, {
      width: '80%',
      maxHeight: '90vh',
      data: {
        companyId: this.data.companyId,
        userId: userId
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        console.log('The dialog was closed');
      }
    })
  }

}
