/* tslint:disable */
import {
  User,
  Company
} from '../index';

declare var Object: any;
export interface CampusInterface {
  "name"?: string;
  "abbreviation"?: string;
  "notAvailableProducts"?: Array<any>;
  "companyId"?: number;
  "isDeleted"?: boolean;
  "id"?: number;
  users?: User[];
  company?: Company;
}

export class Campus implements CampusInterface {
  "name": string;
  "abbreviation": string;
  "notAvailableProducts": Array<any>;
  "companyId": number;
  "isDeleted": boolean;
  "id": number;
  users: User[];
  company: Company;
  constructor(data?: CampusInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Campus`.
   */
  public static getModelName() {
    return "Campus";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Campus for dynamic purposes.
  **/
  public static factory(data: CampusInterface): Campus{
    return new Campus(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Campus',
      plural: 'Campuses',
      path: 'Campuses',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "abbreviation": {
          name: 'abbreviation',
          type: 'string'
        },
        "notAvailableProducts": {
          name: 'notAvailableProducts',
          type: 'Array&lt;any&gt;'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        users: {
          name: 'users',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'campusId'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
      }
    }
  }
}
