/* tslint:disable */
import { Injectable } from '@angular/core';
import { Company } from '../../models/Company';
import { Employee } from '../../models/Employee';
import { Store } from '../../models/Store';
import { Company_store } from '../../models/Company_store';
import { Admin } from '../../models/Admin';
import { Admin_company } from '../../models/Admin_company';
import { BalanceHistory } from '../../models/BalanceHistory';
import { Group } from '../../models/Group';
import { Group_employee } from '../../models/Group_employee';
import { OrderCompany } from '../../models/OrderCompany';
import { OrderEmployee } from '../../models/OrderEmployee';
import { CertificateCategory } from '../../models/CertificateCategory';
import { Ticket } from '../../models/Ticket';
import { Certificate } from '../../models/Certificate';
import { Gifts } from '../../models/Gifts';
import { CertTemplate } from '../../models/CertTemplate';
import { CertificateDesign } from '../../models/CertificateDesign';
import { User } from '../../models/User';
import { Container } from '../../models/Container';
import { Faq } from '../../models/Faq';
import { ImporantDate } from '../../models/ImporantDate';
import { CertSchedule } from '../../models/CertSchedule';
import { Campus } from '../../models/Campus';
import { Event } from '../../models/Event';
import { Employee_event } from '../../models/Employee_event';
import { Campus_commerce } from '../../models/Campus_commerce';
import { Codigo_postal } from '../../models/Codigo_postal';
import { Question } from '../../models/Question';
import { Response } from '../../models/Response';
import { Question_company } from '../../models/Question_company';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Company: Company,
    Employee: Employee,
    Store: Store,
    Company_store: Company_store,
    Admin: Admin,
    Admin_company: Admin_company,
    BalanceHistory: BalanceHistory,
    Group: Group,
    Group_employee: Group_employee,
    OrderCompany: OrderCompany,
    OrderEmployee: OrderEmployee,
    CertificateCategory: CertificateCategory,
    Ticket: Ticket,
    Certificate: Certificate,
    Gifts: Gifts,
    CertTemplate: CertTemplate,
    CertificateDesign: CertificateDesign,
    User: User,
    Container: Container,
    Faq: Faq,
    ImporantDate: ImporantDate,
    CertSchedule: CertSchedule,
    Campus: Campus,
    Event: Event,
    Employee_event: Employee_event,
    Campus_commerce: Campus_commerce,
    Codigo_postal: Codigo_postal,
    Question: Question,
    Response: Response,
    Question_company: Question_company,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
