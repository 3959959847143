import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-solicitud-evento',
  templateUrl: './solicitud-evento.component.html',
  styleUrls: ['./solicitud-evento.component.scss']
})
export class SolicitudEventoComponent implements OnInit {

  constructor(
    public dialogRef : MatDialogRef<SolicitudEventoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user : UserApi,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log(this.data);
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  confirm(resp) {
    let aux = {
      campus: this.data.campusName,
      place: this.data.place,
      startTime: this.data.startTime,
      date: this.data.date
    }
    
    this._user.confirmRequestEvent(this.data.id, resp, aux).subscribe((resp) => {
      
      this.snackBar.open('Solicitud actualizada', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close();
    });
  }

}
