import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnlyLoggedInUsersGuard } from './services/auth-guard.service';

// COMPONENTES APP //
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ListaEmpresaComponent } from './views/empresas/lista/lista.component';
import { LoginComponent} from './views/login/login.component';
import { NuevaEmpresaComponent} from './views/empresas/nueva-empresa/nueva-empresa.component';
import { EditEmpresaComponent} from './views/empresas/edit-empresa/edit-empresa.component';
import { AdministradoresComponent} from './views/administradores/administradores.component'
import { NuevoAdminComponent } from './views/administradores/nuevo-admin/nuevo-admin.component';
import { ListaSaldosComponent } from './views/saldos/lista-saldos/lista-saldos.component';
import { ListaSoporteComponent } from './views/soporte/lista-soporte/lista-soporte.component';
import { ListaColaboradoresComponent } from './views/colaboradores/lista-colaboradores/lista-colaboradores.component';
import { EditarColaboradorComponent } from './views/colaboradores/editar-colaborador/editar-colaborador.component';
import { ListaComerciosComponent } from './views/comercios/lista-comercios/lista-comercios.component';
import { NuevoComercioComponent } from './views/comercios/nuevo-comercio/nuevo-comercio.component';
import { EditComercioComponent } from './views/comercios/edit-comercio/edit-comercio.component';
import { ListaPedidosEmpresasComponent } from './views/pedidos-empresas/lista-pedidos-empresas/lista-pedidos-empresas.component';
import { ListaPedidosColaboradoresComponent } from './views/pedidos-colaboradores/lista-pedidos-colaboradores/lista-pedidos-colaboradores.component';
import { ListaCategoriasComponent } from './views/personalizacion/lista-categorias/lista-categorias.component';
import { ListaEventoComponent } from './views/Eventos/lista-evento/lista-evento.component';
import { ListaCampusComponent } from './views/campus/lista-campus/lista-campus.component';
import { AsistenciasComponent } from './views/Eventos/asistencias/asistencias.component';
import { Loginv2Component } from './views/loginv2/loginv2.component';
import { AdministracionComponent } from './views/administracion/administracion.component';
import { UploadComponent } from './views/upload/upload.component';
import { CertTemplateComponent } from './components/cert-template/cert-template.component';

const APP_ROUTES: Routes = [
{ path: 'login', component: LoginComponent},
{ path: 'loginv2', component: Loginv2Component},
{ path: 'inicio', component: DashboardComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'soporte/:id', component: ListaSoporteComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'soporte', component: ListaSoporteComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'administradores', component: AdministradoresComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'newUser', component: NuevoAdminComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'lista-empresas', component: ListaEmpresaComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'nueva-empresa', component: NuevaEmpresaComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'editar-empresa/:id', component: EditEmpresaComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'lista-saldos', component: ListaSaldosComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'lista-colaboradores', component: ListaColaboradoresComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'lista-colaboradores/:id', component: ListaColaboradoresComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'editar-colaborador/:id', component: EditarColaboradorComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'lista-comercios', component: ListaComerciosComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'nuevo-comercio', component: NuevoComercioComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'editar-comercio/:id', component: EditComercioComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'pedidos-empresas', component: ListaPedidosEmpresasComponent, canActivate: [OnlyLoggedInUsersGuard]},
{ path: 'pedidos-colaboradores', component: ListaPedidosColaboradoresComponent, canActivate : [OnlyLoggedInUsersGuard]},
{ path: 'lista-categorias', component: ListaCategoriasComponent, canActivate : [OnlyLoggedInUsersGuard]},
{ path: 'lista-categorias/:id', component: ListaCategoriasComponent, canActivate : [OnlyLoggedInUsersGuard]},
{ path: 'lista-eventos', component: ListaEventoComponent, canActivate : [OnlyLoggedInUsersGuard]},
{ path: 'lista-campus', component: ListaCampusComponent, canActivate : [OnlyLoggedInUsersGuard]},
{ path: 'asistencias/:id', component: AsistenciasComponent, canActivate : [OnlyLoggedInUsersGuard]},
{ path: 'administracion', component:AdministracionComponent, canActivate: [OnlyLoggedInUsersGuard] },
{ path: 'upload', component:UploadComponent, canActivate: [OnlyLoggedInUsersGuard] },
{ path: 'template/:id', component: CertTemplateComponent},
{ path: '**', pathMatch: 'full', redirectTo: 'loginv2' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
