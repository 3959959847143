import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SDKBrowserModule } from './services/shared/sdk/index';
import { CKEditorModule } from 'ckeditor4-angular';
import { APP_ROUTING } from './app.routing';
import { OnlyLoggedInUsersGuard } from './services/auth-guard.service';
import { AppComponent } from './app.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { CalendarDialogComponent } from './components/calendar-dialog/calendar-dialog.component';
import { IconsComponent } from './components/icons/icons.component';
import { AdministradoresComponent, AdminsEmpresas } from './views/administradores/administradores.component';
import { ListaEmpresaComponent } from './views/empresas/lista/lista.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatTableModule,
  MatNativeDateModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatTooltipModule,
  MatInputModule,
  MatDatepickerModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSnackBarModule,
  MatPaginatorModule,
  MatBadgeModule,
  MatSortModule,
  MatProgressSpinnerModule
} from "@angular/material";
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './views/login/login.component';
import { NuevaEmpresaComponent } from './views/empresas/nueva-empresa/nueva-empresa.component';
import { NuevoAdminComponent } from './views/administradores/nuevo-admin/nuevo-admin.component';
import { EditarPasswordComponent } from './views/administradores/editar-password/editar-password.component';
import { EditarAdminComponent } from './views/administradores/editar-admin/editar-admin.component';
import { BorrarAdminComponent } from './views/administradores/borrar-admin/borrar-admin.component';
import { DialogCompanyDetails, DialogCompanyDelete, DialogCompanyShops, DialogCompanyPassword } from './views/empresas/lista/lista.component';
import { ListaSaldosComponent } from './views/saldos/lista-saldos/lista-saldos.component';
import { NuevoSaldoComponent } from './views/saldos/nuevo-saldo/nuevo-saldo.component';
import { HistoryComponent } from './views/saldos/modals/history/history.component';
import { AbonoEmpresaComponent } from './views/saldos/modals/abono-empresa/abono-empresa.component';
import { ListaSoporteComponent, InfoTickets, ResolveTickets } from './views/soporte/lista-soporte/lista-soporte.component';
import { ListaColaboradoresComponent } from './views/colaboradores/lista-colaboradores/lista-colaboradores.component';
import { RegistrarColaboradorComponent } from './views/colaboradores/registrar-colaborador/registrar-colaborador.component';
import { EditarColaboradorComponent } from './views/colaboradores/editar-colaborador/editar-colaborador.component';
import { PerfilColaboradorComponent } from './views/colaboradores/perfil-colaborador/perfil-colaborador.component';
import { EditarPasswordUserComponent } from './views/colaboradores/editar-password/editar-password.component';
import { EliminarColaboradorComponent } from './views/colaboradores/eliminar-colaborador/eliminar-colaborador.component';
import { IntegrantesGrupoComponent } from './views/colaboradores/integrantes-grupo/integrantes-grupo.component';
import { EditarSaldoComponent } from './views/saldos/editar-saldo/editar-saldo.component';
import { ListaComerciosComponent } from './views/comercios/lista-comercios/lista-comercios.component';
import { NuevoComercioComponent } from './views/comercios/nuevo-comercio/nuevo-comercio.component';
import { DetalleComercioComponent } from './views/comercios/detalle-comercio/detalle-comercio.component';
import { DeleteComercioComponent } from './views/comercios/delete-comercio/delete-comercio.component';
import { EditComercioComponent } from './views/comercios/edit-comercio/edit-comercio.component';
import { ListaPedidosEmpresasComponent } from './views/pedidos-empresas/lista-pedidos-empresas/lista-pedidos-empresas.component';
import { ListaPedidosColaboradoresComponent } from './views/pedidos-colaboradores/lista-pedidos-colaboradores/lista-pedidos-colaboradores.component';
import { RegistrarGrupoComponent } from './views/colaboradores/registrar-grupo/registrar-grupo.component';
import { EditarGrupoComponent } from './views/colaboradores/editar-grupo/editar-grupo.component';
import { EliminarGrupoComponent } from './views/colaboradores/eliminar-grupo/eliminar-grupo.component';
import { ListaCategoriasComponent } from './views/personalizacion/lista-categorias/lista-categorias.component';
import { NuevaCategoriaComponent } from './views/personalizacion/nueva-categoria/nueva-categoria.component';
import { EditarCategoriaComponent } from './views/personalizacion/editar-categoria/editar-categoria.component';
import { NuevoDisenoComponent } from './views/personalizacion/nuevo-diseno/nuevo-diseno.component';
import { DetalleDisenoComponent } from './views/personalizacion/detalle-diseno/detalle-diseno.component';
import { EditDisenoComponent } from './views/personalizacion/edit-diseno/edit-diseno.component';
import { DeleteDisenoComponent } from './views/personalizacion/delete-diseno/delete-diseno.component';
import { NuevaFraseComponent } from './views/personalizacion/nueva-frase/nueva-frase.component';
import { EditFraseComponent } from './views/personalizacion/edit-frase/edit-frase.component';
import { DetalleFraseComponent } from './views/personalizacion/detalle-frase/detalle-frase.component';
import { DeleteFraseComponent } from './views/personalizacion/delete-frase/delete-frase.component';
import { NuevoCertificadoComponent } from './views/personalizacion/nuevo-certificado/nuevo-certificado.component';
import { InfoPedidoComponent } from './views/pedidos-colaboradores/info-pedido/info-pedido.component';
import { EntregaRegalosComponent } from './views/pedidos-colaboradores/entrega-regalos/entrega-regalos.component';
import { InfoPedidoEmpresaComponent } from './views/pedidos-empresas/info-pedido-empresa/info-pedido-empresa.component';
import { EvaluacionPedidoComponent } from './views/pedidos-empresas/evaluacion-pedido/evaluacion-pedido.component';
import { EditEmpresaComponent } from './views/empresas/edit-empresa/edit-empresa.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NuevoEventoComponent } from './views/Eventos/nuevo-evento/nuevo-evento.component';
import { ListaEventoComponent } from './views/Eventos/lista-evento/lista-evento.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ListaCampusComponent } from './views/campus/lista-campus/lista-campus.component';
import { NuevoCampusComponent } from './views/campus/nuevo-campus/nuevo-campus.component';
import { DetallesCampusComponent } from './views/campus/detalles-campus/detalles-campus.component';
import { DetalleEventoComponent } from './views/Eventos/detalle-evento/detalle-evento.component';
import { EliminarCampusComponent } from './views/campus/eliminar-campus/eliminar-campus.component';
import { EditarCampusComponent } from './views/campus/editar-campus/editar-campus.component';
import { EditarEventoComponent } from './views/Eventos/editar-evento/editar-evento.component';
import { EliminarEventoComponent } from './views/Eventos/eliminar-evento/eliminar-evento.component';
import { SolicitudBajaComponent } from './views/colaboradores/solicitud-baja/solicitud-baja.component';
import { SolicitudCategoriaComponent } from './views/personalizacion/solicitud-categoria/solicitud-categoria.component';
import { AsistenciasComponent } from './views/Eventos/asistencias/asistencias.component';
import { SolicitudEventoComponent } from './views/Eventos/solicitud-evento/solicitud-evento.component';
import { EditarCertificadoComponent } from './views/personalizacion/editar-certificado/editar-certificado.component';
import { EliminarCertificadoComponent } from './views/personalizacion/eliminar-certificado/eliminar-certificado.component';
import { Loginv2Component } from './views/loginv2/loginv2.component';
import { AdministracionComponent } from './views/administracion/administracion.component';
import { AsignaCertificadoComponent } from './views/colaboradores/asigna-certificado/asigna-certificado.component';
import { UploadComponent } from './views/upload/upload.component';
import { ForgotPasswordComponent } from './views/loginv2/modal-forgot/forgot-password/forgot-password.component';
import { CertTemplateComponent } from './components/cert-template/cert-template.component';
import { ReenviarInvitacionComponent } from './views/colaboradores/reenviar-invitacion/reenviar-invitacion.component';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { FilterUsersPipe } from './pipes/filter-users.pipe';
import { SubirEventoComponent } from './views/Eventos/subir-evento/subir-evento.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { AsignarAdminCategoriasComponent } from './views/asignar-admin-categorias/asignar-admin-categorias.component';
import { AsignarSaldoCategoriaComponent } from './views/personalizacion/asignar-saldo-categoria/asignar-saldo-categoria.component';
import { BalanceTypePipe } from './pipes/balance-type.pipe';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    CalendarDialogComponent,
    IconsComponent,
    AdministradoresComponent,
    ListaEmpresaComponent,
    LoginComponent,
    NuevaEmpresaComponent,
    NuevoAdminComponent,
    EditarPasswordComponent,
    AdminsEmpresas,
    EditarAdminComponent,
    BorrarAdminComponent,
    DialogCompanyDetails,
    DialogCompanyShops,
    DialogCompanyDelete,
    ListaSaldosComponent,
    DialogCompanyPassword,
    NuevoSaldoComponent,
    HistoryComponent,
    AbonoEmpresaComponent,
    ListaSoporteComponent,
    InfoTickets,
    ResolveTickets,
    ListaColaboradoresComponent,
    RegistrarColaboradorComponent,
    EditarColaboradorComponent,
    PerfilColaboradorComponent,
    EditarPasswordUserComponent,
    EliminarColaboradorComponent,
    IntegrantesGrupoComponent,
    EditarSaldoComponent,
    ListaComerciosComponent,
    NuevoComercioComponent,
    DetalleComercioComponent,
    DeleteComercioComponent,
    EditComercioComponent,
    ListaPedidosEmpresasComponent,
    ListaPedidosColaboradoresComponent,
    RegistrarGrupoComponent,
    EditarGrupoComponent,
    EliminarGrupoComponent,
    ListaCategoriasComponent,
    NuevaCategoriaComponent,
    EditarCategoriaComponent,
    NuevoDisenoComponent,
    DetalleDisenoComponent,
    EditDisenoComponent,
    DeleteDisenoComponent,
    NuevaFraseComponent,
    EditFraseComponent,
    DetalleFraseComponent,
    DeleteFraseComponent,
    NuevoCertificadoComponent,
    InfoPedidoComponent,
    EntregaRegalosComponent,
    InfoPedidoEmpresaComponent,
    EvaluacionPedidoComponent,
    EditEmpresaComponent,
    NuevoEventoComponent,
    ListaEventoComponent,
    ListaCampusComponent,
    NuevoCampusComponent,
    DetallesCampusComponent,
    DetalleEventoComponent,
    EliminarCampusComponent,
    EditarCampusComponent,
    EditarEventoComponent,
    EliminarEventoComponent,
    SolicitudBajaComponent,
    SolicitudCategoriaComponent,
    AsistenciasComponent,
    SolicitudEventoComponent,
    EditarCertificadoComponent,
    EliminarCertificadoComponent,
    Loginv2Component,
    AdministracionComponent,
    AsignaCertificadoComponent,
    UploadComponent,
    ForgotPasswordComponent,
    CertTemplateComponent,
    ReenviarInvitacionComponent,
    FilterUsersPipe,
    SubirEventoComponent,
    UploaderComponent,
    AsignarAdminCategoriasComponent,
    AsignarSaldoCategoriaComponent,
    BalanceTypePipe
  ],
  entryComponents: [
    EditarPasswordComponent,
    AdminsEmpresas,
    EditarAdminComponent,
    BorrarAdminComponent,
    DialogCompanyDetails,
    DialogCompanyShops,
    DialogCompanyDelete,
    DialogCompanyPassword,
    NuevoSaldoComponent,
    HistoryComponent,
    AbonoEmpresaComponent,
    InfoTickets,
    ResolveTickets,
    RegistrarColaboradorComponent,
    EditarPasswordUserComponent,
    PerfilColaboradorComponent,
    EliminarColaboradorComponent,
    IntegrantesGrupoComponent,
    EditarSaldoComponent,
    NuevoAdminComponent,
    DetalleComercioComponent,
    DeleteComercioComponent,
    RegistrarGrupoComponent,
    EditarGrupoComponent,
    EliminarGrupoComponent,
    NuevaCategoriaComponent,
    EditarCategoriaComponent,
    NuevoDisenoComponent,
    DetalleDisenoComponent,
    EditDisenoComponent,
    DeleteDisenoComponent,
    NuevaFraseComponent,
    EditFraseComponent,
    DetalleFraseComponent,
    DeleteFraseComponent,
    NuevoCertificadoComponent,
    CalendarDialogComponent,
    IconsComponent,
    InfoPedidoComponent,
    EntregaRegalosComponent,
    InfoPedidoEmpresaComponent,
    EvaluacionPedidoComponent,
    NuevoEventoComponent,
    NuevoCampusComponent,
    DetallesCampusComponent,
    EliminarCampusComponent,
    DetalleEventoComponent,
    EditarEventoComponent,
    EliminarEventoComponent,
    EditarCampusComponent,
    SolicitudBajaComponent,
    SolicitudCategoriaComponent,
    SolicitudEventoComponent,
    EditarCertificadoComponent,
    EliminarCertificadoComponent,
    AsignaCertificadoComponent,
    ForgotPasswordComponent,
    ReenviarInvitacionComponent,
    SubirEventoComponent,
    AsignarAdminCategoriasComponent,
    AsignarSaldoCategoriaComponent
  ],
  imports: [
    SDKBrowserModule.forRoot(),
    APP_ROUTING,
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    FileUploadModule,
    NgbDatepickerModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatSortModule,
    SlickCarouselModule,
    NgMultiSelectDropDownModule.forRoot(),
    CKEditorModule,
    NgxMatDrpModule,
    MatProgressSpinnerModule
  ],
  providers: [
    OnlyLoggedInUsersGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
