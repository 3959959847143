import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UserApi, GroupApi, Group_employeeApi } from '../../../services/shared/sdk';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-editar-grupo',
  templateUrl: './editar-grupo.component.html',
  styleUrls: ['./editar-grupo.component.scss']
})
export class EditarGrupoComponent implements OnInit {

  form: FormGroup;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  colabCtrl = new FormControl();
  filteredColaboradores: Observable<string[]>;
  colaboradores: any = [];
  members: any[];
  otherUsers: any[];
  allUsers: any = [];
  created = new Date();
  userSearched: string = null;

  @ViewChild('colaboradorInput') colaboradorInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditarGrupoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user: UserApi,
    private _group: GroupApi,
    private _groupUser: Group_employeeApi,
    private _snackBar: MatSnackBar
  ) {
    this.members = this.data.group.members.map(member => member.user)
    this.colaboradores = this.data.group.members.map(member => member.user.email)
  }

  remove(colab: string): void {
    const index = this.colaboradores.indexOf(colab);
    const toRemove = this.members.find(member => member.email == colab);
    if (toRemove && !toRemove.enter) {
      toRemove.exit = true;
    }

    this.colaboradores.splice(index, 1);
    this.colabCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedMail = event.option.viewValue
    const selectedUser = event.option.value
    if (this.colaboradores.includes(selectedMail)) {
      this._snackBar.open('Este usuario ya está en la lista', 'Error', {
        duration: 2000
      });
      return;
    }
    const isMember = this.members.find(member => member.email == selectedMail)
    if (!isMember) {
      selectedUser.enter = true;
      this.members = this.members.concat(selectedUser)
    } else {
      isMember.exit = false;
    }
    this.colaboradores = this.colaboradores.concat(selectedMail);
    this.colaboradorInput.nativeElement.value = '';
    this.colabCtrl.setValue(null);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.data.group.name, Validators.required]
    });
    this.colabCtrl.valueChanges.subscribe(newVal => {
      if (newVal == null) this.filteredColaboradores = null
      if (!newVal || typeof newVal != 'string' || newVal.trim().length <= 0) return
      this.filteredColaboradores = this._user.find({
        where: {
          and: [
            {
              isDeleted: false
            },
            {
              companyId: this.data.group.companyId
            },
            {
              email: {
                like: `%${newVal.trim()}%`
              }
            },
            {
              email: {
                nin: this.colaboradores.length > 100 ? this.colaboradores.slice(0, 100) : this.colaboradores
              }
            }
          ]
        }
      })
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async editGroup() {
    Promise.all(
      this.members.map(async colab => {
        if (colab.enter && colab.enter == true) {
          await this._groupUser.create({ groupId: this.data.group.id, userId: colab.id, companyId: colab.companyId }).subscribe(resp => {
            console.log(`El usuario ${colab.email} fue asignado al grupo.`);
          });
        }
        if (colab.exit && colab.exit == true) {
          this.data.group.members.forEach(member => {
            if (member.userId == colab.id) {
              this._groupUser.deleteById(member.id).subscribe((resp: any) => {
                console.log(`El usuario ${colab.email} fue borrado del grupo.`);
              });
            }
          });
        }
      })
    )
    await this._group.patchAttributes(this.data.group.id, this.form.value).subscribe(resp => {
      console.log('updated group', resp);
      this._snackBar.open('Grupo actualizado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(resp);
    })
  }

}
