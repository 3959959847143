import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from 'src/app/services/base-url';
import { LoopBackAuth } from 'src/app/services/shared/sdk';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef
  @Input() maxFiles: number = 1;
  @Input() maxFileSize: number = 2;
  @Input() allowedFileType: string;
  @Input() uploadURL: string = `${BASE_URL}/${API_VERSION}${CONTAINER_FOLDER}/upload?access_token=${this.loopBackAuth.getToken().id}`;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  response: string;

  constructor(
    private loopBackAuth: LoopBackAuth,
    public snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    const maxFileSize = this.maxFileSize * 1024 * 1024
    const options: FileUploaderOptions = {
      url: this.uploadURL,
      queueLimit: this.maxFiles,
      allowedMimeType: [...this.allowedFileType.split(',')],
      maxFileSize
    }
    console.log('options', options);

    this.uploader = new FileUploader(options);

    this.hasBaseDropZoneOver = false;
    this.response = '';

    this.uploader.response.subscribe(res => {
      console.log('res', res);
      this.response = res;
    });

    this.uploader.onAfterAddingFile = (item) => {
      console.log('item', item);
    }

    this.uploader.onWhenAddingFileFailed = (item, error, status) => {
      console.log('item', item);
      console.log('error', error);
      console.log('status', status);
      let alert = error.name == 'fileSize'
        ? 'El archivo es muy pesado'
        : error.name == 'mimeType'
          ? 'Formato inválido'
          : error.name == 'queueLimit'
            ? `Solo se ${this.maxFiles == 1 ? 'permite' : 'permiten'} ${this.maxFiles} ${this.maxFiles == 1 ? 'archivo' : 'archivos'}`
            : 'Error al seleccionar archivo'

      this.showAlert(alert)
    }
  }

  onAfterAddingFile(file) {
    console.log('file', file);

  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  addFile(event) {
    console.log('event', event);
    // refrescar queue al agregar archivo diferente si el límite es 1
    this.uploader.queue.reverse()
    console.log(this.uploader.queue);
  }

  uploadFiles() {
    this.uploader.onErrorItem = (item, error, status) => {
      console.log('error on upload item', error);
      console.log('item', item);
      console.log('status', status);
    }
    this.uploader.uploadAll()
  }

  showAlert(text: string, helpText: string = 'Error') {
    this.snackBar.open(text, helpText, {
      duration: 3000
    });
  }

}
