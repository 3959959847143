import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { TicketApi, UserApi, CompanyApi, CertificateCategoryApi, CertificateDesignApi, CertTemplateApi, CertificateApi, CertificateCategoryInterface } from '../../../services/shared/sdk';
import { NuevaCategoriaComponent } from '../nueva-categoria/nueva-categoria.component';
import { EditarCategoriaComponent } from '../editar-categoria/editar-categoria.component';
import { NuevoDisenoComponent } from '../nuevo-diseno/nuevo-diseno.component';
import { EditDisenoComponent } from '../edit-diseno/edit-diseno.component';
import { DetalleDisenoComponent } from '../detalle-diseno/detalle-diseno.component';
import { DeleteDisenoComponent } from '../delete-diseno/delete-diseno.component';
import { NuevaFraseComponent } from '../nueva-frase/nueva-frase.component';
import { EditFraseComponent } from '../edit-frase/edit-frase.component';
import { DetalleFraseComponent } from '../detalle-frase/detalle-frase.component';
import { DeleteFraseComponent } from '../delete-frase/delete-frase.component';
import { NuevoCertificadoComponent } from '../nuevo-certificado/nuevo-certificado.component';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import { SolicitudCategoriaComponent } from '../solicitud-categoria/solicitud-categoria.component';
import { EditarCertificadoComponent } from '../editar-certificado/editar-certificado.component';
import { EliminarCertificadoComponent } from '../eliminar-certificado/eliminar-certificado.component';
import { ActivatedRoute } from '@angular/router';
import { AsignarAdminCategoriasComponent } from '../../asignar-admin-categorias/asignar-admin-categorias.component';
import { AsignarSaldoCategoriaComponent } from '../asignar-saldo-categoria/asignar-saldo-categoria.component';

@Component({
  selector: 'app-lista-categorias',
  templateUrl: './lista-categorias.component.html',
  styleUrls: ['./lista-categorias.component.scss']
})
export class ListaCategoriasComponent implements OnInit {
  displayedColumns: string[] = ['name', 'dateDefault', 'designs', 'balance', 'actions'];
  displayedColumns2: string[] = ['name', 'categories', 'extrac', 'actions'];
  companies: any;
  categories: any = [];
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  companyId = 0;
  designs = [];
  balance = [];
  plantillas = [];
  certs = [];
  certificados = [];
  dropdownSettings = {};
  subAdmins = [];
  selectedCompanies = [];

  constructor(
    public dialog: MatDialog,
    private _ticket: TicketApi,
    private _user: UserApi,
    private _company: CompanyApi,
    private _category: CertificateCategoryApi,
    private _design: CertificateDesignApi,
    private _template: CertTemplateApi,
    private _cert: CertificateApi,
    public route: ActivatedRoute
  ) {
    this.route.params.subscribe(resp => {
      if (resp && resp.id) {
        this.showCompany2(resp.id);
      }
    })
  }

  async ngOnInit() {
    this.getCompanies();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 25,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar empresa',
      closeDropDownOnSelection: true
    };
  }

  getCompanies() {
    this._company.find({
      where: {
        isDeleted: false
      }, include: 'adminCompanies'
    }).subscribe((companies) => {
      this._user.getCurrent().subscribe((admin) => {
        if (admin.isSuperAdmin) {
          this.companies = companies;
        } else {
          this.companies = companies.filter((company: any) => {
            return company.adminCompanies.map((admin) => admin.adminId).includes(admin.id);
          });
        }

      });
    })
  }

  getTemplates() {
    this._template.find({
      where: {
        companyId: this.companyId
      }
    }).subscribe((templates: any) => {
      // console.log(templates);

      this.plantillas = templates;
      this.dataSource2 = new MatTableDataSource(this.plantillas);
    });
  }

  getDesigns() {
    this._design.find({ where: { companyId: this.companyId } }).subscribe((designs: any) => {
      designs.forEach((des) => {
        des.img = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + des.image;
        des.url = 'url(' + BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + des.image + ')';
        if (!des.image || des.image == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png") {
          des.url = 'url("https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png")';
          des.img = "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png";
        }
      });
      this.designs = designs;
    });
  }

  showCompany(event) {
    let id = event.id;
    let name = event.name;
    this.companyId = id;
    this.selectedCompanies = [{
      id,
      name
    }]
    document.getElementById('_table-container').style.visibility = "visible";
    document.getElementById('nameEmpresa').innerHTML = 'Categorías disponibles: ' + name;
    document.getElementById('nameEmpresa2').innerHTML = 'Diseños de los certificados: ' + name;
    document.getElementById('nameEmpresa3').innerHTML = 'Plantillas de frases: ' + name;
    document.getElementById('nameEmpresa4').innerHTML = 'Certificados de bienvenida: ' + name;
    this.getSubadmins(id);
    this.getCategory();
    this.getTemplates();
    this.getCertificates();
    this.getDesigns();

  }

  showCompany2(id) {
    this.companyId = id;
    this._company.findById(id).subscribe((resp: any) => {
      let name = resp.name;
      this.selectedCompanies = [{
        id,
        name
      }]
      document.getElementById('_table-container').style.visibility = "visible";
      document.getElementById('nameEmpresa').innerHTML = 'Categorías disponibles: ' + name;
      document.getElementById('nameEmpresa2').innerHTML = 'Diseños de los certificados: ' + name;
      document.getElementById('nameEmpresa3').innerHTML = 'Plantillas de frases: ' + name;
      document.getElementById('nameEmpresa4').innerHTML = 'Certificados de bienvenida: ' + name;
    })
    this.getSubadmins(id);
    this.getCategory();
    this.getTemplates();
    this.getCertificates();
    this.getDesigns();
  }

  getCertificates() {
    this._cert.find({ where: { companyId: this.companyId, isWelcome: true } }).subscribe((certificados: any) => {
      certificados.forEach(certificado => {
        if (certificado.img == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png") {
          certificado.imagen = certificado.img;
        }
        if (!certificado.img) {
          certificado.imagen = "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png";
        }
        if (certificado.img && (certificado.img != "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png")) {
          certificado.imagen = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + certificado.img
        }
        // certificado.imagen = 'assets/images/cert.png'
      });
      this.certificados = certificados;
    });
  }

  openDialogNewCategory() {
    const dialogRef = this.dialog.open(NuevaCategoriaComponent, {
      width: '60%',
      data: {
        companyId: this.companyId
      }
    });

    dialogRef.afterClosed().subscribe(categoria => {
      // console.log('The dialog was closed');
      if (categoria) {
        this.categories.push(categoria)
        this.dataSource = new MatTableDataSource(this.categories);
      }

    });
  }

  openDialogEditarCategory(item, index) {
    const dialogRef = this.dialog.open(EditarCategoriaComponent, {
      width: '60%',
      data: {
        categoria: item,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(categoria => {
      // console.log('The dialog was closed');
      if (categoria) {
        this.categories[categoria.index] = categoria.categoria;

      }

    });
  }

  openDialogAsignarAdmins(item: CertificateCategoryInterface, index) {
    const dialogRef = this.dialog.open(AsignarAdminCategoriasComponent, {
      width: '55%',
      data: {
        categoria: item,
        subadmins: this.subAdmins
      }
    })

    dialogRef.afterClosed().subscribe(categoryAdmins => {
      item.admins = categoryAdmins;
    })
  }

  openDialogAsignarSaldo(item: CertificateCategoryInterface, index: number) {
    const dialogRef = this.dialog.open(AsignarSaldoCategoriaComponent, {
      width: '60%',
      data: {
        category: item
      }
    })

    dialogRef.afterClosed().subscribe(({ newBalance }) => {
      console.log(newBalance);
      if (newBalance) {
        item.balance = newBalance.balance + item.balance;
      }

    })
  }

  openDialogNuevoDiseno() {
    const dialogRef = this.dialog.open(NuevoDisenoComponent, {
      width: '60%',
      data: {
        companyId: this.companyId,
        categories: this.categories
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {
        resp.url = BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + resp.image;
        resp.image = 'url(' + BASE_URL + '/' + API_VERSION + CONTAINER_FOLDER + '/download/' + resp.image + ')';
        this.designs.push(resp);
      }

    });
  }

  openDialogDetalleDiseno(item) {
    const dialogRef = this.dialog.open(DetalleDisenoComponent, {
      maxHeight: '90vh',
      data: {
        companyId: this.companyId,
        diseno: item
      }
    });

    dialogRef.afterClosed().subscribe(resp => {

    });
  }

  openDialogEditarDiseno(item, index) {
    const dialogRef = this.dialog.open(EditDisenoComponent, {
      width: '60%',
      data: {
        diseno: item,
        index: index,
        categories: this.categories
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      this.getDesigns();
    });
  }

  openDialogDeleteDiseno(item, index) {
    const dialogRef = this.dialog.open(DeleteDisenoComponent, {
      width: '60%',
      data: {
        diseno: item,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {

        this.designs.splice(resp.index, 1);
      }

    });
  }

  openDialogNuevaFrase() {
    const dialogRef = this.dialog.open(NuevaFraseComponent, {
      width: '60%',
      data: {
        companyId: this.companyId,
        categories: this.categories
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {
        this.plantillas.push(resp);
        this.dataSource2 = new MatTableDataSource(this.plantillas)
      }

    });
  }

  openDialogEditarFrase(item, index) {
    const dialogRef = this.dialog.open(EditFraseComponent, {
      width: '60%',
      data: {
        frase: item,
        index: index,
        categories: this.categories
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {
        this.plantillas[resp.index] = resp.frase;
      }

    });
  }

  openDialogDetalleFrase(item, index) {
    const dialogRef = this.dialog.open(DetalleFraseComponent, {
      width: '40%',
      data: {
        frase: item,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');      
    });
  }

  openDialogDeleteFrase(item, index) {
    const dialogRef = this.dialog.open(DeleteFraseComponent, {
      width: '40%',
      data: {
        frase: item,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {
        this.plantillas.splice(resp.index, 1);
        this.dataSource2 = new MatTableDataSource(this.plantillas);
      }
    });
  }

  openDialogNuevoCertificado() {
    const dialogRef = this.dialog.open(NuevoCertificadoComponent, {
      width: '90%',
      maxHeight: '90vh',
      data: {
        companyId: this.companyId,
        categories: this.categories,
        plantillas: this.plantillas,
        designs: this.designs
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {
        this.getCertificates();
      }

    });
  }

  async getSubadmins(companyId) {
    this.subAdmins = await this._user.find({
      where: {
        type: 'owner',
        companyId
      }
    }).toPromise();
    console.log('this.subAdmins', this.subAdmins);
  }

  getCategory() {
    this._category.find({
      where: {
        isDeleted: false,
        companyId: this.companyId
      },
      include: [
        {
          relation: 'admins',
          scope: {
            fields: {
              id: true,
              email: true
            }
          }
        }]
    }).subscribe((categories: any) => {
      this.categories = categories;
      this.dataSource = new MatTableDataSource(this.categories);
    });
  }

  solicitud(row) {
    const dialog = this.dialog.open(SolicitudCategoriaComponent, {
      width: '50%',
      data: row
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.getCategory();
      }
    })
  }

  editCertificate(row, index) {
    const dialogRef = this.dialog.open(EditarCertificadoComponent, {
      width: '80%',
      maxHeight: '90vh',
      data: {
        companyId: this.companyId,
        categories: this.categories,
        plantillas: this.plantillas,
        designs: this.designs,
        cert: row,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {
        // console.log(resp);
        this.getCertificates()

      }

    });
  }

  deleteCertificate(row, index) {
    const dialogRef = this.dialog.open(EliminarCertificadoComponent, {
      width: '40%',
      maxHeight: '90vh',
      data: {
        companyId: this.companyId,
        categories: this.categories,
        plantillas: this.plantillas,
        designs: this.designs,
        cert: row,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      // console.log('The dialog was closed');
      if (resp) {
        // console.log(resp);
        this.getCertificates()

      }

    });
  }

}
