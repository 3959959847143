import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, CertTemplateApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-nueva-frase',
  templateUrl: './nueva-frase.component.html',
  styleUrls: ['./nueva-frase.component.scss']
})
export class NuevaFraseComponent implements OnInit {
  form: FormGroup;
  frase: any = {
    name: '',
    description: ''
  }

  constructor(
    public dialogRef: MatDialogRef<NuevaFraseComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _template: CertTemplateApi,
    private _user: UserApi,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      categories: ['', Validators.required],
      phrase: ['', [Validators.required]]
    });  
   }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createFrase() {
    this.frase.companyId = this.data.companyId;
    this._template.create(this.frase).subscribe((template) => {
      this.dialogRef.close(template);
      this._snackBar.open('Plantilla creada', 'Listo!', {
        duration: 3000
      });
    },(error) => {
      this._snackBar.open('Ocurrió un error', 'ERROR!', {
        duration: 4000
      });
      console.error(error);
      
    });
  }
}
