import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { CertificateCategory, CertificateCategoryApi, User } from "src/app/services/shared/sdk";

@Component({
    selector: 'app-assign-admin-categories',
    templateUrl: './asignar-admin-categorias.component.html'

})
export class AsignarAdminCategoriasComponent implements OnInit {
    availableAdmins: User[] = [];
    categoryAdmins: User[] = [];
    category: CertificateCategory;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    userControl = new FormControl();
    filteredUsers: Observable<any>;
    userSearched: string;
    constructor(
        public dialogRef: MatDialogRef<AsignarAdminCategoriasComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _snackBar: MatSnackBar,
        private readonly _category: CertificateCategoryApi
    ) {
        this.category = this.data.categoria;
        this.categoryAdmins = this.category && this.category.admins ? [...this.category.admins] : [];
        this.availableAdmins = this.data.subadmins;
    }

    ngOnInit() {
        this.filteredUsers = this.userControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }

    private _filter(value: string): User[] {
        if (typeof value != 'string') return;
        const filterValue = value.toLowerCase();
        const finded = this.availableAdmins.filter(user => user.email.toLowerCase().includes(filterValue));
        return finded;
    }

    async selected(event: MatAutocompleteSelectedEvent): Promise<void> {
        const selectedMail = event.option.viewValue;
        const selectedUser = event.option.value as User;

        if (this.categoryAdmins.some(u => u.email.includes(selectedMail))) {
            this._snackBar.open('Este usuario ya está en la lista', 'Error', {
                duration: 2000
            });
            return;
        }
        const isMember = this.categoryAdmins.find(member => member.email == selectedMail)
        if (!isMember) {
            this.categoryAdmins = this.categoryAdmins.concat(selectedUser);
            await this._category.linkAdmins(this.category.id, selectedUser.id, {}).toPromise();
            this.showSnackBar('Administrador asignado', 'Hecho')
        }
        this.userControl.patchValue('');
    }

    async remove(user: User): Promise<void> {
        const index = this.categoryAdmins.indexOf(user);
        const toRemove = this.categoryAdmins.find(member => member.email == user.email);
        if (toRemove) {
            this.categoryAdmins.splice(index, 1);
            await this._category.unlinkAdmins(this.category.id, toRemove.id).toPromise();
            this.showSnackBar('Administrador desvinculado', 'Hecho');
        }
        this.userControl.patchValue('');
    }

    showSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000
        });
    }

    closeModal(): void {
        this.dialogRef.close(this.categoryAdmins);
    }
}