import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { BASE_URL, API_VERSION, CONTAINER_FOLDER } from '../../../services/base-url';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { EventApi } from '../../../services/shared/sdk';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-eliminar-evento',
  templateUrl: './eliminar-evento.component.html',
  styleUrls: ['./eliminar-evento.component.scss']
})
export class EliminarEventoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EliminarEventoComponent>,
    private formBuilder: FormBuilder,
    private _event: EventApi,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete() {
    this._event.patchAttributes(this.data.event.id, {isDeleted: true}).subscribe(() => {
      this._snackBar.open('Evento eliminado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(this.data);
    });
  }

}
