import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserApi, CertTemplateApi } from '../../../services/shared/sdk';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle-frase',
  templateUrl: './detalle-frase.component.html',
  styleUrls: ['./detalle-frase.component.scss']
})
export class DetalleFraseComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DetalleFraseComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _template: CertTemplateApi,
    private _user: UserApi,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
