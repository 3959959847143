import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import {CompanyApi, AdminApi, Admin_companyApi, UserApi} from '../../../services/shared/sdk'
import {MatSnackBar} from '@angular/material/snack-bar';

// export interface DialogData {
//   name: string;
//   email: string;
//   password: string;
//   confirmPass: string;
//   companies: []
// }

@Component({
  selector: 'app-nuevo-admin',
  templateUrl: './nuevo-admin.component.html',
  styleUrls: ['./nuevo-admin.component.scss']
})
export class NuevoAdminComponent implements OnInit {

  form: FormGroup;

  patronEmail = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  companyCtrl = new FormControl();
  filteredCompanies: Observable<string[]>;
  companies: any = [] ;
  allCompanies: any;
  user;

  @ViewChild('companyInput') companyInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  constructor(
    public dialogRef: MatDialogRef<NuevoAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _company: CompanyApi,
    private _admin: AdminApi,
    private _admComp: Admin_companyApi,
    private _user: UserApi,
    private snackBar: MatSnackBar
    ) {
      this._user.getCurrent().subscribe(user => {
        this.user = user;
      })
      this.allCompanies = this.data.Companies;
      this.companies = this.data.Primera;
      this.filteredCompanies = this.companyCtrl.valueChanges.pipe(
      startWith(null),
      map((company: string | null) => company ? this._filter(company): this.allCompanies.slice())
    );
   }
   
  //  getCompanies(){
  //   this._company.find().subscribe((com: any) => {
  //     com.forEach(element => {
  //       this.allCompanies.push(element.name)
  //     });
  //     console.log(this.allCompanies);
  //   })
  //  }

   add(event: MatChipInputEvent): void {
     const input = event.input;
     const value = event.value;

     if((value || '').trim()) {
       this.companies.push(value.trim());
     }

     if(input) {
       input.value = '';
     }

     this.companyCtrl.setValue(null);
   }

   remove(company: string): void {
     const index = this.companies.indexOf(company);

     if (index >= 0) {
       this.companies.splice(index,1);
     }
     this.allCompanies.unshift(company);
     this.companyInput.nativeElement.value = '';
     this.companyCtrl.setValue(null);
   }

   selected(event: MatAutocompleteSelectedEvent): void {
     this.companies.push(event.option.viewValue);
     let indexCompany = this.allCompanies.indexOf(event.option.viewValue);
     this.allCompanies.splice(indexCompany,1);
     this.companyInput.nativeElement.value = '';
     this.companyCtrl.setValue(null);
   }

   private _filter(value: string): string[] {
     const filterValue = value.toLowerCase();
     return this.allCompanies.filter(company => company.toLowerCase().indexOf(filterValue) === 0);
   }

    onNoClick(): void {
      this.dialogRef.close();
    }

    closeModal(): void {
      this.dialogRef.close();
    }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['',Validators.required],
      email: ['',[Validators.required, Validators.pattern(this.patronEmail)]],
      password: ['', Validators.required],
      confirmPass: [false, Validators.requiredTrue],
      isSuperAdmin: ['']
    });
  }

  test(){
    console.log(this.form.value);
  }

  createAdmin(){
    if(!this.form.value.isSuperAdmin || this.form.value.isSuperAdmin == false){
      this.form.value.isAdmin = true;
    }
    let adminAux = this.form.value;
    adminAux.type = 'admin';
    adminAux.pass = adminAux.password;
    
    this._user.create(adminAux).subscribe((admin) => {
      console.log(admin);
      if(this.companies && this.companies.length){
        this.companies.forEach(com => {
          this._company.find({where: {and: [{name: com}, {isDeleted: false}]}}).subscribe((company : any) => {
            console.log(company[0].id);
            let companydata = {adminId: admin.id,companyId: company[0].id};
            this._admComp.create(companydata).subscribe((admComData) => {
              console.log(admComData);
            });
          });
        });
      }
      this.snackBar.open('Administrador creado', 'Listo!', {
        duration: 2000
      });
      this.dialogRef.close(admin);
    });
  }

  passIsEqual(e) {
    this.form.get('confirmPass')
      .setValue(e.target.value === this.form.get('password').value);
  }

}
