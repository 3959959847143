import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BalanceHistoryApi, CertificateCategoryApi, UserApi } from "src/app/services/shared/sdk";

@Component({
    selector: 'ngx-asignar-saldo',
    templateUrl: './asignar-saldo-categoria.component.html'
})
export class AsignarSaldoCategoriaComponent {
    balanceForm = new FormGroup({
        balance: new FormControl('', [Validators.required])
    });
    category;

    constructor(
        private _user: UserApi,
        private _balance: BalanceHistoryApi,
        private _category: CertificateCategoryApi,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<AsignarSaldoCategoriaComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any
    ) {
        this.category = this.data.category;
        console.log(this.category);

    }

    createBalance() {

        let balance: any = {
            companyId: this.category.companyId,
            balance: this.balanceForm.value.balance,
            balanceUsed: 0,
            adminId: this._user.getCurrentId(),
            status: 'inBalance',
            categoryId: this.category.id
        };

        this._balance.create(balance).subscribe((newBalance) => {
            this.dialogRef.close({
                newBalance: newBalance
            });
        }, (err: any) => {
            this._snackBar.open(err.message, 'Error!', {
                duration: 2000
            });
        });

    }

    closeModal() {
        this.dialogRef.close();
    }
}