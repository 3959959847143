/* tslint:disable */

declare var Object: any;
export interface ImporantDateInterface {
  "name"?: string;
  "employeeId"?: number;
  "date"?: any;
  "companyId"?: number;
  "id"?: number;
}

export class ImporantDate implements ImporantDateInterface {
  "name": string;
  "employeeId": number;
  "date": any;
  "companyId": number;
  "id": number;
  constructor(data?: ImporantDateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ImporantDate`.
   */
  public static getModelName() {
    return "ImporantDate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ImporantDate for dynamic purposes.
  **/
  public static factory(data: ImporantDateInterface): ImporantDate{
    return new ImporantDate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ImporantDate',
      plural: 'ImporantDates',
      path: 'ImporantDates',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'any'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
